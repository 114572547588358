<template>
  <div :id="instId" class="SarsCoV2Template bg-white">
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <img
            src="https://cdn.glitch.com/1d1d7c79-abe4-4c95-b135-3a111632f4d8%2Fserenity-nyaho-logo.png?v=1589272118531"
            alt="nyaho-logo"
            style="height: 150px; z-index: -1;"
          />
        </div>
        <div class="col text-primary text-right" style>
          <p class="m-1 lh-100">35 Kofi Annan Street</p>
          <p class="m-1 lh-100">Airport Residential Area</p>
          <p class="m-1 lh-100">Accra-Ghana</p>
          <p class="m-1 lh-100">Email: info@nyahomedical.com</p>
          <p class="m-1 lh-100">Tel: +2330307 086 490</p>
          <p class="m-1 lh-100">Website: www.nyahomedical.com</p>
        </div>
      </div>

      <div class="row mt-2" style="border-top: 1px dashed black; border-bottom: 1px dashed black;">
        <div class="col text-center text-danger">LABORATORY DEPARTMENT</div>
      </div>
      <small>
        <div class="row" style="border-bottom: 1px dashed black;">
          <div class="col">
            <div class="row">
              <div class="col-4">Order code</div>
              <div class="col">: {{ orderCode || "--" }}</div>
            </div>
            <div class="row">
              <div class="col-4">Patient name</div>
              <div class="col">: {{ patientName || "--" }}</div>
            </div>
            <div class="row">
              <div class="col-4">Age/Sex</div>
              <div class="col">: {{ patientAge || "--" }}/{{ patientGender || "--" }}</div>
            </div>
            <div class="row">
              <div class="col-4">OPD/IPD</div>
              <div class="col">: {{ "--" }}</div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-5">Patient ID (MR #)</div>
              <div class="col">: {{ patientId || "--" }} ({{ patientMR }})</div>
            </div>
            <div class="row">
              <div class="col-5">Practitioner</div>
              <div class="col">: {{ practitionerName || "--" }}</div>
            </div>
            <div class="row">
              <div class="col-5">Order Date/Time</div>
              <div class="col">: {{ serviceRequestDateTime || "--" }}</div>
            </div>
            <div class="row">
              <div class="col-5">Sample Received Date/Time</div>
              <div class="col">: {{ sampleReceiveDateTime || "--" }}</div>
            </div>
          </div>
        </div>
      </small>

      <div class="row mt-3">
        <div class="col text-center">
          <h2>Microbiology</h2>
        </div>
      </div>
      <div
        class="row font-weight-bold text-sm"
        style="border-top: 1px dashed black; border-bottom: 1px dashed black;"
      >
        <div class="col-5">Investigations</div>
        <div class="col-1 text-right">Flag</div>
        <div class="col-2">Results</div>
        <div class="col-3">Reference Interval Biological</div>
        <div class="col-1">Unit</div>
      </div>
      <div class="row">
        <div
          class="col-5 font-weight-bold"
        >{{ `${serviceName} NUCLEIC ACID by RT-qPCR/Swab` || "--" }}</div>
        <div class="col-1 text-right">{{ flag }}</div>
        <div class="col-2">{{ result }}</div>
        <div class="col-3">{{ referenceIntervalBiological || "--" }}</div>
        <div class="col-1">{{ unit || "--" }}</div>
      </div>

      <div class="row mt-3">
        <div class="col font-weight-bold">COMMENT</div>
      </div>
      <div class="row">
        <div class="col font-weight-bold">
          <p class="font-style-italic m-0">
            If your test result is
            <em>POSITIVE</em>:
          </p>
          <p
            class="font-style-italic m-0"
          >Please ensure you always wear a protective nose mask, wash your hands frequently, keep yourself isolated from all other persons by staying home.</p>
          <p
            class="font-style-italic"
          >If you experience a severe form of any of the COVID-19 symptoms like shortness of breath, please contact Nyaho Medical Center or your medical provider for assistance.</p>
        </div>
      </div>
      <div class="row">
        <div class="col font-weight-bold">
          <p class="font-style-italic mb-0">
            If your test result is
            <em>NEGATIVE</em>:
          </p>
          <p
            class="font-style-italic m-0"
          >A negative SARS-CoV-2 virus test (COVID-19) is not a conclusive test. While a negative test result means you might not have an active infection, it does not lower your risk of getting a SARS-CoV-2 infection in the future.</p>
          <p
            class="font-style-italic m-0"
          >Please remember to practice social distancing and frequent hand washing to stay home if you do not feel well to reduce the spread of COVID-19.</p>
        </div>
      </div>

      <div class="row">
        <div class="col text-center text-sm">***End of Report***</div>
      </div>
      <!-- <div class="row g-0">
        <div class="col p-0">
          <vue-qrcode :value="qrCodeValue" style="width: 50mm; height: 50mm;" />
        </div>
      </div>-->
      <div class="row" style="margin-top: 26mm;">
        <!-- <div class="col">xxx</div>
        <div class="col text-right">
          <div class="row">
            <div class="col-12">xxx</div>
            <div class="col-12">
              <small>xxx</small>
            </div>
          </div>
        </div>-->
      </div>

      <div class="row" style="border-bottom: 1px dashed black;">
        <div class="col text-sm">* Report for perusal of doctors only, not for medicolegal cases.</div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueQrcode from "vue-qrcode";

export default {
  name: "sars-cov-2-template",
  props: {
    orderCode: {
      type: [Number, String],
      required: true,
    },
    patientName: {
      type: String,
      required: true,
    },
    patientAge: {
      type: [String, Number],
      required: true,
    },
    patientGender: {
      type: String,
      required: true,
    },
    patientId: {
      type: String,
      required: true,
    },
    patientMR: {
      type: String,
    },
    practitionerName: {
      type: String,
      required: true,
    },
    serviceRequestDateTime: {
      type: String,
      required: true,
    },
    sampleReceiveDateTime: {
      type: String,
    },
    serviceName: {
      type: String,
      required: true,
    },
    flag: {
      type: String,
    },
    result: {
      type: String,
    },
    referenceIntervalBiological: {
      type: String,
    },
    unit: {
      type: String,
    },
    // qrCodeValue: {
    //   type: String,
    //   required: true,
    // },
    instId: {
      type: String,
      default: "",
    },
  },
  // components: { VueQrcode },
};
</script>

<style scoped>
.SarsCoV2Template {
  display: none;
  /* width: 210mm; */
  /* height: 297mm; */
}
@media print {
  body > * {
    display: none;
  }
  .SarsCoV2Template {
    display: block;
    margin-left: 10mm;
    margin-right: 10mm;
  }
  .SarsCoV2Template * {
    /* display: initial; */
  }
}
</style>
