<template>
  <div class="bot container">
    <form id="form-appointment" autocomplete="off"></form>
    <div id="cf-context" class="dark-theme" role="cf-context" cf-context></div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import moment from "moment";
import env from "../../env";

export default {
  data() {
    return {
      conversationalForm: null,
      userSessionData: {
        appointmentCost: "GHc 499",
        address: "Some location in the world",
        result: [],
        resultData: {},
        doctors: {},
        display: "",
        serviceValue: "",
        streetAddress: "",
        appointmentTypeKey: "virtual-care-specialist",
        appointmentType: "Virtual Care",
      },
      specialistKeys: {
        "Family Physician": "family-medicine",
        "Emergency Doctor": "emergency-medicine",
        Dietician: "dietetics",
        "Paediatric Doctor": "paediatrics",
        "Obstetrician & Gynecologist": "obstetrics-and-gynaecology",
        "General Surgeon": "general-surgery",
        Urologist: "urology",
      },
      // apiUrl: "https://api.v05.serenity.health",
      apiUrl:
        process.env.NODE_ENV === "production"
          ? env.baseUrlProduction
          : env.baseUrlTest,
      totalPoints: 0,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      practitionerType: "",
      greetingsTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "",
        },
        {
          tag: "select",
          name: "startoptions",
          "cf-questions":
            "I'm Serenity and I can help you get started in minutes. When would you like the online consultation?",
          children: [
            {
              tag: "option",
              "cf-label": "Next available Date",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Choose my own Date",
              value: "",
            },
          ],
        },
      ],
      greetingsSpecialistTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "",
        },
        {
          tag: "select",
          name: "specialistType",
          "cf-questions": "",
          children: [
            {
              tag: "option",
              "cf-label": "Yes",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "No",
              value: "",
            },
            //   {
            //     tag: "option",
            //     "cf-label": "Family Physician",
            //     value: "",
            //   },
            //   {
            //     tag: "option",
            //     "cf-label": "Paediatric Doctor",
            //     value: "",
            //   },
            //   {
            //     tag: "option",
            //     "cf-label": "Dietician",
            //     value: "",
            //   },
            //   {
            //     tag: "option",
            //     "cf-label": "Emergency Doctor",
            //     value: "",
            //   },
            //   {
            //     tag: "option",
            //     "cf-label": "Urologist",
            //     value: "",
            //   },
            //   {
            //     tag: "option",
            //     "cf-label": "Obstetrician & Gynecologist",
            //     value: "",
            //   },
          ],
        },
      ],
      otherTimeSlots: [
        {
          tag: "select",
          name: "otherTimeSlots",
          "cf-questions":
            "Select from the available slots a day for your appointment.",
          children: [
            {
              tag: "option",
              "cf-label": "Today",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Tomorrow",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Choose other days",
              value: "",
            },
          ],
        },
      ],
      otherTimeSlotsNoToday: [
        {
          tag: "select",
          name: "otherTimeSlots",
          "cf-questions":
            "Select from the available slots a day for your appointment.",
          children: [
            {
              tag: "option",
              "cf-label": "Tomorrow",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Choose other days",
              value: "",
            },
          ],
        },
      ],
      userExistenceTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "Splendid",
        },
        {
          tag: "select",
          name: "bookingfor",
          "cf-questions": "Are you booking for yourself or someone else",
          children: [
            {
              tag: "option",
              "cf-label": "Yes,  myself",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "No, someone else",
              value: "",
            },
          ],
        },
        {
          tag: "select",
          name: "userexistence",
          "cf-questions": "Is this your first time?",
          children: [
            {
              tag: "option",
              "cf-label": "Yes,  I’m a new user",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "No I’m an existing user",
              value: "",
            },
          ],
        },
      ],
      userDetailsTags: [
        {
          tag: "input",
          name: "name",
          "cf-questions":
            "Can you tell me the fullname of the person you are booking for",
        },
        {
          tag: "input",
          name: "mobile",
          "cf-questions": "Please enter {name}'s mobile number",
        },
        {
          tag: "select",
          name: "gender",
          "cf-questions": "Please select their gender",
          children: [
            {
              tag: "option",
              "cf-label": "Male",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Female",
              value: "",
            },
          ],
        },
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions":
            "Great. Please enter {name}'s date of birth in the format given below.",
        },
        {
          tag: "input",
          name: "dateofbirth",
          "cf-input-placeholder": "dd/mm/yyyy",
          "cf-questions": "<strong>eg. 26/09/1993</strong>",
        },
      ],
      mobileMoneyOptionsTags: [
        {
          tag: "select",
          name: "mobilemoneyoptions",
          "cf-questions": "Please select a payment provider",
          children: [
            {
              tag: "option",
              "cf-label": "MTN",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Airtel Tigo",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Vodafone",
              value: "",
            },
          ],
        },
        {
          tag: "input",
          name: "mobilemoneynumber",
          "cf-input-placeholder": "",
          "cf-questions":
            "Please enter the {mobilemoneyoptions} Mobile money number",
        },
      ],
      vodafoneVoucherTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-input-placeholder": "",
          "cf-questions":
            "Please dial *110# and select option 4 then select Generate voucher to generate a voucher for this payment.",
        },
        {
          tag: "input",
          name: "vodafonevoucher",
          "cf-input-placeholder": "",
          "cf-questions": "Enter your generated voucher here",
        },
      ],
      validateMyInfoTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "Ok, great. confirming your details",
        },
        {
          tag: "select",
          name: "validatemyinfo",
          "cf-questions":
            "You are Thomas Kensah, 26 years, mobile 0500318982. Is this correct?",
          children: [
            {
              tag: "option",
              "cf-label": "Yes",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "No",
              value: "",
            },
          ],
        },
      ],
      newUserDetailsCollectionTags: [
        {
          tag: "input",
          name: "phone",
          "cf-questions": "Kindly input the phone number for registration.",
        },
        {
          tag: "select",
          name: "gender",
          "cf-questions": "Please select your gender",
          children: [
            {
              tag: "option",
              "cf-label": "Male",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Female",
              value: "",
            },
          ],
        },
        {
          tag: "input",
          name: "fullname",
          "cf-questions": "Kindly give me your full name.",
        },
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "Kindly share your Date of Birth with me.",
        },
        {
          tag: "input",
          name: "dateofbirth",
          "cf-input-placeholder": "dd/mm/yyyy",
          "cf-questions": "<strong>eg. 26/09/1993</strong>",
        },
      ],
      bookingTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "Splendid,",
        },
        {
          tag: "select",
          name: "bookingfor",
          "cf-questions":
            "Are you booking this appointment for yourself or someone else",
          children: [
            {
              tag: "option",
              "cf-label": "Yes, myself",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "No, someone else",
              value: "",
            },
          ],
        },
      ],
      existingUserDetailsCollectionTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "Lets get you verified",
        },
        {
          tag: "input",
          name: "mobile",
          "cf-questions":
            "Please enter the phone number you used for registration.",
        },
      ],
      welcomeBackMessageTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": ``,
        },
        {
          tag: "select",
          name: "condition",
          "cf-questions": "What brings you in?",
          children: [
            {
              tag: "option",
              "cf-label": "Abdominal pain",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Rash",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Diarrhoea",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Other",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Corona Virus Concerns",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "None",
              value: "",
            },
          ],
        },
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": ``,
        },
        {
          tag: "select",
          name: "proceedwithappointment",
          "cf-questions":
            "Select Proceed to book an appointment or cancel to cancel the appointment.",
          children: [
            {
              tag: "option",
              "cf-label": "Proceed",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Cancel",
              value: "",
            },
          ],
        },
      ],
      daySelectionTags: [
        {
          tag: "select",
          name: "dayselection",
          "cf-questions":
            "Select a day for your test. Holiday and weekend times may vary, call 0209404046 to confirm if you're booking on a weekend or holiday.",
          children: [
            {
              tag: "option",
              "cf-label": "Next available Date",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Choose my own Date",
              value: "",
            },
          ],
        },
      ],
      verifyLoginCodeInputTags: [
        {
          tag: "input",
          name: "verifylogincode",
          "cf-questions":
            "Please verify your number by entering the six digit code that has been sent to your phone.",
        },
      ],
      paymentSelectionTags: [
        {
          tag: "select",
          name: "paymentselection",
          "cf-questions": "",
          children: [
            // {
            //   tag: "option",
            //   "cf-label": "Mobile Money",
            //   value: "",
            // },
            {
              tag: "option",
              "cf-label": "Cash",
              value: "",
            },
          ],
        },
      ],
      cancelTags: [
        {
          tag: "select",
          name: "cancel",
          "cf-questions": "Would you like to end the session or go back?",
          children: [
            {
              tag: "option",
              "cf-label": "End the session",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Return to back to the start",
              value: "",
            },
          ],
        },
      ],
      mobileMoneyProviderSelectionTags: [
        {
          tag: "select",
          name: "mobilemoneyproviderselection",
          "cf-questions": "Please select a payment provider",
          children: [
            {
              tag: "option",
              "cf-label": "MTN",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Airtel Tigo",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Vodafone",
              value: "",
            },
          ],
        },
      ],
      mobileMoneyNumberInputTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "",
        },
        {
          tag: "input",
          name: "mobilemoneynumber",
          "cf-questions":
            "Please enter the {mobilemoneyproviderselection} Mobile money number",
        },
      ],
      vodafoneVoucherInputTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": `Please dial *110# and select option 4 then select Generate voucher to generate a voucher for this payment.`,
        },
        {
          tag: "input",
          name: "voucher",
          "cf-questions": "Enter your generated voucher here",
        },
      ],
      finalSessionMTNMessageTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "",
        },
        {
          tag: "select",
          name: "finalsessionmessage",
          "cf-questions":
            "Please dial *170# and select options 6 then 3 to authorise payment. Thank you.",
          children: [
            {
              tag: "option",
              "cf-label": "Book another appointment",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Check my bill for this appointment",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Done",
              value: "",
            },
            // {
            //   tag: "option",
            //   "cf-label": "End Session",
            //   value: ""
            // }
          ],
        },
      ],
      finalSessionMessageTags: [
        {
          tag: "select",
          name: "finalsessionmessage",
          "cf-questions": "",
          children: [
            {
              tag: "option",
              "cf-label": "Book another appointment",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Check my bill for this appointment",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Pay with ExpressPay",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Done",
              value: "",
            },
          ],
        },
      ],
      forSpecialist: true,
      listTiers: [],
      userName: "",
      listService: "",
      currentDate: "",
      tomorrowDate: "",
      nextDayDate: "",
      date: "",
      Timeslots: "",
    };
  },
  created() {
    // this.greetingsSpecialistTags[1].children = this.$store.state.serviceStore.listChildren;
    this.listService = this.$store.state.serviceStore.select;
    console.log(this.listService);

    this.userName = this.userInfo.szfirstname
      ? this.userInfo.szfirstname
      : "Dear";
  },
  methods: {
    setupForm() {},
    async flowCallback(dto, success, error) {
      if (dto.tag.domElement.name === "specialistType") {
        if (dto.text === "Yes") {
          // this.userSessionData.doctorSpecialty = this.specialistKeys[dto.text];

          this.conversationalForm.addRobotChatResponse(
            "Fetching available specialists..."
          );
          try {
            // Make a request for a user with a given ID
            let response = await axios.get(
              this.apiUrl +
                "/provider/healthservices?clinic_name=&specialty=" +
                this.listService.specialty
            );
            // Try an existing account
            let service = [];
            this.listTiers = response.data[0].health_service_price_tiers;
            response.data[0].health_service_price_tiers.forEach(
              (list, index) => {
                service.push({
                  tag: "option",
                  "cf-label":
                    list.display +
                    " " +
                    "(" +
                    list.charge +
                    list.currency +
                    ")",
                  value: list.display,
                });
                // tiersTags.children.push({
                //   tag: "option",
                //   "cf-label":
                //     list.display +
                //     " " +
                //     "(" +
                //     list.charge +
                //     list.currency +
                //     ")",
                //   value: list.display,
                // });
              }
            );
            const tiersTags = [
              {
                tag: "select",
                name: "tiersselection",
                "cf-questions": `Here are the available services for <a href="#" class="text-info">${this.listService.specialty}</a>.`,
                children: service,
              },
            ];
            console.log(service);
            if (response.data[0].length <= 0) {
              const tags = [
                {
                  tag: "cf-robot-message",
                  name: "message",
                  "cf-questions": `Sorry, there are no specialist available for ${dto.text}`,
                },
                {
                  tag: "select",
                  name: "fetchspecialistsfailed",
                  "cf-questions":
                    "Would you like to contact a general practitioner, select a different type of specialist or try again",
                  children: [
                    {
                      tag: "option",
                      "cf-label": "Try again",
                      value: "",
                    },
                    // {
                    //   tag: "option",
                    //   "cf-label": "Contact a different type of specialist",
                    //   value: "",
                    // },
                    {
                      tag: "option",
                      "cf-label": "Take me back to selecting a practitioner",
                      value: "",
                    },
                    {
                      tag: "option",
                      "cf-label": "Cancel",
                      value: "",
                    },
                  ],
                },
              ];
              this.conversationalForm.addTags(tags, true);
            } else this.conversationalForm.addTags(tiersTags, true);
          } catch (e) {
            // Try an existing account
            const tags = [
              {
                tag: "cf-robot-message",
                name: "message",
                "cf-questions": `Sorry, there was a problem and I couldn't fetch any specialists`,
              },
              {
                tag: "select",
                name: "fetchspecialistsfailed",
                "cf-questions":
                  "Would you like to contact a general practitioner, select a different type of specialist or try again",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  // {
                  //   tag: "option",
                  //   "cf-label": "Contact a different type of specialist",
                  //   value: "",
                  // },
                  {
                    tag: "option",
                    "cf-label": "Take me back to selecting a practitioner",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
        }
        if (dto.text === "No") {
          // TODO: Add permission required tags
          this.$router.push({ path: "/" });
        }
      }
      if (dto.tag.domElement.name === "tiersselection") {
        this.userSessionData.display = dto.tag.value[0];
        console.log(this.userSessionData.display);
        console.log(this.listTiers);
        this.listTiers.forEach((el) => {
          if (el.display == this.userSessionData.display) {
            this.userSessionData.serviceValue = el;
            console.log(el);
          }
        });
        console.log(this.userSessionData.serviceValue);
        this.conversationalForm.addTags(this.bookingTags, true);
      }
      if (dto.tag.domElement.name === "bookingfor") {
        if (dto.text === "Yes, myself") {
          // TODO: Make a network call: Get next Available time slot

          // TODO: Gather user details from store
          if (this.loggedIn) {
            this.userSessionData.fullName =
              this.userInfo.szfirstname +
              `${
                this.userInfo.sz_othername
                  ? +" " + this.userInfo.sz_othername + " "
                  : " "
              }` +
              this.userInfo.szlastname;
            this.userSessionData.userId = this.userInfo.sz_userid;
            this.userSessionData.mobile = this.userInfo.sz_phoneno;
            this.userSessionData.gender = this.userInfo.szgender;
            this.userSessionData.dateOfBirth = this.userInfo.szdob;

            if (!!this.sz_companyid) {
              this.userSessionData.hasCorporate = true;
              this.userSessionData.companyId = this.userInfo.sz_companyid;
            }
            if (!!this.sz_companyname)
              this.userSessionData.companyName = this.userInfo.sz_companyname;

            this.validateMyInfoTags[1]["cf-questions"] = `You are ${
              this.userSessionData.fullName
            }, ${
              this.$moment().diff(this.userSessionData.dateOfBirth, 'years', false)
            } years, mobile ${this.userSessionData.mobile}. Is this correct?`;
            this.conversationalForm.addTags(this.validateMyInfoTags, true);
          } else {
            this.userDetailsTags[0]["cf-questions"] =
              "Can you tell me your full name";
            this.userDetailsTags[1]["cf-questions"] =
              "Please enter your mobile number";
            this.conversationalForm.addTags(this.userDetailsTags, true);
            this.userSessionData.isBookingForSelf = true;
          }
        }
        if (dto.text === "No, someone else") {
          // TODO: Add user details tags
          this.conversationalForm.addTags(this.userDetailsTags, true);
        }
      }
      if (dto.tag.domElement.name === "validatemyinfo") {
        if (dto.text === "Yes" && this.loggedIn)
          this.conversationalForm.addTags(this.daySelectionTags, true);
        else if (dto.text === "No") this.$router.push({ path: "/" });
      }
      // Gather Inputs
      if (dto.tag.domElement.name === "name")
        this.userSessionData.fullName = dto.text;
      if (dto.tag.domElement.name === "mobile")
        this.userSessionData.mobile = dto.text;
      if (dto.tag.domElement.name === "gender")
        this.userSessionData.gender = dto.text;
      if (dto.tag.domElement.name === "dateofbirth") {
        this.userSessionData.dateOfBirth = dto.text;
        this.conversationalForm.addTags(this.daySelectionTags, true);
      }
      // if (dto.tag.domElement.name === "specialistselection") {
      //   this.userSessionData.practitionerId = this.userSessionData.doctors[
      //     dto.text
      //   ].id;
      //   this.userSessionData.practitionerName = dto.text;

      //   const tags = [
      //     {
      //       tag: "cf-robot-message",
      //       name: "message",
      //       "cf-questions": `Splendid,`,
      //     },
      //     {
      //       tag: "select",
      //       name: "startoptions",
      //       "cf-questions": "Now, when would you like the online consultation?",
      //       children: [
      //         {
      //           tag: "option",
      //           "cf-label": "Next available Date",
      //           value: "",
      //         },
      //         {
      //           tag: "option",
      //           "cf-label": "Choose my own Date",
      //           value: "",
      //         },
      //       ],
      //     },
      //   ];
      //   this.conversationalForm.addTags(tags, true);
      //   //window.practitionerId = userSessionData.doctors[dto.text].id;
      // }
      if (dto.tag.domElement.name === "fetchspecialistsfailed") {
        if (dto.text === "Contact a different type of specialist") {
          // const tags = [
          //   {
          //     tag: "select",
          //     name: "specialistType",
          //     "cf-questions": "What type of specialist are you looking for?",
          //     children: [
          //       {
          //         tag: "option",
          //         "cf-label": "General Surgeon",
          //         value: "",
          //       },
          //       {
          //         tag: "option",
          //         "cf-label": "Family Physician",
          //         value: "",
          //       },
          //       {
          //         tag: "option",
          //         "cf-label": "Paediatric Doctor",
          //         value: "",
          //       },
          //       {
          //         tag: "option",
          //         "cf-label": "Dietician",
          //         value: "",
          //       },
          //       {
          //         tag: "option",
          //         "cf-label": "Emergency Doctor",
          //         value: "",
          //       },
          //       {
          //         tag: "option",
          //         "cf-label": "Urologist",
          //         value: "",
          //       },
          //       {
          //         tag: "option",
          //         "cf-label": "Obstetrician & Gynecologist",
          //         value: "",
          //       },
          //     ],
          //   },
          // ];
          // this.conversationalForm.addTags(tags, true);
        }
        if (dto.text === "Take me back to selecting a practitioner") {
          this.$router.push("/?special=true");
          // const tags = [
          //   {
          //     tag: "cf-robot-message",
          //     name: "message",
          //     "cf-questions": "Please select from the options below",
          //   },
          //   {
          //     tag: "select",
          //     name: "doctortype",
          //     "cf-questions":
          //       "Would you like to see a general practitioner, a family physician or a specialist?",
          //     children: [
          //       {
          //         tag: "option",
          //         "cf-label": "General Practitioner",
          //         value: "",
          //       },
          //       {
          //         tag: "option",
          //         "cf-label": "Family Physician",
          //         value: "",
          //       },
          //       {
          //         tag: "option",
          //         "cf-label": "Specialist",
          //         value: "",
          //       },
          //     ],
          //   },
          // ];
          // this.conversationalForm.addTags(tags, true);
        }
        if (dto.text === "Cancel") {
          this.$router.push({ path: "/" });
        }
        if (dto.text === "Try again") {
          // post
          const payload = {
            sz_firstname: "",
            sz_email: "",
            sz_password: "",
            sz_lastname: "",
            sz_dob: "",
            sz_gender: "",
            sz_phoneno: this.userSessionData.mobile,
            sz_marital_status: "",
            sz_nationality: "",
            sz_native_language: "",
            sz_religion: "",
            sz_occupation: "",
            sz_employer: "",
            sz_ice_name: "",
            sz_ice_place_of_work: "",
            sz_ice_address: "",
            sz_ice_phoneno: "",
            sz_bookedfortime: "",
            sz_bookername: "",
            sz_paymentmethod: "",
            sz_complaint: "suspected-covid-19",
            sz_docempid: "",
            sz_docname: "",
            sz_unitid: "",
            sz_othername: "",
            sz_address: "",
            sz_title: "",
            phone: this.userSessionData.mobile,
          };

          this.conversationalForm.addRobotChatResponse(
            "Fetching available specialists..."
          );

          try {
            // Make a request for a user with a given ID
            let response = await axios.get(
              this.apiUrl +
                "/employee/specialist?specialty=" +
                this.userSessionData.doctorSpecialty
            );

            // Try an existing account
            const tags = [
              {
                tag: "select",
                name: "specialistselection",
                "cf-questions": `Here are the available ${dto.text} doctors.`,
                children: [],
              },
            ];
            for (let doctor of response.data) {
              const fullName =
                "Dr" +
                " " +
                doctor.sz_fname.trim() +
                " " +
                doctor.sz_oname.trim() +
                " " +
                doctor.sz_lname.trim();
              tags[0].children.push({
                tag: "option",
                "cf-label": fullName.trim(),
              });
              this.userSessionData.doctors[fullName.trim()] = doctor;
            }
            if (tags[0].children.count <= 0) {
              const tags = [
                {
                  tag: "cf-robot-message",
                  name: "message",
                  "cf-questions": `Sorry, there are no specialist available for ${dto.text}`,
                },
                {
                  tag: "select",
                  name: "fetchspecialistsfailed",
                  "cf-questions":
                    "Would you like to contact a general practitioner, select a different type of specialist or try again",
                  children: [
                    {
                      tag: "option",
                      "cf-label": "Try again",
                      value: "",
                    },
                    // {
                    //   tag: "option",
                    //   "cf-label": "Contact a different type of specialist",
                    //   value: "",
                    // },
                    {
                      tag: "option",
                      "cf-label": "Take me back to selecting a practitioner",
                      value: "",
                    },
                    {
                      tag: "option",
                      "cf-label": "Cancel",
                      value: "",
                    },
                  ],
                },
              ];
              this.conversationalForm.addTags(tags, true);
            } else this.conversationalForm.addTags(tags, true);

            console.log(response);
          } catch (e) {
            // Try an existing account
            const tags = [
              {
                tag: "cf-robot-message",
                name: "message",
                "cf-questions": `Sorry, there was a problem and I couldn't fetch any specialists`,
              },
              {
                tag: "select",
                name: "fetchspecialistsfailed",
                "cf-questions":
                  "Would you like to contact a general practitioner, select a different type of specialist or try again",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  // {
                  //   tag: "option",
                  //   "cf-label": "Contact a different type of specialist",
                  //   value: "",
                  // },
                  {
                    tag: "option",
                    "cf-label": "Take me back to selecting a practitioner",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
        }
      }
      if (dto.tag.domElement.name === "dayselection") {
        if (dto.text === "Next available Date") {
          // TODO: Make a network call: Get next Available time slot
          this.conversationalForm.addRobotChatResponse(
            "Fetching next available time slot..."
          );
          let payload = {
            service: this.userSessionData.serviceValue.health_service,
          };
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patient/slots/nextavailable",
              payload
            );

            this.Timeslots = response.data;
            let timing = [];

            this.Timeslots.forEach((el) => {
              const time = this.$moment(el.startDateTime).format("LT");
              // const date = el.startDateTime.split(" ")[0];
              // console.log(time);
              const date = this.$moment(el.startDateTime).calendar();
              // TODO: Update this tag with api response
              // const parsedDate = new Date(date);
              el.timing = date;
              timing.push({
                tag: "option",
                "cf-label": date,
                value: date,
              });
            });
            const tags = [
              {
                tag: "cf-robot-message",
                name: "message",
                "cf-questions": "These are the available time slots",
              },
              {
                tag: "select",
                name: "nextavailabletimeslot",
                "cf-questions":
                  "Select the available time slot to proceed or select a different date",
                children: timing,
              },
            ];

            this.conversationalForm.addTags(tags, true);
            console.log(
              "logging axios..(success)",
              this.userSessionData.appointmentDateTime
            );
            console.log("logging axios..(success)", response);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "slotsfailed",
                "cf-questions":
                  "Sorry, I could not fetch the available time slots.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Pick other dates",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
        }
        if (dto.text === "Choose my own Date") {
          // add choose your own date tags
          const date = new Date();
          if (date.getHours() > 22)
            this.conversationalForm.addTags(this.otherTimeSlotsNoToday, true);
          if (date.getHours() <= 22)
            this.conversationalForm.addTags(this.otherTimeSlots, true);
        }
      }
      if (dto.tag.domElement.name === "startoptions") {
        if (dto.text === "Next available Date") {
          // TODO: Make a network call: Get next Available time slot
          this.conversationalForm.addRobotChatResponse(
            "Fetching next available time slot..."
          );
          let payload = {
            service: this.userSessionData.serviceValue.health_service,
          };
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patient/slots/nextavailable",
              payload
            );

            this.Timeslots = response.data;
            let timing = [];

            this.Timeslots.forEach((el) => {
              const time = this.$moment(el.startDateTime).format("LT");
              // const date = el.startDateTime.split(" ")[0];
              // console.log(time);
              const date = this.$moment(el.startDateTime).calendar();
              // TODO: Update this tag with api response
              // const parsedDate = new Date(date);
              el.timing = date;
              timing.push({
                tag: "option",
                "cf-label": date,
                value: date,
              });
            });
            const tags = [
              {
                tag: "cf-robot-message",
                name: "message",
                "cf-questions": "These are the available time slots",
              },
              {
                tag: "select",
                name: "nextavailabletimeslot",
                "cf-questions":
                  "Select the available time slot to proceed or select a different date",
                children: timing,
              },
            ];

            this.conversationalForm.addTags(tags, true);
            console.log(
              "logging axios..(success)",
              this.userSessionData.appointmentDateTime
            );
            console.log("logging axios..(success)", response);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "slotsfailed",
                "cf-questions":
                  "Sorry, I could not fetch the available time slots.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Pick other dates",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
        }
        if (dto.text === "Choose my own Date") {
          // add choose your own date tags
          const date = new Date();
          if (date.getHours() > 22)
            this.conversationalForm.addTags(this.otherTimeSlotsNoToday, true);
          if (date.getHours() <= 22)
            this.conversationalForm.addTags(this.otherTimeSlots, true);
        }
      }
      if (dto.tag.domElement.name === "nextavailabletimeslot") {
        this.Timeslots.forEach((el) => {
          if (el.timing == dto.text) {
            this.userSessionData.slotId = el.slotid;
            this.userSessionData.appointmentDateTime = el.startDateTime;
            this.userSessionData.selectedTime = el.timing;
            this.userSessionData.resultData = el;
            console.log(this.userSessionData.resultData.doctorName);
          }
        });
      }
      if (dto.tag.domElement.name === "slotsfailed") {
        if (dto.text === "Try again") {
          // TODO: Make a network call: Get next Available time slot
          this.conversationalForm.addRobotChatResponse(
            "Fetching next available time slot..."
          );
          let payload = {
            service: this.userSessionData.serviceValue.health_service,
          };
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patient/slots/nextavailable",
              payload
            );
            console.log(response.data);
            let timing = [];

            response.data.forEach((el) => {
              const time = this.$moment(el.startDateTime).format("LT");
              console.log(time);
              // const date = el.startDateTime.split(" ")[0];
              const date = this.$moment(el.startDateTime).calendar();
              // TODO: Update this tag with api response
              // const parsedDate = new Date(date);
              timing.push({
                tag: "option",
                "cf-label": date,
                value: date,
              });
            });
            console.log(timing);
            const tags = [
              {
                tag: "cf-robot-message",
                name: "message",
                "cf-questions": "These are the available time slots",
              },
              {
                tag: "select",
                name: "nextavailabletimeslot",
                "cf-questions":
                  "Select the available time slot to proceed or select a different date",
                children: timing,
              },
            ];

            console.log(
              "logging axios..(success)",
              this.userSessionData.appointmentDateTime
            );
            console.log("logging axios..(success)", response);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "slotsfailed",
                "cf-questions":
                  "Sorry, I could not fetch the available time slots.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Pick other dates",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
        }
        if (dto.text === "Pick other dates") {
          // add choose your own date tags
          const date = new Date();
          if (date.getHours() > 22)
            this.conversationalForm.addTags(this.otherTimeSlotsNoToday, true);
          if (date.getHours() <= 22)
            this.conversationalForm.addTags(this.otherTimeSlots, true);
        }
        if (dto.text === "Cancel") {
          this.$router.push("/");
        }
      }
         if (dto.tag.domElement.name === "otherTimeSlots") {
        this.userSessionData.day = dto.text;

        const date = new Date();
        let currentDate =
          date.getFullYear() +
          "-" +
          (parseInt(date.getMonth() + 1) < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) +
          "-" +
          date.getDate() +
          " " +
          "00:00";
        const tommorrow = new Date(date.setDate(date.getDate() + 1));
        const tomorrowDate =
          tommorrow.getFullYear() +
          "-" +
          (parseInt(tommorrow.getMonth() + 1) < 10
            ? "0" + (tommorrow.getMonth() + 1)
            : tommorrow.getMonth() + 1) +
          "-" +
          tommorrow.getDate() +
          " " +
          "00:00";
        const nextDate = new Date(date.setDate(date.getDate() + 1));
        const nextDayDate =
          nextDate.getFullYear() +
          "-" +
          (parseInt(nextDate.getMonth() + 1) < 10
            ? "0" + (nextDate.getMonth() + 1)
            : nextDate.getMonth() + 1) +
          "-" +
          nextDate.getDate() +
          " " +
          "00:00";
        let payload = {
          startDateTime:
            currentDate.split(" ")[0] +
            " " +
            date.getHours() +
            ":" +
            date.getMinutes(),
          endDateTime: tomorrowDate,
          // add specialist Id here,
          service: this.userSessionData.serviceValue.health_service,
          practitioner_id: this.userSessionData.practitionerId,
        };
        if (dto.text === "Today") {
          this.conversationalForm.addRobotChatResponse(
            "Fetching available time slots..."
          );
          // TODO: Make a network call: Get Available time slots for today and then add time selections tags
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patient/slots/available",
              payload
            );
            const availableTimeSlots = [
              {
                tag: "select",
                name: "availabletimeslots",
                "cf-questions": "The available time slots for today are:",
                children: [],
              },
            ];
            this.userSessionData.result = [];
            for (let item of response.data) {
              const time = item.startDateTime.split("T")[1];
              const parsedTime =
                time.split(":")[0] +
                ":" +
                time.split(":")[1] +
                (parseInt(time.split(":")[0]) > 11 ? "pm" : "am");
              if (!this.userSessionData.result.includes(parsedTime)) {
                this.userSessionData.result.push(parsedTime);
                availableTimeSlots[0].children.push({
                  tag: "option",
                  "cf-label": parsedTime,
                  value: "",
                });
                this.userSessionData.resultData[parsedTime] = item;
              }
            }

            // handle success
            if (response.data.length > 0)
              this.conversationalForm.addTags(availableTimeSlots, true);
            if (response.data.length === 0) {
              const tags = [
                {
                  tag: "select",
                  name: "otherslotsfailed",
                  "cf-questions": "Sorry, There are no slots.",
                  children: [
                    {
                      tag: "option",
                      "cf-label": "Try again",
                      value: "",
                    },
                    {
                      tag: "option",
                      "cf-label": "Pick other dates",
                      value: "",
                    },
                    {
                      tag: "option",
                      "cf-label": "Cancel",
                      value: "",
                    },
                  ],
                },
              ];
              this.conversationalForm.addTags(tags, true);
            }
            console.log("logging axios..(success)", response);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "otherslotsfailed",
                "cf-questions": "Sorry, I could not get a service response.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Pick other dates",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
          while (currentDate.includes("-"))
            currentDate = currentDate.replace("-", "/");
          this.userSessionData.appointmentDateTime = currentDate.split(" ")[0];
        }
        payload = {
          startDateTime: tomorrowDate,
          endDateTime: nextDayDate,
          // add specialist Id here,
          service: this.userSessionData.serviceValue.health_service,
          practitioner_id: this.userSessionData.practitionerId,
        };
        if (dto.text === "Tomorrow") {
          this.conversationalForm.addRobotChatResponse(
            "Fetching available time slots..."
          );
          // TODO: Make a network call: Get Available time slots for tomorrow and then add time selections tags
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patient/slots/available",
              payload
            );
            const availableTimeSlots = [
              {
                tag: "select",
                name: "availabletimeslots",
                "cf-questions": "The available time slots for tomorrow are:",
                children: [],
              },
            ];
            this.userSessionData.result = [];
            for (let item of response.data) {
              const time = item.startDateTime.split("T")[1];
              const parsedTime =
                time.split(":")[0] +
                ":" +
                time.split(":")[1] +
                (parseInt(time.split(":")[0]) > 11 ? "pm" : "am");
              if (!this.userSessionData.result.includes(parsedTime)) {
                this.userSessionData.result.push(parsedTime);
                availableTimeSlots[0].children.push({
                  tag: "option",
                  "cf-label": parsedTime,
                  value: "",
                });
                this.userSessionData.resultData[parsedTime] = item;
              }
            }

            // handle success
            if (response.data.length > 0)
              this.conversationalForm.addTags(availableTimeSlots, true);
            if (response.data.length === 0) {
              const tags = [
                {
                  tag: "select",
                  name: "otherslotsfailed",
                  "cf-questions":
                    "Sorry, It looks like all the available slots for tomorrow have been booked. There are no available slots.",
                  children: [
                    {
                      tag: "option",
                      "cf-label": "Try again",
                      value: "",
                    },
                    {
                      tag: "option",
                      "cf-label": "Pick other dates",
                      value: "",
                    },
                    {
                      tag: "option",
                      "cf-label": "Cancel",
                      value: "",
                    },
                  ],
                },
              ];
              this.conversationalForm.addTags(tags, true);
            }
            console.log("logging axios..(success)", response);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            this.conversationalForm.addRobotChatResponse("");
            const tags = [
              {
                tag: "select",
                name: "otherslotsfailed",
                "cf-questions":
                  "Sorry, I could not fetch the available time slots for this date.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Pick other dates",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
          this.userSessionData.appointmentDateTime = tomorrowDate.split(" ")[0];
        }
        if (dto.text === "Choose other days") {
          // open date selection popup
          const tags = [
            {
              tag: "input",
              name: "selectOtherDate",
              "cf-input-placeholder": "dd/mm/yyyy",
              "cf-questions": "<strong>eg. 24/07/2020</strong>",
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
      }
      if (dto.tag.domElement.name === "selectOtherDate") {
        let start = dto.text
          .replace(/[/]/gi, "-")
          .split("-")
          .reverse()
          .join("-");
        let realDate = moment(start).format("Do MMM, YYYY");
        console.log(start);
        let payload = {
          startDateTime: start + " " + "00:00",
          endDateTime: start + " " + "23:59",
          // add specialist Id here,
          service: this.userSessionData.serviceValue.health_service,
          // practitioner_id: this.userSessionData.practitionerId,
        };
        this.conversationalForm.addRobotChatResponse(
          "Fetching available time slots..."
        );
        // TODO: Make a network call: Get Available time slots for tomorrow and then add time selections tags
        try {
          // Make a request for a user with a given ID
          const response = await axios.post(
            this.apiUrl + "/patient/slots/available",
            payload
          );
          const availableTimeSlots = [
            {
              tag: "select",
              name: "availabletimeslots",
              "cf-questions": `The available time slots for ${realDate} are:`,
              children: [],
            },
          ];
          this.userSessionData.result = [];
          for (let item of response.data) {
            const time = item.startDateTime.split("T")[1];
            const parsedTime =
              time.split(":")[0] +
              ":" +
              time.split(":")[1] +
              (parseInt(time.split(":")[0]) > 11 ? "pm" : "am");
            if (!this.userSessionData.result.includes(parsedTime)) {
              this.userSessionData.result.push(parsedTime);
              availableTimeSlots[0].children.push({
                tag: "option",
                "cf-label": parsedTime,
                value: "",
              });
              this.userSessionData.resultData[parsedTime] = item;
            }
          }

          // handle success
          if (response.data.length > 0)
            this.conversationalForm.addTags(availableTimeSlots, true);
          if (response.data.length === 0) {
            const tags = [
              {
                tag: "select",
                name: "otherslotsfailed",
                "cf-questions":
                  "Sorry, It looks like all the available slots for tomorrow have been booked. There are no available slots.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Pick other dates",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
          console.log("logging axios..(success)", response);
        } catch (e) {
          // handle success
          console.log("logging axios..(error)", e);
          this.conversationalForm.addRobotChatResponse("");
          const tags = [
            {
              tag: "select",
              name: "otherslotsfailed",
              "cf-questions":
                "Sorry, I could not fetch the available time slots for this date.",
              children: [
                {
                  tag: "option",
                  "cf-label": "Try again",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Pick other dates",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Cancel",
                  value: "",
                },
              ],
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
        this.userSessionData.appointmentDateTime = start;
      }
      if (dto.tag.domElement.name === "availabletimeslots") {
        this.userSessionData.appointmentDateTime += " " + dto.text.slice(0, 5);
        this.userSessionData.selectedTime = dto.text;
        this.paymentSelectionTags[0][
          "cf-questions"
        ] = `The cost of your test is ${this.userSessionData.serviceValue.currency} ${this.userSessionData.serviceValue.charge}. How would you like to pay?`;
        this.conversationalForm.addTags(this.paymentSelectionTags, true);
      }
      if (dto.tag.domElement.name === "nextavailabletimeslot") {
        if (dto.text === "Pick other dates") {
          // add choose your own date tags
          const date = new Date();
          if (date.getHours() > 22)
            this.conversationalForm.addTags(this.otherTimeSlotsNoToday, true);
          if (date.getHours() <= 22)
            this.conversationalForm.addTags(this.otherTimeSlots, true);
        } else
          this.paymentSelectionTags[0][
            "cf-questions"
          ] = `The cost of your test is ${this.userSessionData.serviceValue.currency} ${this.userSessionData.serviceValue.charge}. How would you like to pay?`;
        this.conversationalForm.addTags(this.paymentSelectionTags, true);
      }

      if (dto.tag.domElement.name === "bookingfor") {
        if (dto.text === "Yes,  myself") {
          if (this.loggedIn) {
            this.userSessionData.fullName =
              this.userInfo.szfirstname +
              `${
                this.userInfo.sz_othername
                  ? +" " + this.userInfo.sz_othername + " "
                  : " "
              }` +
              this.userInfo.szlastname;
            this.userSessionData.userId = this.userInfo.sz_userid;
            this.userSessionData.mobile = this.userInfo.sz_phoneno;
            this.userSessionData.dateOfBirth = this.userInfo.szdob;

            if (!!this.sz_companyid) {
              this.userSessionData.hasCorporate = true;
              this.userSessionData.companyId = this.userInfo.sz_companyid;
            }
            if (!!this.sz_companyname)
              this.userSessionData.companyName = this.userInfo.sz_companyname;

            this.validateMyInfoTags[1]["cf-questions"] = `You are ${
              this.userSessionData.fullName
            }, ${
              this.$moment().diff(this.userSessionData.dateOfBirth, 'years', false)
            } years, mobile ${this.userSessionData.mobile}. Is this correct?`;
            this.conversationalForm.addTags(this.validateMyInfoTags, true);
          } else this.userSessionData.isBookingForSelf = true;
        } else if ("No, someone else") {
        }
      }

      if (dto.tag.domElement.name === "userexistence") {
        if (dto.text === "Yes,  I’m a new user") {
          this.conversationalForm.addTags(
            this.newUserDetailsCollectionTags,
            true
          );
          this.userSessionData.isUserNew = true;
        }
        if (dto.text === "No I’m an existing user") {
          this.conversationalForm.addTags(
            this.existingUserDetailsCollectionTags,
            true
          );
          this.userSessionData.isUserNew = false;
        }
      }

      // Existing User Authentication steps
      // (1)
      if (dto.tag.domElement.name === "mobile") {
        this.userSessionData.mobile = dto.text;

        // post
        const payload = {
          sz_firstname: "",
          sz_email: "",
          sz_password: "",
          sz_lastname: "",
          sz_dob: "",
          sz_gender: "",
          sz_phoneno: this.userSessionData.mobile,
          sz_marital_status: "",
          sz_nationality: "",
          sz_native_language: "",
          sz_religion: "",
          sz_occupation: "",
          sz_employer: "",
          sz_ice_name: "",
          sz_ice_place_of_work: "",
          sz_ice_address: "",
          sz_ice_phoneno: "",
          sz_bookedfortime: "",
          sz_bookername: "",
          sz_paymentmethod: "",
          sz_complaint: "suspected-covid-19",
          sz_docempid: "",
          sz_docname: "",
          sz_unitid: "",
          sz_othername: "",
          sz_address: "",
          sz_title: "",
          phone: this.userSessionData.mobile,
        };

        // verify account
        this.conversationalForm.addRobotChatResponse("Verifying...");
        try {
          // Make a request for a user with a given ID
          let response = await axios.post(
            this.apiUrl + "/patient/checkexistence",
            payload
          );

          const patientResponse = await axios.post(
            this.apiUrl + "/patient/get",
            {
              szpatientid: response.data.sz_userid,
            }
          );

          // handle success
          console.log("logging axios..(success)", response);
          this.userSessionData.userData = patientResponse.data;
          this.userSessionData.fullName = response.data.sz_username;
          this.userSessionData.userId = response.data.sz_userid;
          this.userSessionData.userData.sz_userid = response.data.sz_userid;
          if (!!response.data.sz_companyid) {
            this.userSessionData.hasCorporate = true;
            this.userSessionData.companyId = response.data.sz_companyid;
          }
          if (!!response.data.sz_companyname)
            this.userSessionData.companyName = response.data.sz_companyname;

          // Send code
          response = await axios.post(
            this.apiUrl + "/user/sendotpcode",
            payload
          );
          // handle success
          console.log("logging axios..(success)", response);
          this.userSessionData.code = response.data.code;
          this.conversationalForm.addTags(this.verifyLoginCodeInputTags, true);
        } catch (e) {
          // handle success
          console.log("logging axios..(error)", e);
          this.conversationalForm.addRobotChatResponse("");
          // Try an existing account
          const tags = [
            {
              tag: "select",
              name: "tryexisting",
              "cf-questions":
                "Your account could not be verified. If you are a new user please sign up by clicking on the sign up button at the top of this page before booking for a doctor",
              children: [
                {
                  tag: "option",
                  "cf-label": "Try again",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Register a new account",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Cancel",
                  value: "",
                },
              ],
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
      }
      if (dto.tag.domElement.name === "tryexisting") {
        if (
          dto.text === "Try logging into an existing account" ||
          dto.text === "Try again"
        )
          this.conversationalForm.addTags(
            this.existingUserDetailsCollectionTags,
            true
          );
        if (dto.text === "Register a new account") {
          this.conversationalForm.addTags(
            this.newUserDetailsCollectionTags,
            true
          );
          this.userSessionData.isUserNew = true;
        }
        if (dto.text === "Cancel") closeForm();
      }
      if (dto.tag.domElement.name === "verifylogincode") {
        if (dto.text == this.userSessionData.code) {
          if (this.userSessionData.isBookingForSelf) {
            this.$store.commit("authStore/setLoggedIn", true);
            this.$store.commit(
              "authStore/setUserInfo",
              this.userSessionData.userData
            );
            this.$store.commit(
              "authStore/setId",
              this.userSessionData.userData.id
            );
          }
          this.welcomeBackMessageTags[0][
            "cf-questions"
          ] = `Welcome back ${this.userSessionData.fullName}`;
          this.welcomeBackMessageTags[2][
            "cf-questions"
          ] = `Please verify your appointment date and time. You opted for ${
            this.userSessionData.appointmentDateTime.split(" ")[0]
          } at ${this.userSessionData.appointmentDateTime.split(" ")[1]}`;
          this.conversationalForm.addTags(this.welcomeBackMessageTags, true);
        } else {
          this.conversationalForm.addRobotChatResponse(
            "Wrong input. Try again."
          );
          this.conversationalForm.addTags(this.verifyLoginCodeInputTags, true);
        }
      }
      // Eight interaction: Payment
      if (dto.tag.domElement.name === "proceedwithappointment") {
        if (dto.text === "Proceed") {
          if (this.userSessionData.hasCorporate) {
            this.paymentSelectionTags[0].children.push({
              tag: "option",
              "cf-label": "Bill my company",
              value: "",
            });
          }
          this.paymentSelectionTags[0][
            "cf-questions"
          ] = `Your total fee for the ${this.userSessionData.appointmentType} is ${this.userSessionData.appointmentCost}. How would you like to pay`;
          this.conversationalForm.addTags(this.paymentSelectionTags, true);
        }
        if (dto.text === "Cancel")
          this.conversationalForm.addTags(this.cancelTags, true); // TODO: exit
      }
      // Payment type picked
      if (dto.tag.domElement.name === "paymentselection") {
        if (dto.text === "Mobile Money" || dto.text === "Try Mobile Money") {
          this.conversationalForm.addTags(this.mobileMoneyOptionsTags, true);
          this.userSessionData.paymentMethod = "mobile-money";
        }
        if (dto.text === "Cash") {
          this.userSessionData.paymentMethod = "cash";
          // book slot:
          this.conversationalForm.addRobotChatResponse(
            "Booking your appointment..."
          );
          // const slotInfo = this.userSessionData.resultData[
          //   this.userSessionData.selectedTime
          // ];
          // this.userSessionData.slotId = slotInfo.slotid;

          let payload = {
            slotid: this.userSessionData.slotId,
            patientName: this.userSessionData.fullName,
            mobile: this.userSessionData.mobile,
            email: this.userSessionData.mobile + "@Nhyahohealth.com",
            patientId: this.userSessionData.userId,
            sz_bookerid: this.userSessionData.userId,
            sz_bookername: this.userSessionData.fullName,
            sz_bookedforid: this.userSessionData.userId,
            sz_docempid: this.userSessionData.resultData.assignedDoctorId,
            sz_docname: this.userSessionData.resultData.doctorName,
            sz_bookedtime: this.userSessionData.appointmentDateTime,
            sz_location: this.userSessionData.streetAddress,
            sz_payerid: this.userSessionData.userId,
            payername: "self",
            service: this.userSessionData.serviceValue.health_service
              ? this.userSessionData.serviceValue.health_service
              : "",
            tier: this.userSessionData.serviceValue.id
              ? this.userSessionData.serviceValue.id
              : "",
            sz_bookingnote: "",
            sz_bookingstatus: "",
            sz_bookingmode: "Online",
            sz_hospid: "",
            sz_unitid: "",
            sz_paymentmethod: this.userSessionData.paymentMethod,
            sz_bookedforname: this.userSessionData.fullName,
            sz_complaint: "",
            assignedDoctorId: this.userSessionData.resultData.assignedDoctorId,
            sz_bookingtype: this.userSessionData.appointmentTypeKey,
            sz_bookedfortime: this.userSessionData.appointmentDateTime,
          };
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patients/bookappointment",
              payload
            );

            // handle success
            console.log("logging axios..(success)", response);
            this.finalSessionMessageTags[0][
              "cf-questions"
            ] = `Thanks for having me, ${this.userSessionData.fullName}. Your payment has been initiated, once your payment succeeds. You will receive an SMS and Email to confirm your appointment booking.`;
            this.conversationalForm.addTags(this.finalSessionMessageTags, true);
            console.log("logging axios..(success)", "response");
          } catch (e) {
            // handle error
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "timeselectionfailed",
                "cf-questions":
                  "Sorry, something went wrong and I could not select this slot.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Book a new appointment",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
        }
        if (dto.text === "Visa")
          this.conversationalForm.addTags(noVisaTags, true); // TODO: exit
        if (dto.text === "Bill my company") {
          const tags = [
            {
              tag: "select",
              name: "insuranceprovider",
              "cf-questions": `${this.userSessionData.companyName} will be charged for your virtual care. would you like to proceed?`,
              children: [
                {
                  tag: "option",
                  "cf-label": "Yes, proceed",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "No, cancel",
                  value: "",
                },
              ],
            },
          ];
          this.conversationalForm.addTags(tags, true);
          this.userSessionData.paymentMethod = "corporate";
        }
        if (dto.text === "I don't have mobile money")
          this.conversationalForm.addRobotChatResponse(
            "Sorry, You will need a payment option to proceed. Thank you."
          );
      }
      // Mobile money option picked
      if (dto.tag.domElement.name === "mobilemoneyoptions") {
        if (dto.text === "MTN") this.userSessionData.provider = "mtn";
        if (dto.text === "Airtel Tigo")
          this.userSessionData.provider = "airtel";
        if (dto.text === "Vodafone") this.userSessionData.provider = "vodafone";
      }
      if (dto.tag.domElement.name === "mobilemoneynumber") {
        this.userSessionData.momonumber = dto.text;
      }
      // Tenth interaction: Payment -> Mobile Money -> select provider
      if (dto.tag.domElement.name === "mobilemoneyproviderselection") {
        if (dto.text === "MTN") this.userSessionData.provider = "mtn";
        if (dto.text === "Airtel Tigo")
          this.userSessionData.provider = "airtel";
        if (dto.text === "Vodafone") this.userSessionData.provider = "vodafone";
        this.mobileMoneyNumberInputTags[0]["cf-questions"] =
          "A charge of " +
          this.userSessionData.appointmentCost +
          " will be charged to your account";
        this.conversationalForm.addTags(this.mobileMoneyNumberInputTags, true);
      }
      if (
        dto.tag.domElement.name === "mobilemoneynumber" &&
        this.userSessionData.provider != "vodafone"
      ) {
        // book slot:
        this.conversationalForm.addRobotChatResponse(
          "Booking your appointment..."
        );
        // const slotInfo = this.userSessionData.resultData[
        //   this.userSessionData.selectedTime
        // ];
        // this.userSessionData.slotId = slotInfo.slotid;

        let payload = {
          slotid: this.userSessionData.slotId,
          patientName: this.userSessionData.fullName,
          mobile: this.userSessionData.mobile,
          email: this.userSessionData.mobile + "@Nhyahohealth.com",
          patientId: this.userSessionData.userId,
          sz_bookerid: this.userSessionData.userId,
          sz_bookername: this.userSessionData.fullName,
          sz_bookedforid: this.userSessionData.userId,
          sz_docempid: this.userSessionData.resultData.assignedDoctorId,
          sz_docname: this.userSessionData.resultData.doctorName,
          sz_bookedtime: this.userSessionData.appointmentDateTime,
          sz_location: this.userSessionData.streetAddress,
          sz_payerid: this.userSessionData.userId,
          payername: "self",
          service: this.userSessionData.serviceValue.health_service
            ? this.userSessionData.serviceValue.health_service
            : "",
          tier: this.userSessionData.serviceValue.id
            ? this.userSessionData.serviceValue.id
            : "",
          sz_bookingnote: "",
          sz_bookingstatus: "",
          sz_bookingmode: "Online",
          sz_hospid: "",
          sz_unitid: "",
          sz_paymentmethod: this.userSessionData.paymentMethod,
          sz_bookedforname: this.userSessionData.fullName,
          sz_complaint: "",
          assignedDoctorId: this.userSessionData.resultData.assignedDoctorId,
          sz_bookingtype: this.userSessionData.appointmentTypeKey,
          sz_bookedfortime: this.userSessionData.appointmentDateTime,
        };
        try {
          // Make a request for a user with a given ID
          const response = await axios.post(
            this.apiUrl + "/patients/bookappointment",
            payload
          );

          // handle success
          this.userSessionData.patientId = response.data.sz_bookedforid;
          this.userSessionData.appointmentId = response.data.id;
          this.userSessionData.chargeItem = response.data.charge_item_id;

          this.userSessionData.voucher = dto.text;
          //POST: api
          payload = {
            vendor: this.userSessionData.provider,
            mobile:
              "233" +
              (this.userSessionData.momonumber[0] === "0"
                ? this.userSessionData.momonumber.slice(1)
                : this.userSessionData.momonumber),
            // sz_paymentnumber:
            //   "233" +
            //   (this.userSessionData.momonumber[0] === "0"
            //     ? this.userSessionData.momonumber.slice(1)
            //     : this.userSessionData.momonumber),
            service: this.userSessionData.appointmentTypeKey,
            // productid: "",
            // status: "pending",
            trans_type: "debit",
            voucher_number: this.userSessionData.voucher,
            charge_item: this.userSessionData.chargeItem,
            // slotid: this.userSessionData.slotId,
            // provider: "Nyaho",
            // patientName: this.userSessionData.fullName,
            // patientId: this.userSessionData.patientId,
            // appointmentId: this.userSessionData.appointmentId,
          };

          // call payment api
          this.conversationalForm.addRobotChatResponse("Verifying payment...");
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/finance/payment/mobile-money",
              payload
            );

            // handle success
            console.log("logging axios..(success)", response);
            this.finalSessionMessageTags[0][
              "cf-questions"
            ] = `Thanks for having me, ${this.userSessionData.fullName}. Your payment has been initiated, once your payment succeeds. You will receive an SMS and Email to confirm your appointment booking.`;
            this.finalSessionMTNMessageTags[0][
              "cf-questions"
            ] = `Thanks for having me, ${this.userSessionData.fullName}. Your payment has been initiated, once your payment succeeds. You will receive an SMS and Email to confirm your appointment booking.`;

            if (this.userSessionData.provider === "mtn")
              this.conversationalForm.addTags(
                this.finalSessionMTNMessageTags,
                true
              );
            if (this.userSessionData.provider != "mtn")
              this.conversationalForm.addTags(
                this.finalSessionMessageTags,
                true
              );
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            this.conversationalForm.addTags(this.paymentFailedTags, true);
          }
        } catch (e) {
          // handle error
          console.log("logging axios..(error)", e);
          const tags = [
            {
              tag: "select",
              name: "timeselectionfailed",
              "cf-questions":
                "Sorry, something went wrong and I could not select this slot.",
              children: [
                {
                  tag: "option",
                  "cf-label": "Try again",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Book a new appointment",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Cancel",
                  value: "",
                },
              ],
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
      } else if (
        dto.tag.domElement.name === "mobilemoneynumber" &&
        this.userSessionData.provider === "vodafone"
      ) {
        this.conversationalForm.addTags(this.vodafoneVoucherInputTags, true);
      }
      // Tenth interaction: Payment -> Mobile Money -> select provider
      if (dto.tag.domElement.name === "voucher") {
        // book slot:
        this.conversationalForm.addRobotChatResponse(
          "Booking your appointment..."
        );
        // const slotInfo = this.userSessionData.resultData[
        //   this.userSessionData.selectedTime
        // ];
        // this.userSessionData.slotId = slotInfo.slotid;

        let payload = {
          slotid: this.userSessionData.slotId,
          patientName: this.userSessionData.fullName,
          mobile: this.userSessionData.mobile,
          email: this.userSessionData.mobile + "@Nhyahohealth.com",
          patientId: this.userSessionData.userId,
          sz_bookerid: this.userSessionData.userId,
          sz_bookername: this.userSessionData.fullName,
          sz_bookedforid: this.userSessionData.userId,
          sz_docempid: this.userSessionData.resultData.assignedDoctorId,
          sz_docname: this.userSessionData.resultData.doctorName,
          sz_bookedtime: this.userSessionData.appointmentDateTime,
          sz_location: this.userSessionData.streetAddress,
          sz_payerid: this.userSessionData.userId,
          payername: "self",
          sz_bookingnote: "",
          sz_bookingstatus: "",
          service: this.userSessionData.serviceValue.health_service
            ? this.userSessionData.serviceValue.health_service
            : "",
          tier: this.userSessionData.serviceValue.id
            ? this.userSessionData.serviceValue.id
            : "",
          sz_bookingmode: "Online",
          sz_hospid: "",
          sz_unitid: "",
          sz_paymentmethod: this.userSessionData.paymentMethod,
          sz_bookedforname: this.userSessionData.fullName,
          sz_complaint: "",
          assignedDoctorId: this.userSessionData.resultData.assignedDoctorId,
          sz_bookingtype: this.userSessionData.appointmentTypeKey,
          sz_bookedfortime: this.userSessionData.appointmentDateTime,
        };
        try {
          // Make a request for a user with a given ID
          const response = await axios.post(
            this.apiUrl + "/patients/bookappointment",
            payload
          );
          // handle success
          this.userSessionData.patientId = response.data.sz_bookedforid;
          this.userSessionData.appointmentId = response.data.id;
          this.userSessionData.chargeItem = response.data.charge_item_id;

          this.userSessionData.voucher = dto.text;
          //POST: api
          payload = {
            vendor: this.userSessionData.provider,
            mobile:
              "233" +
              (this.userSessionData.momonumber[0] === "0"
                ? this.userSessionData.momonumber.slice(1)
                : this.userSessionData.momonumber),
            // sz_paymentnumber:
            //   "233" +
            //   (this.userSessionData.momonumber[0] === "0"
            //     ? this.userSessionData.momonumber.slice(1)
            //     : this.userSessionData.momonumber),
            service: this.userSessionData.appointmentTypeKey,
            // productid: "",
            // status: "pending",
            trans_type: "debit",
            voucher_number: this.userSessionData.voucher,
            charge_item: this.userSessionData.chargeItem,
            // slotid: this.userSessionData.slotId,
            // provider: "Nyaho",
            // patientName: this.userSessionData.fullName,
            // patientId: this.userSessionData.patientId,
            // appointmentId: this.userSessionData.appointmentId,
          };

          // call payment api
          this.conversationalForm.addRobotChatResponse("Verifying payment...");
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/finance/payment/mobile-money",
              payload
            );

            // handle success
            console.log("logging axios..(success)", response);
            this.finalSessionMessageTags[0][
              "cf-questions"
            ] = `Thanks for having me, ${this.userSessionData.fullName}. Your payment has been initiated, once your payment succeeds. You will receive an SMS and Email to confirm your appointment booking.`;
            this.conversationalForm.addTags(this.finalSessionMessageTags, true);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            this.conversationalForm.addTags(this.paymentFailedTags, true);
          }
        } catch (e) {
          // handle error
          console.log("logging axios..(error)", e);
          const tags = [
            {
              tag: "select",
              name: "timeselectionfailed",
              "cf-questions":
                "Sorry, something went wrong and I could not select this slot.",
              children: [
                {
                  tag: "option",
                  "cf-label": "Try again",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Book a new appointment",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Cancel",
                  value: "",
                },
              ],
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
      }
      if (dto.tag.domElement.name === "timeselectionfailed") {
        if (dto.text === "Try again") {
          this.conversationalForm.addRobotChatResponse(
            "Booking your appointment..."
          );
          // const slotInfo = this.userSessionData.resultData[
          //   this.userSessionData.selectedTime
          // ];
          // this.userSessionData.slotId = slotInfo.slotid;

          let payload = {
            slotid: this.userSessionData.slotId,
            patientName: this.userSessionData.fullName,
            mobile: this.userSessionData.mobile,
            email: this.userSessionData.mobile + "@Nhyahohealth.com",
            patientId: this.userSessionData.userId,
            sz_bookerid: this.userSessionData.userId,
            sz_bookername: this.userSessionData.fullName,
            sz_bookedforid: this.userSessionData.userId,
            sz_docempid: this.userSessionData.resultData.assignedDoctorId,
            sz_docname: this.userSessionData.resultData.doctorName,
            sz_bookedtime: this.userSessionData.appointmentDateTime,
            sz_location: this.userSessionData.streetAddress,
            sz_payerid: this.userSessionData.userId,
            payername: "self",
            sz_bookingnote: "",
            sz_bookingstatus: "",
            service: this.userSessionData.serviceValue.health_service
              ? this.userSessionData.serviceValue.health_service
              : "",
            tier: this.userSessionData.serviceValue.id
              ? this.userSessionData.serviceValue.id
              : "",
            sz_bookingmode: "Online",
            sz_hospid: "",
            sz_unitid: "",
            sz_paymentmethod: this.userSessionData.paymentMethod,
            sz_bookedforname: this.userSessionData.fullName,
            sz_complaint: "",
            assignedDoctorId: this.userSessionData.resultData.assignedDoctorId,
            sz_bookingtype: this.userSessionData.appointmentTypeKey,
            sz_bookedfortime: this.userSessionData.appointmentDateTime,
          };
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patients/bookappointment",
              payload
            );
            // handle success
            this.userSessionData.patientId = response.data.sz_bookedforid;
            this.userSessionData.appointmentId = response.data.id;
            this.userSessionData.chargeItem = response.data.charge_item_id;

            this.userSessionData.voucher = dto.text;
            //POST: api
            payload = {
              vendor: this.userSessionData.provider,
              mobile:
                "233" +
                (this.userSessionData.momonumber[0] === "0"
                  ? this.userSessionData.momonumber.slice(1)
                  : this.userSessionData.momonumber),
              // sz_paymentnumber:
              //   "233" +
              //   (this.userSessionData.momonumber[0] === "0"
              //     ? this.userSessionData.momonumber.slice(1)
              //     : this.userSessionData.momonumber),
              service: this.userSessionData.appointmentTypeKey,
              // productid: "",
              // status: "pending",
              trans_type: "debit",
              voucher_number: this.userSessionData.voucher,
              charge_item: this.userSessionData.chargeItem,
              // slotid: this.userSessionData.slotId,
              // provider: "Nyaho",
              // patientName: this.userSessionData.fullName,
              // patientId: this.userSessionData.patientId,
              // appointmentId: this.userSessionData.appointmentId,
            };

            // call payment api
            this.conversationalForm.addRobotChatResponse(
              "Verifying payment..."
            );
            try {
              // Make a request for a user with a given ID
              const response = await axios.post(
                this.apiUrl + "/finance/payment/mobile-money",
                payload
              );

              // handle success
              console.log("logging axios..(success)", response);
              this.finalSessionMessageTags[0][
                "cf-questions"
              ] = `Thanks for having me, ${this.userSessionData.fullName}. Your payment has been initiated, once your payment succeeds. You will receive an SMS and Email to confirm your appointment booking.`;
              this.conversationalForm.addTags(
                this.finalSessionMessageTags,
                true
              );
            } catch (e) {
              // handle success
              console.log("logging axios..(error)", e);
              this.conversationalForm.addTags(this.paymentFailedTags, true);
            }
          } catch (e) {
            // handle error
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "timeselectionfailed",
                "cf-questions":
                  "Sorry, something went wrong and I could not select this slot.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Book a new appointment",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
        }
        if (dto.text === "Book a new appointment") {
          const tags = [
            {
              tag: "select",
              name: "startoptions",
              "cf-questions":
                "When would you like to book the next appointment?",
              children: [
                {
                  tag: "option",
                  "cf-label": "Next available Date",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Choose my own Date",
                  value: "",
                },
              ],
            },
          ];

          this.conversationalForm.addTags(tags, true);
        }
      }
      // End of flow: Final
      if (dto.tag.domElement.name === "paymentfailed") {
        if (dto.text === "Try again") {
          // book slot:
          // book slot:
          this.conversationalForm.addRobotChatResponse(
            "Booking your appointment..."
          );
          // const slotInfo = this.userSessionData.resultData[
          //   this.userSessionData.selectedTime
          // ];
          // this.userSessionData.slotId = slotInfo.slotid;
          let payload = {
            slotid: this.userSessionData.slotId,
            patientName: this.userSessionData.fullName,
            mobile: this.userSessionData.mobile,
            email: this.userSessionData.mobile + "@Nhyahohealth.com",
            patientId: this.userSessionData.userId,
            sz_bookerid: this.userSessionData.userId,
            sz_bookername: this.userSessionData.fullName,
            sz_bookedforid: this.userSessionData.userId,
            sz_docempid: this.userSessionData.resultData.assignedDoctorId,
            sz_payerid:
              this.userSessionData.paymentMethod != "corporate"
                ? this.userSessionData.userId
                : this.userSessionData.companyId,
            sz_companyid: this.userSessionData.companyId,
            sz_docname: this.userSessionData.resultData.doctorName,
            sz_bookedtime: this.userSessionData.appointmentDateTime,
            sz_location: this.userSessionData.streetAddress,
            payername:
              this.userSessionData.paymentMethod != "corporate"
                ? "self"
                : this.userSessionData.companyName,
            sz_bookingnote: "",
            sz_bookingstatus: "",
            sz_bookingmode: "Online",
            sz_hospid: "",
            sz_unitid: "",
            service: this.userSessionData.serviceValue.health_service
              ? this.userSessionData.serviceValue.health_service
              : "",
            tier: this.userSessionData.serviceValue.id
              ? this.userSessionData.serviceValue.id
              : "",
            sz_paymentmethod: this.userSessionData.paymentMethod,
            sz_bookedforname: this.userSessionData.fullName,
            sz_complaint: "",
            assignedDoctorId: this.userSessionData.resultData.assignedDoctorId,
            sz_bookingtype: this.userSessionData.appointmentTypeKey,
            sz_bookedfortime: this.userSessionData.appointmentDateTime,
          };
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patients/bookappointment",
              payload
            );
            // handle success
            this.userSessionData.patientId = response.data.sz_bookedforid;
            this.userSessionData.appointmentId = response.data.id;
            console.log("logging axios..(success)", response);
          } catch (e) {
            // handle error
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "timeselectionfailed",
                "cf-questions":
                  "Sorry, something went wrong and I could not select this slot.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Book a new appointment",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }

          const response = await axios.post(
            this.apiUrl + "/patients/bookappointment",
            payload
          );
          // handle success
          this.userSessionData.patientId = response.data.sz_bookedforid;
          this.userSessionData.appointmentId = response.data.id;
          this.userSessionData.chargeItem = response.data.charge_item_id;

          this.userSessionData.voucher = dto.text;
          //POST: api
          payload = {
            vendor: this.userSessionData.provider,
            mobile:
              "233" +
              (this.userSessionData.momonumber[0] === "0"
                ? this.userSessionData.momonumber.slice(1)
                : this.userSessionData.momonumber),
            // sz_paymentnumber:
            //   "233" +
            //   (this.userSessionData.momonumber[0] === "0"
            //     ? this.userSessionData.momonumber.slice(1)
            //     : this.userSessionData.momonumber),
            service: this.userSessionData.appointmentTypeKey,
            // productid: "",
            // status: "pending",
            trans_type: "debit",
            voucher_number: this.userSessionData.voucher,
            charge_item: this.userSessionData.chargeItem,
            // slotid: this.userSessionData.slotId,
            // provider: "Nyaho",
            // patientName: this.userSessionData.fullName,
            // patientId: this.userSessionData.patientId,
            // appointmentId: this.userSessionData.appointmentId,
          };

          // call payment api
          this.conversationalForm.addRobotChatResponse("Retrying..");
          this.conversationalForm.addRobotChatResponse("Verifying payment...");
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/finance/payment/mobile-money",
              payload
            );

            // handle success
            console.log("logging axios..(success)", response);
            this.finalSessionMessageTags[0][
              "cf-questions"
            ] = `Thanks for having me, ${this.userSessionData.fullName}. Your payment has been initiated, once your payment succeeds. You will receive an SMS and Email to confirm your appointment booking.`;
            if (this.userSessionData.provider === "mtn")
              this.conversationalForm.addTags(
                this.finalSessionMTNMessageTags,
                true
              );
            if (this.userSessionData.provider != "mtn")
              this.conversationalForm.addTags(
                this.finalSessionMessageTags,
                true
              );
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            this.conversationalForm.addTags(this.paymentFailedTags, true);
          }
        }
        if (dto.text === "Return to start") openForm("form-appointment");
      }
      if (dto.tag.domElement.name === "finalsessionmessage") {
        if (dto.text === "Done") this.$router.push({ path: "/appointments" });
        if (dto.text === "Check my bill for this appointment")
          this.$router.push({ path: "/bills" });
        if (dto.text === "Pay with ExpressPay")
          this.$router.push({ path: "/express-pay" });
        if (dto.text === "Book another appointment") {
          const tags = [
            {
              tag: "select",
              name: "startoptions",
              "cf-questions":
                "When would you like to book the next appointment?",
              children: [
                {
                  tag: "option",
                  "cf-label": "Next available Date",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Choose my own Date",
                  value: "",
                },
              ],
            },
          ];

          this.conversationalForm.addTags(tags, true);
        }
      }
      success();
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
      loggedIn: (state) => state.authStore.loggedIn,
    }),
    greeting: () => {
      const date = new Date();
      return date.getHours() >= 17
        ? "Hello 😊, Good evening"
        : date.getHours() >= 12
        ? "Hello 😊, Good afternoon"
        : "Hello 😊, Good morning";
    },
  },
  mounted() {
    this.setupForm();
    var dispatcher = new cf.EventDispatcher();
    dispatcher.addEventListener(
      cf.ChatListEvents.CHATLIST_UPDATED,
      function (event) {
        // your code here
        const date = document.querySelector("input[placeholder='dd/mm/yyyy']");

        function checkValue(str, max) {
          if (str.charAt(0) !== "0" || str == "00") {
            var num = parseInt(str);
            if (isNaN(num) || num <= 0 || num > max) num = 1;
            str =
              num > parseInt(max.toString().charAt(0)) &&
              num.toString().length == 1
                ? "0" + num
                : num.toString();
          }
          return str;
        }

        date.addEventListener("input", function (e) {
          this.type = "text";
          var input = this.value;
          if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
          var values = input.split("/").map(function (v) {
            return v.replace(/\D/g, "");
          });
          if (values[0]) values[0] = checkValue(values[0], 31);
          if (values[1]) values[1] = checkValue(values[1], 12);
          var output = values.map(function (v, i) {
            return v.length == 2 && i < 2 ? v + "/" : v;
          });
          this.value = output.join("").substr(0, 14);
        });
      },
      false
    );
    this.conversationalForm = window.cf.ConversationalForm.startTheConversation(
      {
        formEl: document.getElementById("form-appointment"),
        context: document.getElementById("cf-context"),
        preventAutoFocus: true,
        userImage:
          "https://cdn.glitch.com/1d1d7c79-abe4-4c95-b135-3a111632f4d8%2FEllipse%2018.png?v=1585908713570",
        robotImage: "../assets/logo.png",
        flowStepCallback: this.flowCallback,
        submitCallback: function () {},
        eventDispatcher: dispatcher,
      }
    );
    if (this.forSpecialist) {
      this.greetingsSpecialistTags[0]["cf-questions"] = this.greeting;
      this.greetingsSpecialistTags[1][
        "cf-questions"
      ] = `I'm Serenity and I will help you book an appoint with ${
        this.listService.sz_fname + " " + this.listService.sz_lname
      } of <a class='text-info'>${this.listService.specialty}</a> in a minute.`;
      this.conversationalForm.addTags(this.greetingsSpecialistTags, true);
    } else {
      this.greetingsTags[0]["cf-questions"] = this.greeting;
      this.greetingsSpecialistTags[1][
        "cf-questions"
      ] = `I'm Serenity and I will help you book an appoint with ${
        this.listService.sz_fname + " " + this.listService.sz_lname
      } of <a class='text-info'>${this.listService.specialty}</a> in a minute.`;
      this.conversationalForm.addTags(this.greetingsTags, true);
    }
    this.conversationalForm.start();
  },
};
</script>

<style lang="scss" scoped>
.bot {
  height: 100vh;
}

.cf-input-button {
  background-color: green;
  font-size: 100px;
}

.inputWrapper {
  /* background-color: green; */
  background-color: #c4c4c431;
}

.dark-theme .conversational-form cf-input input:hover {
  color: black;
}

.dark-theme .conversational-form .cf-button:hover cf-radio {
  color: grey;
}

.dark-theme .conversational-form-inner input:hover {
  color: green;
  background-color: #c4c4c431;
}

.dark-theme .conversational-form cf-input textarea:hover {
  color: grey;
}

.dark-theme .conversational-form cf-chat-response thumb > p {
  background-color: white;
  background-image: url("../assets/logo.png") !important;
}

.dark-theme .conversational-form cf-chat-response.user thumb {
  background-color: white;
  background-image: url("https://gulpjs.com/img/gulp-white-text.svg") !important;
  background-image: url("https://cdn.glitch.com/1d1d7c79-abe4-4c95-b135-3a111632f4d8%2FEllipse%2018.png?v=1585908713570") !important;
}

dark-theme .conversational-form cf-chat-response.robot thumb {
  background-color: white;
}

#chatstart {
  margin: 0;
  padding: auto;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: lighter;
}

.hold {
  background: linear-gradient(
    140.72deg,
    #72deff 0%,
    rgba(35, 31, 219, 0.51) 100%
  );
  margin-top: -10px;
  padding-bottom: 20px;
  padding-top: 20px;
}

#chatone {
  font-family: "Lato", sans-serif;
  padding-left: 10px;
  font-weight: lighter;
  padding-top: 10px;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: large;
}

#conversational-form {
  background-color: white;
  color: white;
}

.conversational-form-inner {
  background-color: white;
}

.conversational-form-inner input {
  color: #00000050;
  background-color: #c4c4c431;
}

.conversational-form {
  /* color: hotpink; */
  background-color: white;
}

.cf-button-input {
  background-color: #c4c4c431;
}

.chat-popup {
  width: 50%;
}

#cf-context {
  width: 100% !important;
}

.user.can-edit.peak-thumb.show p {
  background-color: blue;
  color: white;
  font-weight: bold;
}

.conversational-form--enable-animation .cf-button.animate-in {
  background-color: blue;
  color: white;
  font-weight: bold;
  border: 1px solid white;
}

.cf-button:hover,
.cf-button:hover:not([checked="checked"]) {
  color: green;
}

.conversational-form--enable-animation .cf-button.animate-in:hover {
  background-color: white;
  color: blue;
  border: 1px solid blue;
}

thumb .conversational-form--enable-animation cf-chat-response.user.peak-thumb {
  background-color: tomato;
}

.cf-input-button.cf-input-button {
  background-color: white;
  background-repeat: no-repeat;
  color: white;
}

cf-input-button.cf-input-button .cf-input-icons {
  color: green;
}

cf-input-button.cf-input-button .cf-input-progress {
  background-color: green;
}

cf-input-button.cf-input-button .cf-input-attachment {
  background-color: green;
}

.conversational-form--enable-animation cf-chat-response text > p.show {
  background-color: white;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  /* line-height: normal; */
  font-family: "Lato", sans-serif;
  font-weight: light;
  width: auto;
}

// .conversational-form--enable-animation cf-chat-response.robot.peak-thumb thumb {
//   background-image: url(../images/Ellipse\ 18.png) !important;
//   background-color: white;
//   width: 30px;
//   margin-right: 0;
// }

.conversational-form--enable-animation cf-chat-response thumb {
  background-color: blue;
}

cf-chat-response.user thumb {
  background-color: blue;
}

cf-input-button.cf-input-button:hover {
  background-color: blue;
}

cf-chat-response.user thumb {
  background-color: teal;
}

.conversational-form--enable-animation cf-chat-response text > p.show {
  /* background-color: teal; */
}

.conversational-form--enable-animation cf-chat-response.user.peak-thumb thumb {
  background-color: white;
}

cf-chat-response.show {
  padding-left: 0;
}

#hide-button {
  display: none;
}

#cf-context {
  width: 350px;
  height: 500px;
}
#form {
  height: 0px;
  overflow: hidden;
}
#form-appointment {
  height: 0px;
  overflow: hidden;
}
/* The popup chat - hidden by default */
.chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #4caf50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}
.myForm {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
iframe {
  height: 100%;
  width: 100%;
  border-width: 0px;
  border-style: inset;
  border-color: initial;
  border-image: initial;
  border: 0;
  border: none;
  min-height: 0px;
  min-width: 0px;
  background: transparent;
}
* {
  box-sizing: border-box;
}
/* width */
::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  /* background-color:; */
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

@media screen and (max-width: 770px) {
  #cf-context {
    width: 100% !important;
  }

  .chat-popup {
    width: 100%;
  }

  #chatstart {
    font-size: 18px;
  }
}
</style>
<style>
.loading {
  width: 60px;
  min-height: 60px;
  margin: 0px auto;
  background-color: #544848;
  border-radius: 100%;
  -webkit-animation: sa-scaleout 1s infinite ease-in-out;
  animation: sa-scaleout 1s infinite ease-in-out;
}

@keyframes sa-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sa-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
cf-list {
  width: auto;
  height: auto;
  margin-top: 20px;
  max-height: 210px;
  overflow-y: auto;
}
</style>
