<template>
  <div class="mt-4 mb-5 py-3">
    <div>
      <div class="container mt-5">
        <!-- {{userInfo}} -->
        <div class="text-center my-3">
          <h3>Primary Insurance</h3>
          <!-- <small class="text-muted">Basic info, like your name and photo, that you use on Serenity.</small> -->
        </div>
        <div class="row m-0 justify-content-center text-center">
          <div
            class="p-3 col my-2 text-left"
            style="border: 1px solid aqua; border-radius: 12px; max-width: 400px"
          >
            <h2 class="text-info pl-3 mb-3">Self Pay</h2>

            <div
              class="row m-0 justify-content-between align-items-center mb-3"
            >
              <div class="col">
                <small class="text-muted">First Name</small>
                <h5>{{ userInfo.sz_firstname }}</h5>
              </div>
              <div class="col">
                <small class="text-muted">Last Name</small>
                <h5>{{ userInfo.sz_lastname }}</h5>
              </div>
            </div>
            <div
              class="row m-0 justify-content-between align-items-center mb-3"
            >
              <div class="col">
                <small class="text-muted">Date of Birth</small>
                <h5>{{ userInfo.sz_dob }}</h5>
              </div>
              <div class="col">
                <small class="text-muted">Gender</small>
                <h5>{{ userInfo.sz_gender }}</h5>
              </div>
            </div>
            <div
              class="row m-0 justify-content-between align-items-center mb-3"
            >
              <div class="col">
                <small class="text-muted">Plan Type</small>
                <h5>Other</h5>
              </div>
              <div class="col">
                <!-- <small class="text-muted">Last Name</small>
                <h5>Papa</h5>                 -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-btn fixed-bottom">
        <b-button class="w-100 btn btn-info py-3"
          >Update your insurance</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  methods: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
