<template>
  <div class="AppointmentTile mr-2 p-2">
    <div>
      <div class="row align-items-center text-left">
        <!-- when -->
        <div
          class="col-4 col-md-3 text-center"
          style="border-right: 2px solid black; text-transform: uppercase"
        >
          <div v-if="apptStatus === 'Active'">
            <button
              class="btn btn-sm btn-outline-success mb-2"
              style="border-radius: 18px"
              @click="startVideo"
            >
              Join Visit
            </button>
            <button
              class="btn btn-sm btn-outline-danger"
              style="border-radius: 18px"
              @click="endVisit"
            >
              <b-spinner
                variant="danger"
                style="width: 18px; height: 18px"
                label="Spinning"
                v-if="loading"
              ></b-spinner>
              <span v-else> End Visit</span>
            </button>
          </div>
          <div v-else>
            <button
              class="btn btn-sm btn-outline-info"
              style="border-radius: 24px; font-size: 12px !important"
              @click="bookAnother"
            >
              Book apointment
            </button>
          </div>
        </div>

        <!-- detail -->
        <div class="col ml-1">
          <div class="row text-muted" style="font-size: 0.7rem">
            <div class="col">
              {{ apptType }} is
              <span
                :class="[
                  apptStatus === 'Active' ? 'text-success' : 'text-danger',
                ]"
                >{{ apptStatus }}</span
              >
            </div>
          </div>
          <div style="font-weight: 700; font-size: 1.5rem">
            <div>{{ practitionerName }}</div>
          </div>
          <div class="row text-muted" style="font-size: 0.7rem">
            <div class="col">
              {{ apptTime | moment("Do MMM, YYYY @ HH:mm") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import http from "@/network/Base.js";
export default {
  name: "appointment-tile",
  props: {
    appt: {
      type: Object,
      // required: true
    },
    listvists: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
    }),
    apptDay() {
      return this.appt.szstarttime.slice(0, 10);
    },
    apptDate() {
      return this.appt.szstarttime.slice(0, 10);
    },
    apptMonth() {
      return this.appt.szstarttime.slice(0, 10);
    },
    apptTime() {
      return (
        this.appt.szstarttime.slice(0, 10) +
        " " +
        this.appt.szstarttime.slice(11, 19)
      );
    },
    apptType() {
      return this.appt.sz_service;
    },
    apptStatus() {
      return this.appt.sz_visitstatus;
    },
    practitionerName() {
      return this.appt.szpatientname
        ? this.appt.szpatientname
        : `${this.userInfo.szfirstname + " " + this.userInfo.szlastname}`;
    },
  },
  filters: {
    readableDateTime(val) {
      return moment(val).format("Do MMM, YYYY @ HH:mm");
    },
  },
  methods: {
    bookAnother() {
      this.$router.push("/appoint-bot");
    },
    startVideo() {
      this.$router.push(`/video?url=${this.appt.startUrl}`);
    },
    endVisit() {
      this.loading = true;
      http()
        .post("/visits/endvisit", {
          szpatientid: this.appt.szpatientid,
          szvisitid: this.appt.szvisitid,
        })
        .then(({ data }) => {
          this.loading = false;
          this.$snotify.success("Visit ended");
          this.$emit("listvists");
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$snotify.error("Sorry, couldn't end visit");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.AppointmentTile {
  border-radius: 16px;
  cursor: pointer;
  background-color: #fff;
}
.AppointmentTile:hover {
  background-color: #ccc;
}
</style>
