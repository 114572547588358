<template>
  <div>
    <b-card no-body class="mb-3" style="border-style: none">
      <b-card-header
        header-tag="header"
        class="shadow p-0 mh-100"
        style="border-radius: 12px"
        v-b-toggle="
          getId(
            `{type: ${bill.service}, productOrservice: ${bill.service_or_product_name}, productOrServiceName: ${bill.service_or_product_name}, patientName: ${bill.patientname}, patientMobile: ${bill.patient_mobile}}`
          )
        "
        role="tab"
      >
        <BillCard :bill="bill" />
      </b-card-header>
      <b-collapse
        :id="`accordion-${bill.id}`"
        accordion="my-accordion"
        role="tabpanel"
        style="border-radius: 7px; border-style: none"
      >
        <b-card-body class="pt-3 px-2">
          <div id="printMe" class="row m-0 align-items-center">
            <div style="align-self: flex-start" class="col text-center">
              <b-card-text class="text-left m-0">
                <code v-if="category === 'due'">Amount to be Paid:</code>
                <code v-else>Amount Paid:</code>
                GHS {{ bill.charge }}.00
              </b-card-text>
              <div class="col p-0">
                <b-card-text class="text-left m-0">
                  <code>By:</code>
                  {{ bill.payername }}
                </b-card-text>
                <b-card-text v-if="category === 'paid'" class="text-left m-0">
                  <code>Via:</code>
                  {{ bill.paymentMethod }}
                </b-card-text>
              </div>
            </div>
            <div class="col text-center">
              <div>
                <qrcode-vue :value="value" :size="size" level="H"></qrcode-vue>
              </div>
            </div>
          </div>
          <div class="text-right">
            <span>
              <button @click="print" class="btn btn-light text-info shadow">
                <b-spinner
                  :class="['fa', loading ? 'loading-width' : '']"
                  label="Spinning"
                  v-if="loading"
                ></b-spinner>
                <template v-else> <i class="fa fa-print"></i> Print </template>
              </button>
            </span>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import BillCard from "@/components/billCard";
import http from "@/network/Base.js";
import { mapState } from "vuex";
import QrcodeVue from "qrcode.vue";

export default {
  name: "prescriptions",
  components: {
    BillCard,
    QrcodeVue,
  },
  props: {
    bill: {
      type: Object,
      // required: true
    },
    category: {
      type: String,
    },
  },
  data() {
    return {
      visitDate: "Thu 9th June, 2020",
      loading: false,
      value: {},
      size: 300,
    };
  },
  methods: {
    setQRcodeData(data) {
      this.value = data;
    },
    print() {
      // Pass the element id here
      this.$htmlToPaper("printMe");
    },
    getId(data) {
      this.setQRcodeData(data);
      return `accordion-${this.bill.id}`;
    },
  },
  created() {},
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
    }),
  },
};
</script>