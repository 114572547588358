<template>
  <div class="container mt-4 mb-5 py-3">
    <div class="mt-5 py-1 fixed-top bg-white">
      <div class="text-center my-2">
        <h4 class="m-0">Your Labs</h4>
      </div>
      <div class="row m-0 justify-content-center align-items-center py-2">
        <div
          class="col p-0 py-1 text-center"
          @click="currentCategory = 'Request'"
          style="cursor: pointer"
          :style="`${
            currentCategory == 'Request'
              ? 'border-bottom: .2rem solid silver'
              : ''
          }`"
        >
          <span>REQUESTS</span>
        </div>
        <div
          class="col p-0 py-1"
          @click="currentCategory = 'Report'"
          style="cursor: pointer"
          :style="`${
            currentCategory == 'Report' ? 'border-bottom: .2rem solid teal' : ''
          }`"
        >
          <div class="reports">
            <span>REPORTS</span>
            <span class="notify"></span>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-top: 50px; margin-top: 50px">
      <div v-if="loading" class="text-center mt-5">
        <b-spinner
          variant="info"
          style="width: 3rem; height: 3rem"
          type="grow"
          label="Spinning"
        ></b-spinner>
      </div>
      <div
        v-if="currentCategory == 'Report' && reportCount === 0"
        class="text-center mt-5"
      >
        <div>Sorry no Lab reports available</div>
      </div>
      <div
        v-else-if="currentCategory == 'Request' && requestCount === 0"
        class="text-center mt-5"
      >
        <div>Sorry no Lab requests available</div>
      </div>
      <div v-else class="row py-3">
        <div
          v-for="(lab, i) in filterLab"
          :key="i"
          class="col-md-6 col-lg-6 col-sm-12"
        >
          <b-card no-body class="mb-3" style="border-style: none">
            <b-card-header
              header-tag="header"
              class="shadow-sm p-0 mh-100"
              style="border-radius: 12px"
              v-b-toggle="`accordion-${lab.id}`"
              role="tab"
            >
              <LabTile :lab="lab" />
            </b-card-header>
            <b-collapse
              :id="`accordion-${lab.id}`"
              accordion="my-accordion"
              role="tabpanel"
              style="border-radius: 7px; border-style: none"
            >
              <b-card-body class="pt-2 px-2">
                <div style="align-self: flex-start" class="text-center">
                  <div class="p-0">
                    <b-card-text class="text-left m-0">
                      <code>Specimen:</code>
                      {{ lab.specimen }}
                    </b-card-text>
                    <b-card-text class="text-left m-0">
                      <code>Result:</code>
                      <span
                        class="px-2"
                        v-if="lab.results != [] || lab.results != ''"
                        >{{ lab.results | checkResult }}</span
                      >
                    </b-card-text>
                    <!-- <b-card-text class="text-left m-0">
                      <code>Conclusion:</code>
                      {{lab.conclusion}}
                    </b-card-text>-->
                  </div>
                </div>
                <div class="text-right pt-3" v-if="currentCategory == 'Report'">
                  <span>
                    <button
                      class="btn btn-light text-info shadow"
                      @click="startDownload(lab.diagnostic_report_documents)"
                    >
                      <b-spinner
                        :class="['fa', loading ? 'loading-width' : '']"
                        label="Spinning"
                        v-if="loading"
                      ></b-spinner>
                      <template v-else>
                        <i class="fa fa-download"></i> Download
                      </template>
                    </button>
                  </span>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
    <!-- <vue-html2pdf
      :show-layout="controlValue.showLayout"
      :enable-download="controlValue.enableDownload"
      :preview-modal="controlValue.previewModal"
      :filename="controlValue.filename"
      :paginate-elements-by-height="controlValue.paginateElementsByHeight"
      :pdf-quality="controlValue.pdfQuality"
      :pdf-format="controlValue.pdfFormat"
      :pdf-orientation="controlValue.pdfOrientation"
      :pdf-content-width="controlValue.pdfContentWidth"
      :manual-pagination="controlValue.manualPagination"
      :html-to-pdf-options="htmlToPdfOptions"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration($event)"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <pdf-content @domRendered="domRendered()" slot="pdf-content" />
    </vue-html2pdf>-->
  </div>
</template>

<script>
import LabTile from "@/components/LabTile";
import http from "@/network/Base.js";
//import PdfContent from "@/components/PdfContent";
import PdfContent from "@/components/SarsCoV2Template";
// import VueHtml2pdf from '@/components/VueHtml2pdf'
import ControlsContainer from "@/components/ControlsContainer";
import VueHtml2pdf from "vue-html2pdf";
// import VueHtml2pdf from 'vue-html2pdf-test'
import { mapFields } from "vuex-map-fields";
import { mapState } from "vuex";

export default {
  name: "labs",
  components: {
    LabTile,
    VueHtml2pdf,
    PdfContent,
    ControlsContainer,
  },
  data() {
    return {
      Labs: [],
      reports: [],
      requests: [],
      currentCategory: "Request",
      loading: false,
      pdfProgressLoading: false,
      installBtn: false,
      installer: undefined,
      contentRendered: false,
      progress: 0,
      generatingPdf: false,
      pdfDownloaded: false,
      // controlValue: null,
      reportCount: 0,
      requestCount: 0,
    };
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
    // async downloadPdf() {
    //   if (!(await this.validateControlValue())) return;

    //   this.pdfProgressLoading = true;
    //   this.$refs.html2Pdf.generatePdf();
    // },
    // download(documents) {
    //   console.log(documents[0].file);
    // },
    download(file) {
      //creating an invisible element
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8, " + encodeURIComponent("Lab_Report")
      );
      element.setAttribute("download", file);

      // Above code is equivalent to
      // <a href="path of file" download="file name">

      document.body.appendChild(element);

      //onClick property
      element.click();

      document.body.removeChild(element);
      this.loading = false;
    },

    startDownload(documents) {
      // Generate download of hello.txt
      // file with some content
      this.loading = true;
      let doc = documents[0];
      if (doc) {
        var files = doc.file.split("/");
        let name = files[files.length - 1];
        const a = document.createElement("A");
        a.setAttribute("download", name);
        a.setAttribute("target", "_blank");
        a.setAttribute("href", doc.file);
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.loading = false;
      } else {
        this.loading = false;
      }
      // var filename =
      //   "https://serenity.s3.amazonaws.com/media/0004_auto_20200323_1845.py";
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        alert("pdf-quality value should only be 0 - 2");
        this.controlValue.pdfQuality = 2;

        return false;
      }

      if (!this.controlValue.paginateElementsByHeight) {
        alert("paginate-elements-by-height value cannot be empty");
        this.controlValue.paginateElementsByHeight = 1400;

        return false;
      }

      const paperSizes = [
        "a0",
        "a1",
        "a2",
        "a3",
        "a4",
        "letter",
        "legal",
        "a5",
        "a6",
        "a7",
        "a8",
        "a9",
        "a10",
      ];

      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        alert(`pdf-format value should only be ${paperSizes}`);
        this.controlValue.pdfFormat = "a4";

        return false;
      }

      if (!this.controlValue.pdfOrientation) {
        alert("pdf-orientation value cannot be empty");
        this.controlValue.pdfOrientation = "portrait";

        return false;
      }

      if (!this.controlValue.pdfContentWidth) {
        alert("pdf-content-width value cannot be empty");
        this.controlValue.pdfContentWidth = "800px";

        return false;
      }

      return true;
    },

    onProgress(progress) {
      this.progress = progress;
      console.log(`PDF generation progress: ${progress}%`);
    },

    hasStartedGeneration(e) {
      console.log(`PDF has started generation`);
    },

    hasGenerated(blobPdf) {
      this.pdfDownloaded = true;
      this.pdfProgressLoading = false;
      console.log(`PDF has downloaded yehey`);
      console.log(blobPdf);
    },

    domRendered() {
      console.log("Dom Has Rendered");
      this.contentRendered = true;
    },

    onBlobGenerate(blob) {
      console.log(blob);
    },
    // getLabs() {
    //   this.loading = true;
    //   http()
    //     .get(
    //       `/visits/servicerequest/filter?patientId=${this.userInfo.szpatientid}`
    //     )
    //     .then(({ data }) => {
    //       // this.LabsCount = data.count;
    //       let labs = data.results.reverse();
    //       this.reports = labs;
    //     })
    //     .catch((error) => {
    //       this.loading = false;
    //       console.log(error);
    //       // this.$snotify.error('Sorry, cannot get Service detail')
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
    getLabsReport() {
      this.loading = true;
      http()
        .get(`/visits/diagnosticreport?patientId=${this.userInfo.szpatientid}`)
        .then(({ data }) => {
          this.reportCount = data.count;
          let labs = data.results.reverse();
          this.reports = labs;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          // this.$snotify.error('Sorry, cannot get Service detail')
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLabsRequest() {
      this.loading = true;
      http()
        .get(`/visits/servicerequest?patientId=${this.userInfo.szpatientid}`)
        .then(({ data }) => {
          this.requestCount = data.count;
          let labs = data.results.reverse();
          this.requests = labs;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          // this.$snotify.error('Sorry, cannot get Service detail')
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getLabsRequest();
    this.getLabsReport();
  },
  filters: {
    checkResult(result) {
      if (result) {
        let value = JSON.parse(result);
        let res = value[0].text ? value[0].text : "No result";
        return res;
      } else {
        return "No result";
      }
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
    }),
    filterLab() {
      if (this.currentCategory == "Request") {
        return this.requests;
      } else {
        return this.reports;
      }
    },
    ...mapFields(["controlValue"]),

    htmlToPdfOptions() {
      return {
        margin: 0,

        filename: "lab-report.pdf",

        image: {
          type: "jpeg",
          quality: 0.98,
        },

        enableLinks: true,

        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },

        jsPDF: {
          unit: "in",
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      };
    },
  },
};
</script>

<style scoped>
.Labs {
  width: 100%;
  overflow: hidden;
}
.reports {
  position: relative;
  display: inline-block;
}
.reports .notify {
  position: absolute;
  top: -3px;
  right: -12px;
  padding: 3px;
  border-radius: 50%;
  background: green;
}
</style>
