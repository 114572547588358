<template>
  <div class="mt-4 mb-5 py-3">
    <div class="container mt-5">
      <!-- {{userInfo}} -->
      <div class="text-center my-3">
        <h3 class="m-0">Payments</h3>
        <small class="text-muted"
          >Your payment info, transactions, recurring payments, and
          appointments.</small
        >
      </div>

      <div class="scrolling-wrapper pb-2 pr-3">
        <div class="method--card">
          <div class="row m-0 bg-white align-items-center">
            <div class="card--icon col-4 p-0">
              <img
                src="https://lh3.googleusercontent.com/dhCF7DNax5n9y1Twn2fJi5w5e2hntmpy4savqt8IcjTv5TAQYHLobv_3N3wgKvmAon0"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="card--name col-8 px-1">
              <small class="text-wrap">Momo</small>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        class="p-3 my-2 text-left"
        style="border: 1px solid silver; border-radius: 7px"
      >
        <div
          class="row m-0 align-items-center"
          style="border-bottom: 1px solid silver;"
        >
          <div class="col p-0">
            <div class="row m-0 text-muted justify-content-between">
              <div class="col-8 pb-2 text-left p-0">
                <h5>Payments methods</h5>
                <small
                  >With Mobile Money, you can save payment info for more secure
                  payments online, for your appointments.</small
                >
              </div>
              <div class="col-4 p-0 pb-4 text-right">
                <img
                  src="https://lh3.googleusercontent.com/dhCF7DNax5n9y1Twn2fJi5w5e2hntmpy4savqt8IcjTv5TAQYHLobv_3N3wgKvmAon0"
                  alt=""
                  class="img-fluid"
                  width="150px"
                />
              </div>
            </div>
          </div>
        </div>
        <router-link to="#">
          <h6 class="pt-3">Manage payment methods</h6>
        </router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      user: this.userInfo,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("authStore/unSetUserCode");
      // this.$store.dispatch('unSetToken')
      this.$router.push("/login");
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
      loggedIn: (state) => state.authStore.loggedIn,
    }),
  },
};
</script>

<style lang="scss" scoped>
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  .method--card {
    display: inline-block;
  }
}

.card--icon {
  background: #8c8c8c4e;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  padding: 7px;
  img {
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }
}
.card--name {
  cursor: pointer;
  text-align: center;
  align-content: center;
  small {
    font-weight: 700;
  }
}
</style>
