<template>
  <div class="container mt-4 mb-5 py-3">
    <div class="mt-5 py-1 fixed-top bg-white">
      <div class="text-center my-2">
        <small class="m-0 font-weight-bold">Your Dependants</small>
      </div>
      <div class="scrolling-wrapper pb-2 pr-3" id="service-list">
        <!-- <span class="ml-3" v-for="(item, index) in dependantCards" :key="index">
          <div class="dependant--card">
            <img
              :src="item.image"
              width="40px"
              height="40px"
              style="border-radius: 50%"
              alt=""
            />
            <div class="text-center">
              <small style="font-size: 9px">{{ item.name }}</small>
            </div>
          </div>
        </span> -->
        <span class="ml-3">
          <div class="dependant--card">
            <div
              class="text-center px-2"
              style="
                font-size: 25px;
                border-radius: 50%;
                border: 1px solid gray;
              "
            >
              <i class="fa fa-plus circle"></i>
            </div>
            <div class="text-center">
              <small style="font-size: 9px">ADD</small>
            </div>
          </div>
        </span>
      </div>
      <div class="details container" style="max-width: 500px">
        <div>
          <h4>No dependent details</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: {},
      dependantCards: [
        {
          image: "http://graph.facebook.com/v2.5/6/picture?height=40&width=40",
          name: "ELI",
        },
        {
          image: "http://graph.facebook.com/v2.5/10/picture?height=40&width=40",
          name: "DADSON",
        },
        {
          image: "http://graph.facebook.com/v2.5/4/picture?height=40&width=40",
          name: "THOMAS",
        },
        {
          image: "http://graph.facebook.com/v2.5/5/picture?height=40&width=40",
          name: "EHI",
        },
        {
          image: "http://graph.facebook.com/v2.5/7/picture?height=40&width=40",
          name: "SMART",
        },
        {
          image: "http://graph.facebook.com/v2.5/4/picture?height=40&width=40",
          name: "PK",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  .dependant--card {
    display: inline-block;
  }
}
</style>


