<template>
  <div class="Prescriptions">
    <div class="container mt-5 mb-5">
      <!-- The modal -->
      <b-modal id="prescription" centered :title="'Selected prescriptions'">
        <div class="w-100">
          <div>Send prescriptions to available pharmacy with just a click.</div>
          <div id="printMe">
            <div v-for="(select, i) in checkedNames" :key="i">
              <div class="m-0 mt-4">
                <div class="px-3">
                  <h6
                    class="font-weight-bold m-0"
                    style="text-transform:capitalize; "
                  >{{ select.medication}}</h6>
                  <div class="text-muted">
                    <div
                      class="row m-0"
                      v-for="(dos, i) in select.medication_dosage_instruction"
                      :key="i"
                    >
                      <div class="m-0 col">
                        <div>
                          <div
                            class="font-weight-bold text-muted m-0"
                            style="text-transform:capitalize; font-size: 14px"
                          >{{ dos.frequency}}{{ dos.frequency_unit}} for {{ dos.period}}{{ dos.period_unit}}</div>
                          <div class="text-muted" style=" font-size: 9px">Dosage</div>
                        </div>
                      </div>
                      <div class="m-0 col">
                        <div>
                          <div
                            class="font-weight-bold text-muted m-0"
                            style="text-transform:capitalize; font-size: 14px"
                          >{{ dos.strength}}</div>
                          <div class="text-muted" style=" font-size: 9px">Strength</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-slot:modal-footer="{ cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button
            size="sm"
            style="border-radius: 20px; font-size: 15px"
            variant="outline-danger"
            @click="cancel()"
          >Cancel</b-button>
          <!-- Button with custom close trigger value -->
          <b-button
            size="sm"
            @click="share(checkedNames)"
            variant="outline-info"
            style="border-radius: 20px; font-size: 15px"
          >Share</b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="print"
            style="border-radius: 20px; font-size: 15px"
          >Print</b-button>
        </template>
      </b-modal>
      <!-- header -->
      <div class="row pt-5 text-left">
        <div class="col-md-7">
          <h3>
            <b>Your Prescriptions</b>
          </h3>
        </div>
      </div>
      <div class="row m-0 pb-5 align-items-center justify-content-between">
        <div style="font-size: 0.7rem;">from your recent visits</div>
        <b-button
          variant="outline-info"
          v-b-modal="'prescription'"
          style="border-radius: 20px; font-size: 15px"
        >Selected {{checkedNames.length}}</b-button>
      </div>

      <div>
        <div v-if="loading" class="w-100 text-center mt-5">
          <b-spinner variant="info" style="width: 3rem; height: 3rem;" type="grow" label="Spinning"></b-spinner>
        </div>
        <div class="row m-0" v-else>
          <div v-for="(pres, i) in prescriptions" :key="i" class="col-md-6 mb-3">
            <div class="row m-0 align-items-center">
              <input
                type="checkbox"
                :name="pres.medication"
                :id="pres.id"
                :value="pres"
                v-model="checkedNames"
              />
              <label :for="pres.id">
                <PrescriptionTile class="shadow-sm" :drug="pres" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrescriptionTile from "@/components/PrescriptionTile";
import http from "@/network/Base.js";
import { mapState } from "vuex";

export default {
  name: "prescriptions",
  components: {
    PrescriptionTile,
  },
  data() {
    return {
      checkedNames: [],
      visitDate: "Thu 9th June, 2020",
      prescriptions: [],
      presSelect: {},
      prescriptionsCount: 0,
      loading: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
    selectPres(pres) {
      this.presSelect = pres;
      this.$bvModal.show("prescription");
    },
    print() {
      // Pass the element id here
      this.$htmlToPaper("printMe");
    },
    getPrescriptions() {
      this.loading = true;
      http()
        .get(
          `/encounter/medicationrequest?patientId=${this.userInfo.szpatientid}`
        )
        .then(({ data }) => {
          this.prescriptionsCount = data.length;
          this.prescriptions = data.reverse();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          // this.$snotify.error('Sorry, cannot get Service detail')
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async share(data) {
      if (!data) {
        this.$snotify.error("Sorry, nothing to share");
      } else {
        let load = [];
        data.forEach((element) => {
          load.push(
            "(" +
              element.medication +
              " (" +
              element.medication_dosage_instruction[0].strength +
              ")" +
              ", Prescribed by: " +
              element.medication_requester_name +
              " to be taken " +
              element.medication_dosage_instruction[0].frequency +
              " " +
              element.medication_dosage_instruction[0].frequency_unit +
              " for " +
              element.medication_dosage_instruction[0].period +
              " " +
              element.medication_dosage_instruction[0].period_unit +
              ")"
          );
        });
        console.log(JSON.stringify(load));
        const shareData = {
          title: "Prescriptions",
          text: JSON.stringify(load),
          url: "https://patient.serenity.health",
        };
        try {
          await navigator.share(shareData);
          // console.log("Data was shared successfully");
        } catch (err) {
          // console.error("Share failed:", err.message);
        }
      }
    },
  },
  created() {
    this.getPrescriptions();
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
    }),
  },
};
</script>

<style scoped>
.Prescriptions {
  width: 100%;
  overflow: hidden;
}

/*Checkboxes styles*/
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

input[type="checkbox"] + label:last-child {
  margin-bottom: 0;
}

input[type="checkbox"] + label:before {
  content: "";
  display: block;
  opacity: 0.6;
  -webkit-transition: all 0.12s, border-color 0.08s;
  transition: all 0.12s, border-color 0.08s;
}

input[type="checkbox"]:checked + label {
  /* width: 12px; */
  border-radius: 12px;
  color: #6cc0e5;
  opacity: 1;
  border: 1px solid #6cc0e5;
  /* border-top-color: transparent;
  border-left-color: transparent; */
}
</style>
