<template>
  <div class="mt-4 mb-5 py-3">
    <div class="container mt-5">
      <div class="text-center my-3">
        <h3 class="m-0">Bills & Payments</h3>
        <small class="text-muted">
          Your bill info, transactions, recurring payments, and
          appointments.
        </small>
      </div>
      <div class="row m-0 justify-content-center align-items-center py-2">
        <div
          class="col p-0 py-2 text-center"
          @click="currentCategory = 'due'"
          :style="`${currentCategory == 'due' ? 'border-bottom: .2rem solid silver' : ''}`"
        >
          <h6>DUE</h6>
        </div>
        <div
          class="col p-0 py-2 text-center"
          @click="currentCategory = 'paid'"
          :style="
            `${currentCategory == 'paid' ? 'border-bottom: .2rem solid #bfffbfd9' : ''}`
          "
        >
          <h6>PAID</h6>
        </div>
        <div
          class="col p-0 py-2"
          @click="currentCategory = 'sent'"
          :style="
            `${currentCategory == 'sent' ? 'border-bottom: .2rem solid #ffb7b7e3' : ''}`
          "
        >
          <h6>SENT / WAITING</h6>
        </div>
      </div>
    </div>
    <div v-if="loading" class="text-center mt-5">
      <b-spinner variant="info" style="width: 3rem; height: 3rem;" type="grow" label="Spinning"></b-spinner>
    </div>
    <div v-else class="container">
      <component
        v-for="(bill, i) in filteredBills"
        :key="i"
        :is="typeOfBill"
        :bill="bill"
        :category="currentCategory"
      ></component>
    </div>
  </div>
</template>

<script>
import Due from "./Bills/billsDue";
import http from "@/network/Base.js";
import { mapState } from "vuex";

export default {
  components: {
    Due,
  },
  data() {
    return {
      typeOfBill: "Due",
      allBills: [],
      currentCategory: "due",
      loading: false,
    };
  },
  methods: {
    getVisits() {
      this.loading = true;
      http()
        .get(
          `/finance/chargeitem?patientid=${this.userInfo.sz_userid}&service=&clinic=`
        )
        .then(({ data }) => {
          this.allBills = data.reverse();
          console.log(data);
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error)
          // this.$snotify.error('Sorry, cannot get Service detail')
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getVisits();
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
    }),
    filteredBills() {
      if (this.currentCategory === "due")
        return this.allBills.filter((bill) => bill.status === "billable");
      else if (this.currentCategory === "paid")
        return this.allBills.filter((bill) => bill.status === "billed");
      else if (this.currentCategory === "sent")
        return this.allBills.filter((bill) => bill.payername === "corporate");
    },
  },
};
</script>
<style>
.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15) !important;
}
</style>
