<template>
  <div class="mt-4 mb-5 py-3">
    <div class="container mt-5">
      <!-- {{userInfo}} -->
      <router-link to="/chat-tool">
        <div
          class="row m-0 py-3 align-items-center text-dark"
          style="cursor: pointer; border-top: 1px solid silver; border-bottom: 1px solid silver;"
        >
          <div class="px-1 pr-2">
            <!-- <vue-initials-img
                :name="userInfo.sz_firstname + ' '+ userInfo.sz_lastname"
                style="width: 30px; height: 30px; border-radius: 50%"
              />  -->
            <img src="@/assets/logo.svg" width="40px" alt="" />
          </div>
          <div class="pl-3 text-left">
            <h5 class=" m-0">Serenity Bot</h5>
            <small class="text-muted "
              >Hello, {{ userInfo.sz_firstname }}</small
            >
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  methods: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
