<template>
  <div class="ImagingTile">
    <div class="backdrop bg-success" title="Ready"></div>
    <div class="container-fluid py-1">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <img
            src="https://img.icons8.com/cute-clipart/64/000000/file.png"
            style="width: 100%; max-width: 64px;"
          />
        </div>
      </div>
      <div class="row mt-2 justify-content-center">
        <div
          class="col text-center"
          style="font-size: 0.7rem"
        >{{ imaging.dateTime |readableDateTime s}}</div>
      </div>
      <div class="row justify-content-center">
        <div class="col text-center" style="font-size: 0.7rem">
          <b>Ordered By: {{ imaging.orderedBy }}</b>
        </div>
      </div>
      <div class="row justify-content-center mt-2">
        <div class="col text-center">
          <button
            class="btn btn-sm btn-block btn-outline-primary py-0"
            style="height: 25px;"
          >Download</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "imaging-tile",
  props: {
    imaging: {
      type: Object,
      required: true,
      default: () => ({
        name: "COVID-19 Test",
        status: "Ready",
        orderedBy: "Dr. Bruce",
        dateTime: "2020-04-09 13:30"
      })
    }
  },
  data() {
    return {};
  },
  computed: {},
  filters: {
    readableDateTime(val) {
      return moment(val).format("Do MMM, YYYY @ HH:mm");
    }
  }
};
</script>

<style scoped>
.ImagingTile {
  border-radius: 16px;
  cursor: pointer;
  background-color: #fff;
  position: relative;
}
.backdrop {
  border-radius: 50%;
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
}
</style>
