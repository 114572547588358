<template>
  <div class="mt-4 mb-5 py-3">
    <b-modal id="switch" size="md" centered :hide-footer="true" :hide-header="true">
      <!-- <Rating/> -->
      <div class="w-100">
        <div class="text center">
          <h5 class="text-center py-2">Add Dependant</h5>
          <hr />
          <div class="form-row">
            <div class="form-group col">
              <input
                type="text"
                placeholder="First name"
                class="form-control"
                v-model="dependant.fname"
              />
            </div>
            <div class="form-group col">
              <input
                type="text"
                placeholder="Last name"
                class="form-control"
                v-model="dependant.lname"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <input
                type="text"
                placeholder="Relation"
                class="form-control"
                v-model="dependant.relation"
              />
            </div>
            <div class="form-group col">
              <input
                type="date"
                placeholder="Date of Birth"
                class="form-control"
                v-model="dependant.dob"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <input
                type="tel"
                placeholder="Mobile"
                class="form-control"
                v-model="dependant.mobile"
              />
            </div>
            <div class="form-group col">
              <input
                type="email"
                placeholder="email"
                class="form-control"
                v-model="dependant.email"
              />
            </div>
          </div>
          <div class="mt-3 row justify-content-between" style="border-top: 1px black">
            <h5
              class="col text-center text-secondary"
              @click="close"
              style="cursor: pointer;"
            >Cancel</h5>
            <h5 class="col text-center text-info" @click="addDepend" style="cursor: pointer;">Submit</h5>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="container mt-5">
      <!-- {{userInfo}} -->
      <div class="text-center my-3">
        <h2 class="m-0">Personal info</h2>
        <small class="text-muted">
          Basic info, like your name and photo, that you use on
          Serenity.
        </small>
      </div>
      <!-- {{userInfo}} -->
      <div class="p-3 my-3 text-left" style="border: 1px solid silver; border-radius: 7px">
        <h4 class="m-0">Profile</h4>
        <small class="text-muted">Some info may be visible to doctors and others using Serenity.</small>
        <div class="row m-0 my-4 align-items-center">
          <div class="col-9 p-0">
            <div class="row m-0 text-muted justify-content-between">
              <div class="col-md text-left p-0">PHOTO</div>
              <div class="col-md text-left p-0">A photo helps personalize your account</div>
            </div>
          </div>
          <div class="col-3 p-1 pr-2 text-right">
            <div class="avatar-upload">
              <div class="avatar-edit">
                <input
                  type="file"
                  ref="fileInput"
                  @input="onSelectFile"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                />
                <label for="imageUpload"></label>
              </div>
              <div class="avatar-preview">
                <div id="imagePreview" :style="{ 'background-image': `url(${image})` }">
                  <vue-initials-img
                    v-if="!image"
                    :name="userInfo.szfirstname + ' ' + userInfo.szlastname"
                    style="width: 100%; height: 100%; border-radius: 50%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-3" style="border-top: 1px solid silver; border-bottom: 1px solid silver;">
          <small class="px-2 text-muted font-weight-bold">NAME</small>
          <div class="row m-0 align-items-center justify-content-between">
            <div class="col px-2">
              <input
                type="text"
                v-model="userInfo.szfirstname"
                class="form-control"
                placeholder="First name"
              />
            </div>
            <div class="col px-2">
              <input
                type="text"
                v-model="userInfo.sz_othername"
                class="form-control"
                placeholder="Other name"
              />
            </div>
          </div>
          <div class="p-2">
            <input
              type="text"
              v-model="userInfo.szlastname"
              class="form-control"
              placeholder="Last name"
            />
          </div>
        </div>
        <div class="mt-3">
          <small class="mt-2 px-2 text-muted font-weight-bold">BIRTHDAY</small>
          <div
            class="row m-0 pb-3 align-items-center justify-content-between"
            style=" border-bottom: 1px solid silver;"
          >
            <div class="col px-2">
              <input
                type="date"
                v-model="userInfo.szdob"
                class="form-control"
                placeholder="First name"
              />
            </div>
          </div>
        </div>
        <div class="mt-3">
          <small class="mt-2 px-2 text-muted font-weight-bold">GENDER</small>
          <div class="row m-0 pv-3 align-items-center justify-content-between">
            <div class="col px-2">
              <select name="gender" id="gender" class="form-control" v-model="userInfo.szgender">
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="p-3 my-2 text-left" style="border: 1px solid silver; border-radius: 7px">
        <h4 class="m-0">Contact info</h4>
        <div class="mt-3">
          <small class="mt-2 px-2 text-muted font-weight-bold">EMAIL</small>
          <div
            class="row m-0 pb-3 align-items-center justify-content-between"
            style="border-bottom: 1px solid silver;"
          >
            <div class="col px-2">
              <input
                type="email"
                v-model="userInfo.sz_email"
                class="form-control"
                placeholder="Email"
              />
            </div>
          </div>
        </div>
        <div class="mt-3">
          <small class="mt-2 px-2 text-muted font-weight-bold">PHONE</small>
          <div
            class="row m-0 pb-3 align-items-center justify-content-between"
            style="border-bottom: 1px solid silver;"
          >
            <div class="col px-2">
              <input
                type="tel"
                v-model="userInfo.sz_phoneno"
                class="form-control"
                placeholder="Mobile"
              />
            </div>
          </div>
        </div>
        <div class="mt-3">
          <small class="mt-2 px-2 text-muted font-weight-bold">LOCATION</small>
          <div class="row m-0 pb-3 align-items-center justify-content-between">
            <div class="col px-2">
              <input
                type="text"
                v-model="userInfo.sz_location"
                class="form-control"
                placeholder="Location"
              />
            </div>
          </div>
        </div>
      </div> 

      <div class="p-3 my-2 text-left" style="border: 1px solid silver; border-radius: 7px">
        <h4 class="m-0 py-2">Dependants</h4>
        <!-- <ul v-for="(depend, i) in Dependants" :key="i">
          <li>{{depend.fname+ ' ' + depend.lname}}</li>
        </ul>-->
        <div
          class="row m-0 py-3 justify-content-center align-items-center text-dark"
          style="cursor: pointer;border-top: 1px solid silver; "
        >
          <div class="px-1 pr-2">
            <i class="fas text-info fa-plus"></i>
          </div>
          <!-- v-b-modal="'switch'" -->
          <div class="pl-1">
            <h5 class="text-info m-0">Add a dependant</h5>
          </div>
        </div>
        <!-- <div
        class="row m-0 py-3 align-items-center justify-content-between"
        style="border-bottom: 1px solid silver;"
      >
        <div class="text-muted">EMAIL</div>
        <div class="col px-2">
          <input type="email" v-model="userInfo.sz_email" class="form-control" placeholder="Email" />
        </div>
      </div>
      <div
        class="row m-0 py-3 align-items-center justify-content-between"
      >
        <div class="text-muted">PHONE</div>
        <div class="col px-2">
          <input type="tel" v-model="userInfo.sz_phoneno" class="form-control" placeholder="Mobile" />
        </div>
        </div>-->
      </div>

      <b-button class="btn btn-info my-3" @click="update" :disabled="loading">
        <i
          v-if="loading"
          :class="[
            'fa',
            'fa-spin',
            'fa-spinner',
            loading ? 'loading-width' : '',
          ]"
        ></i>
        <template v-else>Update info</template>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import http from "@/network/Base.js";

export default {
  data() {
    return {
      loading: false,
      imageData: null,
      dependant: {
        fname: "",
        lname: "",
        mobile: "",
        email: "",
        dob: "",
        relation: "",
      },
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("authStore/unSetUserCode");
      // this.$store.dispatch('unSetToken')
      this.$router.push("/login");
    },
    close() {
      this.$bvModal.hide("switch");
    },
    addDepend() {
      this.$store.commit("userStore/setDependants", this.dependant);
      this.$bvModal.hide("switch");
      this.dependant = {
        fname: "",
        lname: "",
        mobile: "",
        email: "",
        dob: "",
        relation: "",
      };
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
          this.$store.commit("authStore/setUserImage", this.imageData);
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      }
    },
    update() {
      this.loading = true;
      // login.userSignIn(this, http(), this.form);
      http()
        .patch(`/patient/update/${this.userInfo.id}`, {
          sz_title: this.userInfo.sz_title,
          sz_firstname: this.userInfo.szfirstname,
          sz_lastname: this.userInfo.szlastname,
          sz_othername: this.userInfo.szothername,
          sz_gender: this.userInfo.szgender,
          sz_dob: this.userInfo.szdob,
          sz_phoneno: this.userInfo.sz_phoneno,
          sz_email: this.userInfo.sz_email,
          sz_location: this.userInfo.sz_location,
          sz_ice_phoneno: this.userInfo.sz_phoneno,
        })
        .then(({ data }) => {
          // this.$Progress.finish()
          console.log(data);
          this.$store.commit("authStore/setUserInfo", this.userInfo);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;

          // this.$Progress.finish()
          if (error.response) {
            // this.$Progress.finish()
            if (error.response.status === 500) {
              //    return this.$swal('System Error', 'Error occurred during authentication processing!', 'error')
              return this.$snotify.error(
                "An error occured whiles authenticating"
              );
            } else if (error.response.status === 200) {
              // this.$swal('Login Error', "Incorrect email & password!", 'error')
              return this.$snotify.error("Error updating info");
            }
            // return this.$swal('Error', 'Incorrect email & password!!', 'error')
            return this.$snotify.error("Error updating info");
          }
          // return this.$swal('Error', 'Please check you internet connection', 'error')
          return this.$snotify.error(
            "Could not sign you in. Check your internet connection!"
          );
        })
        .finally(() => {
          this.isSignedIn = false;
          this.loading = false;
        });
    },
  },
  mounted() {},
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
      loggedIn: (state) => state.authStore.loggedIn,
      image: (state) => state.authStore.image,
      Dependants: (state) => state.userStore.dependants,
    }),
  },
};
</script>

<style lang="scss" scoped>
.avatar-upload {
  position: relative;
  max-width: 70px;
  // margin: 50px auto;
  .avatar-edit {
    position: absolute;
    right: -12px;
    z-index: 1;
    top: -10px;
    input {
      display: none;
      + label {
        display: inline-block;
        width: 35px;
        height: 35px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #ffffff;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
        &:hover {
          background: #f1f1f1;
          border-color: #d6d6d6;
        }
        &:after {
          content: "\f040";
          font-family: "FontAwesome";
          color: #757575;
          position: absolute;
          top: 6px;
          left: 0;
          right: 0;
          text-align: center;
          margin: auto;
        }
      }
    }
  }
  .avatar-preview {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
    border-radius: 100%;
    border: 5px solid #f8f8f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    > div {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
</style>
