<template>
  <div style="height: 100%">
    <div
      class="bot container"
      style="display: flex; flex-direction: column; padding-bottom: 32px"
    >
      <iframe
        id="videoiframe"
        width="100%"
        style="
          border: 1px solid white;
          margin-top: 100px;
          max-height: 500px;
          height: 100%;
        "
        allow="camera;microphone"
        @load="iframeLoaded"
        @error="iframeError"
      ></iframe>
      <div v-if="loading" class="text-center mt-5">
        <b-spinner
          variant="info"
          style="width: 3rem; height: 3rem"
          type="grow"
          label="Spinning"
        ></b-spinner>
      </div>
      <template v-else>
        <div style="margin: 8px">
          <strong>{{ time }}</strong>
        </div>
        <button
          @click="endCall"
          type="button"
          style="align-self: center"
          class="btn btn-primary rounded-pill"
        >
          {{ callStarted ? "End call" : "Re-join Call" }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import DailyIFrame from "@daily-co/daily-js";

export default {
  name: "Video",
  data() {
    return {
      time: "00:00",
      timeBegan: null,
      timeStopped: null,
      stoppedDuration: 0,
      started: null,
      running: false,
      callFrame: null,
      callStarted: true,
      frameLoading: true,
      userSessionData: {
        appointmentCost: "GHc 99",
        address: "Some location in the world",
        result: [],
        resultData: {},
        doctors: {},
        streetAddress: "",
        appointmentTypeKey: "virtual-care-gp",
        appointmentType: "Virtual Care",
        appointmentCost: "GHS 99",
      },
    };
  },
  methods: {
    start() {
      if (this.running) return;

      if (this.timeBegan === null) {
        this.reset();
        this.timeBegan = new Date();
      }

      if (this.timeStopped !== null) {
        this.stoppedDuration += new Date() - this.timeStopped;
      }

      this.started = setInterval(this.clockRunning, 10);
      this.running = true;
    },
    stop() {
      this.running = false;
      this.timeStopped = new Date();
      clearInterval(this.started);
    },
    reset() {
      this.running = false;
      clearInterval(this.started);
      this.stoppedDuration = 0;
      this.timeBegan = null;
      this.timeStopped = null;
      this.time = "00:00:00.000";
    },
    clockRunning() {
      var currentTime = new Date();
      const timeElapsed = new Date(
        currentTime - this.timeBegan - this.stoppedDuration
      );
      const hour = timeElapsed.getUTCHours();
      const min = timeElapsed.getUTCMinutes();
      const sec = timeElapsed.getUTCSeconds();
      const ms = timeElapsed.getUTCMilliseconds();

      this.time =
        (hour >= 1 ? this.zeroPrefix(hour, 2) + ":" : "") +
        this.zeroPrefix(min, 2) +
        ":" +
        this.zeroPrefix(sec, 2); // +
      //"." +
      //this.zeroPrefix(ms, 3);
    },
    zeroPrefix(num, digit) {
      let zero = "";
      for (let i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    endCall() {
      if (this.callStarted) {
        this.stop();
        this.callFrame.leave();
        this.callStarted = false;
      } else if (!this.callStarted) {
        this.frameLoading = true;
        this.callFrame.join({
          url: this.joinUrl,
          userName: this.userInfo.szfirstname + " " + this.userInfo.szlastname,
          showLeaveButton: false,
        });
      }
    },
    setupForm() {
      this.callFrame = DailyIFrame.wrap(document.querySelector("#videoiframe"));
      this.callFrame.on("joined-meeting", (event) => {
        this.callStarted = true;
        this.start();
      });
      this.callFrame.on("error", (event) => {
        console.log("Error from daily: ", event);
        this.callStarted = false;
        this.stop();
        $.sweetModal(
          "Sorry, It looks like our servers are unavailable at the moment. Please close this popup and select re-join call to try again."
        );
      });
      this.callFrame.join({
        url: this.joinUrl,
        userName: this.userInfo.szfirstname + " " + this.userInfo.szlastname,
        showLeaveButton: false,
      });
    },
    iframeLoaded() {
      this.frameLoading = false;
    },
    iframeError() {
      this.frameLoading = false;
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
      loggedIn: (state) => state.authStore.loggedIn,
    }),
    joinUrl() {
      return `${this.$route.query["url"]}`;
    },
    loading() {
      return this.frameLoading;
    },
    greeting: () => {
      const date = new Date();
      return date.getHours() >= 17
        ? "Hello 😊, Good evening"
        : date.getHours() >= 12
        ? "Hello 😊, Good afternoon"
        : "Hello 😊, Good morning";
    },
  },
  mounted() {
    // https://serenity-team.daily.co/aLqpg3D_TsKnRm4xmU7-tQ?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoiYUxxcGczRF9Uc0tuUm00eG1VNy10USIsIm8iOmZhbHNlLCJ1IjoiIiwidWQiOiI5NGE2ZDE2ZC1jMjgxLTQzYWItYTRjYi0zYzY2NWE0OTJjZDIiLCJzcyI6dHJ1ZSwidm8iOmZhbHNlLCJhbyI6ZmFsc2UsImN0b2UiOnRydWUsInVpbCI6ImVuZ2xpc2giLCJkIjoiNmFlMmZmNjUtMTA0OC00M2JlLTk1ODQtYmU2YWZmZmUyZmFkIiwiaWF0IjoxNTg5OTU2OTg2fQ.BiACEdzBWW9tAhQhf6ZVCWeJb5kmQvmLNRpGanpIeeM
    // https://serenity-team.daily.co/M3ZAtAM_RG-lE2Op0i_B8g?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoiTTNaQXRBTV9SRy1sRTJPcDBpX0I4ZyIsIm8iOnRydWUsInUiOiJSdXRoIE9zZWkgT2R1bSIsInVkIjoiMmY4ZTE3NjUtYWY1OS00NmY1LWE0MmYtOTRjYzcxNTBmZWQzIiwic3MiOnRydWUsInZvIjpmYWxzZSwiYW8iOmZhbHNlLCJjdG9lIjp0cnVlLCJ1aWwiOiJlbmdsaXNoIiwiZCI6IjZhZTJmZjY1LTEwNDgtNDNiZS05NTg0LWJlNmFmZmZlMmZhZCIsImlhdCI6MTU4OTk2MTY1OH0.pKP93AKyC7xph_vjhXgwr8iQB9Sr1C_Zidu_8juNG_8
    this.setupForm();
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
}
</style>
<style></style>
