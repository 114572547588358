<template>
  <div
    class="covid-ready pt-5"
    :class="[result.result == 'positive' ? 'fail-test' : 'not-tested', result.result == 'negative' ? 'success-test' : 'not-tested']"
  >
    <div class="container mt-4 py-5">
      <!-- <div class="text-left mt-5">
        <span
          class="bg-white arrow-container"
          style="border-radius: 50%; cursor: pointer"
          @click="back"
        >
          <i class="fa fa-angle-left"></i>
        </span>
      </div>-->
      <div id="printMe" class="container">
        <div class="card status-card mt-4 py-5 shadow-sm">
          <span class="my-3">
            <qrcode-vue
              class="m-0"
              :value="file"
              size="250"
              level="H"
              foreground="#535353"
              renderAs="svg"
            ></qrcode-vue>
          </span>
          <div class="mt-2">
            <h3>
              <b>{{userInfo.szfirstname + ' ' + userInfo.szlastname}}</b>
            </h3>
            <div>
              <b-badge
                pill
                class="shadow-sm"
                :class="{ 
                  'text-light bg-danger' : (result.result == 'positive'), 
                  'text-light bg-success' : (result.result == 'negative'), 
               'text-secondary bg-light' : (!result)}"
              >
                <h6 class="m-0">
                  <b>{{result.result || 'Not Tested'}}</b>
                </h6>
              </b-badge>
            </div>
            <div class="mt-3 text-left ml-1 container">
              <div class="row m-0 justify-content-center" style="font-weigth:900">
                <div class="col-6">
                  <div>
                    <small class="text-muted" style="font-size: 12px">LAST COVID TEST</small>
                    <div class="row m-0 align-items-center">
                      <div
                        :class="{ 
                  'bg-danger' : (result.result == 'positive'), 
                  'bg-success' : (result.result == 'negative'), 
               'bg-secondary' : (!result)}"
                        class="bg-secondary status-indicator"
                      ></div>
                      <h6 class="ml-2 mb-0">{{result ? time : 'Not done'}}</h6>
                    </div>
                  </div>
                  <div>
                    <small class="text-muted" style="font-size: 12px">ANTIBODY TEST</small>
                    <div class="row m-0 align-items-center">
                      <div
                        :class="{ 
                  'bg-danger' : (result.result == 'positive'), 
                  'bg-success' : (result.result == 'negative'), 
               'bg-secondary' : (!result)}"
                        class="bg-secondary status-indicator"
                      ></div>
                      <h6 class="ml-2 mb-0">{{'Not done'}}</h6>
                    </div>
                  </div>
                  <div>
                    <small class="text-muted" style="font-size: 12px">PROVIDER</small>
                    <div class="row m-0 align-items-center">
                      <div
                        :class="{ 
                  'bg-danger' : (result.result == 'positive'), 
                  'bg-success' : (result.result == 'negative'), 
               'bg-secondary' : (!result)}"
                        class="bg-secondary status-indicator"
                      ></div>
                      <h6 class="ml-2 mb-0">{{result ? 'Nyaho Medical Centre' : 'None'}}</h6>
                    </div>
                  </div>
                </div>
                <div class="col-6 pl-0">
                  <div>
                    <small class="text-muted" style="font-size: 12px">SYMPTOM TRACKER</small>
                    <div class="row m-0 align-items-center">
                      <div
                        :class="{ 
                  'bg-danger' : (result.result == 'positive'), 
                  'bg-success' : (result.result == 'negative'), 
               'bg-secondary' : (!result)}"
                        class="bg-secondary status-indicator"
                      ></div>
                      <h6 class="ml-2 mb-0">None</h6>
                    </div>
                  </div>
                  <div>
                    <small class="text-muted" style="font-size: 12px">LATEST TEMP READING</small>
                    <div class="row m-0 align-items-center">
                      <div
                        :class="{ 
                  'bg-danger' : (result.result == 'positive'), 
                  'bg-success' : (result.result == 'negative'), 
               'bg-secondary' : (!result)}"
                        class="bg-secondary status-indicator"
                      ></div>
                      <h6 class="ml-2 mb-0">None</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pills--container pt-2">
        <div class="row m-0 align-items-center justify-content-between py-3">
          <div>
            <button
              class="btn btn-outline-secondary mx-1 footer-pills"
              @click="startDownload()"
              v-if="result"
            >Print</button>
            <button
              class="btn btn-outline-secondary mx-1 footer-pills"
              @click="print()"
              v-else
            >Print</button>
            <button class="btn btn-outline-secondary mx-1 footer-pills" @click="print()">Share</button>
          </div>
          <div>
            <button
              class="btn btn-outline-secondary mx-1 footer-pills"
              @click="goCoro"
            >{{result ? 'Take another test' : 'Get Tested'}}</button>
            <button class="btn btn-outline-secondary mx-1 footer-pills" @click="back">Done</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { mapState } from "vuex";
import moment from "moment";
export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      result: this.$store.state.serviceStore.covidReady,
      testNull: "#535353",
      testSuccess: "#4AA869",
      testFail: "#e00700",
      file: "",
      time: "",
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
    }),
  },
  created() {
    this.file = this.result.diagnostic_report_documents[0].file;
    // if (doc) {
    //   this.file = doc.file.split("/");
    // }
    this.time = moment(this.result.issuedDate).fromNow();
  },
  methods: {
    back() {
      this.$router.push("/");
    },
    goCoro() {
      this.$router.push("/corona-tool");
    },
    print() {
      this.$htmlToPaper("printMe");
    },
    startDownload() {
      // Generate download of hello.txt
      // file with some content
      this.loading = true;
      let doc = this.result.diagnostic_report_documents[0];
      if (doc) {
        var files = doc.file.split("/");
        let name = files[files.length - 1];
        const a = document.createElement("A");
        a.setAttribute("download", name);
        a.setAttribute("target", "_blank");
        a.setAttribute("href", doc.file);
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.loading = false;
      } else {
        this.loading = false;
      }
      // var filename =
      //   "https://serenity.s3.amazonaws.com/media/0004_auto_20200323_1845.py";
    },
  },
};
</script>

<style lang="scss" scoped>
.covid-ready {
  // background: #e6e6e6 0% 0% no-repeat padding-box;
  max-height: 200vh;
  min-height: 100vh;
}
.arrow-container {
  padding: 9px 14px;
}
.status-card {
  max-height: 600px;
  max-width: 450px;
  border-radius: 25px;
  margin: auto;
}
.not-tested {
  background: #e6e6e6 0% 0% no-repeat padding-box;
}
.success-test {
  background: #6cfe6ca6 0% 0% no-repeat padding-box;
}

.fail-test {
  background: #ff9797a6 0% 0% no-repeat padding-box;
}

.notest {
  color: #535353;
}
.success {
  color: #02ff02;
}

.fail {
  color: #ff0000;
}

.status-card {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  border: 0px;
}

.pills-container {
  max-width: 450px !important;
  margin-left: auto;
  margin-right: auto;
}
.footer-pills {
  border-radius: 24px;
}
.status-indicator {
  height: 5px;
  width: 5px;
  padding: 5px;
  border-radius: 50%;
}
</style>