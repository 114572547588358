var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"LabTile"},[_c('div',[_c('div',{staticClass:"row m-0 align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-8 text-left"},[_c('div',[_c('div',{staticClass:"col p-0",staticStyle:{"font-size":"0.9rem"}},[_c('b',[_vm._v(_vm._s(_vm.lab.code))])])]),_c('div',[_c('div',{staticClass:"col p-0 text-muted",staticStyle:{"font-size":"0.6rem"}},[_vm._v(" "+_vm._s(_vm._f("readableDateTime")(_vm.lab.issuedDate))+" ")])]),_c('div',[_c('div',{staticClass:"col p-0 text-muted",staticStyle:{"font-size":"0.6rem"}},[_vm._v(" Lab type: "+_vm._s(_vm.lab.category)+" ")])])]),_c('div',{staticClass:"col-2 p-0 text-center mt-2",staticStyle:{"font-size":"0.6rem"}},[_c('span',{staticClass:"status-indicator",class:("" + (_vm.lab.status == 'final' || _vm.lab.status == 'completed'
              ? 'green'
              : _vm.lab.status == 'preliminary' || _vm.lab.status == 'in-progress'
              ? 'orange'
              : _vm.lab.status == 'active'
              ? 'blue'
              : _vm.lab.status == 'draft'
              ? 'gray'
              : 'red'))}),_c('br'),_vm._v(" "+_vm._s(_vm.lab.status)+" ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-2 align-middle px-2"},[_c('img',{staticStyle:{"width":"40px"},attrs:{"src":"https://img.icons8.com/dusk/64/000000/thin-test-tube.png"}})])}]

export { render, staticRenderFns }