<template>
  <div class="Imagings">
    <div class="container mt-5 mb-5">
      <!-- header -->
      <div class="row pt-5 pb-5 text-left">
        <div class="col-md-7">
          <h3>
            <b>Your Imaging Results</b>
          </h3>
        </div>
      </div>

      <!-- current prescriptions -->
      <div v-if="Imaging == [] || Imaging == null">
        <h4>No imaging results</h4>
      </div>
      <div v-else class="row">
        <div v-for="(p, i) in 3" :key="i" class="col-6 col-md-3 mb-2">
          <ImagingTile />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImagingTile from "@/components/ImagingTile";

export default {
  name: "imagings",
  components: {
    ImagingTile,
  },
  data() {
    return {
      Imaging: null,
    };
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.Imagings {
  width: 100%;
  overflow: hidden;
}
</style>
