<template>
  <div class="bot">
    <form id="form" autocomplete="off">
      <cf-robot-message
        cf-questions="Hello there! I'm Serenity and I am here to help you book a covid 19 test at Nyaho Medical Centre."
      ></cf-robot-message>
      <cf-robot-message
        cf-questions="By proceeding you are agreeing to the terms and conditions  <a href='#Terms' style='color:blue'>here</a>"
      ></cf-robot-message>
      <fieldset>
        <select
          cf-questions="Would you like to proceed?"
          name="consent"
          id="consent"
          class="form-control"
        >
          <option></option>
          <option>Yes</option>
          <option>No</option>
        </select>
      </fieldset>
      <fieldset>
        <select
          cf-questions="Are you booking a test for yourself or someone else"
          name="bookingfor"
          id="bookingfor"
          class="form-control"
        >
          <option></option>
          <option>Yes, myself</option>
          <option>No, someone else</option>
        </select>
      </fieldset>
      <fieldset>
        <label for="name">What's your name?</label>
        <input
          cf-conditional-consent="Yes"
          :cf-conditional-bookingfor="canShow"
          required
          cf-questions="Can you tell me the fullname of the person you are booking for?"
          class="form-control"
          name="name"
          id="name"
        />
      </fieldset>
      <fieldset>
        <label for="name">What's your name?</label>
        <input
          cf-conditional-consent="Yes"
          :cf-conditional-bookingfor="canShow"
          required
          cf-questions="Please enter {name}'s mobile number"
          class="form-control"
          name="mobile"
          id="mobile"
        />
      </fieldset>
      <!--<cf-robot-message
            cf-conditional-consent="Yes"
            cf-questions="Hi {name}, please enter your mobile number"
          >
          </cf-robot-message>
          <cf-robot-message
            cf-conditional-consent="Yes"
            cf-questions="Kindly let’s begin."
      ></cf-robot-message>-->

      <!--God made it possible-->
      <fieldset>
        <select
          cf-conditional-consent="Yes"
          :cf-conditional-bookingfor="canShow"
          cf-questions="Please select their gender"
          name="gender"
          id="gender"
          class="form-control"
        >
          <option></option>
          <option>Male</option>
          <option>Female</option>
        </select>
      </fieldset>
      <cf-robot-message
        cf-conditional-consent="Yes"
        :cf-conditional-bookingfor="canShow"
        cf-questions="Great. Please enter {name}'s date of birth in the format given below."
      ></cf-robot-message>
      <fieldset>
        <label for="name">Please enter your dob</label>
        <input
          type="date"
          required
          :cf-conditional-bookingfor="canShow"
          cf-input-placeholder="dd/mm/yyyy"
          cf-questions="<strong>eg. 26/09/1993</strong>"
          class="form-control"
          name="dateofbirth"
          id="dateofbirth"
        />
      </fieldset>
      <fieldset>
        <select
          cf-conditional-consent="Yes"
          cf-questions="Select a day for your test. Holiday and weekend times may vary, call 0209404046 to confirm if you're booking on a weekend or holiday."
          name="appointmentdate"
          id="appointmentdate"
          class="form-control"
        >
          <option></option>
          <option>Today</option>
          <option>Tomorrow</option>
          <option>Choose other days</option>
        </select>
      </fieldset>
      <fieldset>
        <select
          cf-conditional-consent="Yes"
          cf-questions="The cost for your test is GHc 495. This amount covers your PPE and doctor's consultation for the COVID 19 test. How would you like to pay?"
          name="paymentmethod"
          id="paymentmethod"
          class="form-control"
        >
          <option></option>
          <option>Mobile Money</option>
          <option>Cash</option>
        </select>
      </fieldset>
      <fieldset>
        <select
          cf-conditional-consent="Yes"
          cf-conditional-paymentmethod="Visa"
          cf-questions="Sorry, Visa payment is not available at the moment. Use Mobile Money."
          name="paymentmethod"
          id="paymentmethod"
          class="form-control"
        >
          <option></option>
          <option>Try Mobile Money</option>
          <option>I don't have mobile money</option>
        </select>
      </fieldset>
      <fieldset>
        <select
          cf-conditional-consent="Yes"
          cf-conditional-paymentmethod="Mobile Money|Try Mobile Money"
          cf-questions="Please select a payment provider"
          name="paymentprovider"
          id="paymentprovider"
          class="form-control"
        >
          <option></option>
          <option>MTN</option>
          <option>Airtel Tigo</option>
          <option>Vodafone</option>
        </select>
      </fieldset>
      <fieldset>
        <label for="name">Please enter your mobile number</label>
        <input
          cf-conditional-paymentmethod="Mobile Money|Try Mobile Money"
          cf-questions="Please enter the {paymentprovider} Mobile money number"
          class="form-control"
          name="mobilemoneynumber"
          id="mobilemoneynumber"
        />
      </fieldset>
      <cf-robot-message
        cf-conditional-paymentprovider="Vodafone"
        cf-conditional-consent="Yes"
        cf-questions="Please dial *110# and select option 4 then select Generate voucher to generate a voucher for this payment."
      ></cf-robot-message>
      <fieldset>
        <label for="name">Please enter your mobile number</label>
        <input
          cf-conditional-paymentprovider="Vodafone"
          cf-questions="Enter your generated voucher here"
          class="form-control"
          name="mobilemoneyvoucher"
          id="mobilemoneyvoucher"
        />
      </fieldset>
      <fieldset>
        <select
          cf-conditional-consent="Yes"
          cf-conditional-paymentmethod="Mobile Money|Try Mobile Money|Cash"
          cf-questions="Which of the following symptoms do you have?"
          name="symptoms"
          id="symptoms"
          class="form-control"
          multiple
        >
          <option val="red">Difficulty Breathing</option>
          <option>Diarrhoea</option>
          <option>Cough</option>
          <option>Sore Throat</option>
          <option>Fever</option>
          <option>General Weakness</option>
          <option>Runny Nose</option>
          <option>Nausea / Vomiting</option>
          <option>Headache</option>
          <option>Irritability / Confusion</option>
          <option>Chest Pain</option>
          <option>Abdominal Pain</option>
          <option>Muscular Pain</option>
          <option>Joint Pain</option>
          <option>None</option>
        </select>
      </fieldset>
      <cf-robot-message
        cf-conditional-consent="Yes"
        cf-questions="I am processing your data. Please hold."
      ></cf-robot-message>
    </form>
    <div id="cf-context" class="dark-theme" role="cf-context" cf-context></div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import env from "../../env";

export default {
  data() {
    return {
      conversationalForm: null,
      userSessionData: { appointmentDateTime: "", resultData: [] },
      apiUrl:
        process.env.NODE_ENV === "production"
          ? env.baseUrlProduction
          : env.baseUrlTest,
      // apiUrl: "https://api.v05.serenity.health", //"https://api.v05.serenity.health"; //"https://api.v05.serenity.health/"
      selectedDateTime: "",
      otherDaysTags: [
        {
          tag: "select",
          name: "appointmentdate",
          "cf-questions": "Please pick another day from the list below.",
          children: [],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
      loggedIn: (state) => state.authStore.loggedIn,
      canShow: (state) =>
        state.authStore.loggedIn
          ? "No, someone else"
          : "No, someone else|Yes, myself",
    }),
  },
  methods: {
    setupForm() {},
    submitCallback() {
      // Appointment booking
      let totalPoints = 0;
      var formDataSerialized = this.conversationalForm.getFormData(true);

      if (formDataSerialized["bookingfor"][0] === "Yes, myself") {
        formDataSerialized.name = this.userInfo.sz_username;
        formDataSerialized.mobile = this.userInfo.sz_phoneno;
        formDataSerialized.dateofbirth = this.userInfo.sz_dob;
        formDataSerialized.location = this.userInfo.sz_address;
      }

      const payload = {
        difficultyBreathing: 0,
        directContact: 0,
        hasCough: 0,
        travelRecently: 0,
        hasFever: 0,
        contactMe: false,
        appointment: {},
        address: formDataSerialized["location"],
        mobile: formDataSerialized["mobile"],
        patientName: formDataSerialized["name"],
        dob: formDataSerialized["dateofbirth"],
      };

      let paymentPayload = {};

      if (
        formDataSerialized["paymentmethod"] &&
        formDataSerialized["paymentmethod"] != "Cash"
      ) {
        payload.paymentMethod = formDataSerialized["paymentmethod"][0];

        paymentPayload = {
          vendor: "mtn",
          mobile:
            "233" +
            (formDataSerialized["mobilemoneynumber"][0] === "0"
              ? formDataSerialized["mobilemoneynumber"].slice(1)
              : formDataSerialized["mobilemoneynumber"]),
          service: "covid-19",
          productid: "",
          status: "pending",
          trans_type: "debit",
          voucher_number: "",
          slotid: "covid-19",
          provider: "Nyaho",
          patientName: formDataSerialized["name"],
          dob: formDataSerialized["dateofbirth"],
        };
      }

      if (formDataSerialized["paymentmethod"][0] === "Cash") {
        this.userSessionData.paymentmethod = "cash";
      }

      if (formDataSerialized["paymentmethod"][0] != "Cash")
        this.userSessionData.paymentmethod = "mobile-money";
      if (
        formDataSerialized["paymentmethod"][0] != "Cash" &&
        formDataSerialized["paymentprovider"][0] === "Vodafone"
      ) {
        paymentPayload.vendor = "vodafone";
        paymentPayload.voucher_number =
          formDataSerialized["mobilemoneyvoucher"];
      }

      if (
        formDataSerialized["paymentmethod"][0] != "Cash" &&
        formDataSerialized["paymentprovider"][0] === "Airtel Tigo"
      ) {
        paymentPayload.vendor = "airtel";
      }

      const date = new Date();
      const tommorrow = new Date(date.setDate(date.getDate() + 1));
      const currentDate =
        date.getFullYear() +
        "-" +
        (parseInt(date.getMonth() + 1) < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        "-" +
        date.getDate();
      const tomorrowDate =
        tommorrow.getFullYear() +
        "-" +
        (parseInt(tommorrow.getMonth() + 1) < 10
          ? "0" + (tommorrow.getMonth() + 1)
          : tommorrow.getMonth() + 1) +
        "-" +
        tommorrow.getDate();
      const selectedDate =
        this.selectedDateTime === "Today"
          ? currentDate
          : this.selectedDateTime === "Tomorrow"
          ? tomorrowDate
          : this.selectedDateTime;

      payload.appointment = {
        appointmentDateTime: this.userSessionData.appointmentDateTime,
        mobile: formDataSerialized["mobile"],
        type: "covid-19",
        slotid: this.userSessionData.resultData[
          this.userSessionData.selectedTime
        ].slotid,
        paymentMethod: this.userSessionData.paymentmethod,
      };

      // output
      if (totalPoints < 2) {
        payload["positive"] = false;

        // Call REST API
        // Make a request for a user with a given ID
        axios
          .post(this.apiUrl + "/visits/online/addpatientscreening", payload)
          .then((response) => {
            // handle success
            console.log("logging axios..(success)", response);
            paymentPayload.screenId = response.data.id;
            paymentPayload.patientId = response.data.patientId;
            paymentPayload.appointmentId = response.data.appointmentid;
          })
          .catch((error) => {
            // handle error
            console.log("logging axios..(error)", error);
          })
          .then(async () => {
            const tags = [
              {
                tag: "select",
                name: "startoptions",
                "cf-questions":
                  "When would you like to book the next appointment?",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Next available Date",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Choose my own Date",
                    value: "",
                  },
                ],
              },
            ];
            const navTags = [
              {
                tag: "cf-robot-message",
                name: "startoptions",
                "cf-questions":
                  "When would you like to <a href='/#/kjhhg'></a> book the next appointment?",
              },
            ];

            // always executed
            if (
              formDataSerialized["paymentmethod"] &&
              formDataSerialized["paymentmethod"][0] != "Cash"
            )
              this.conversationalForm.addRobotChatResponse(
                "Verifying payment..."
              );

            // Make a request for a user to the payment api
            if (
              formDataSerialized["paymentmethod"] &&
              formDataSerialized["paymentmethod"][0] != "Cash"
            )
              await axios.post(
                this.apiUrl + "/finance/paybills",
                paymentPayload
              );

            this.conversationalForm.addRobotChatResponse(
              "Thank you for booking a COVID-19 test."
            );
            this.conversationalForm.addRobotChatResponse(
              "Your appointment has been scheduled for " +
                this.selectedDateTime +
                " between " +
                this.userSessionData.appointmentDateTime.split(" ")[1] +
                " to 4pm. However, if you need to speak to a qualified professional about your symptoms call 0209404046"
            );
            if (
              formDataSerialized["paymentmethod"] &&
              formDataSerialized["paymentprovider"]
            )
              if (formDataSerialized["paymentprovider"][0] === "MTN")
                this.conversationalForm.addRobotChatResponse(
                  "Please dial *170# and select options 6 then 3 to authorise payment. Thank you."
                );

            if (this.userSessionData.paymentmethod === "cash")
              this.conversationalForm.addRobotChatResponse(
                "Please approach the cashiers at Nyaho Medical Centre to pay for your bills."
              );
            this.conversationalForm.addRobotChatResponse(
              "You can view this appointment and others in your appointments by going back to the previous page"
            );
            console.log(formDataSerialized);
          });
      }
    },
    async flowCallback(dto, success, error) {
      // Add appointment date tags
      if (dto.tag.domElement.name === "appointmentdate") {
        // add appointment dates as formless
        const tags = [
          {
            tag: "select",
            name: "appointmentdate",
            "cf-questions":
              "Select from the available slots a day for your test. Holiday and weekend times may vary, call 0209404046 to confirm if you're booking on a weekend or holiday.",
            children: [
              {
                tag: "option",
                "cf-label": "Today",
                value: "",
              },
              {
                tag: "option",
                "cf-label": "Tomorrow",
                value: "",
              },
              {
                tag: "option",
                "cf-label": "Choose other days",
                value: "",
              },
            ],
          },
        ];

        const getDateArray = function (start, end) {
          const arr = new Array();
          const dt = new Date(start);
          while (dt <= end) {
            arr.push({
              tag: "option",
              "cf-label":
                new Date(dt).getFullYear() +
                "-" +
                (parseInt(new Date(dt).getMonth() + 1) < 10
                  ? "0" + (new Date(dt).getMonth() + 1)
                  : new Date(dt).getMonth() + 1) +
                "-" +
                new Date(dt).getDate(),
              value: "",
            });
            dt.setDate(dt.getDate() + 1);
          }
          return arr;
        };

        const date = new Date();
        const next40days = new Date(date.setDate(date.getDate() + 40));

        this.otherDaysTags[0].children = getDateArray(new Date(), next40days);
        if (dto.text === "Choose other days")
          conversationalForm.addTags(this.otherDaysTags, true);
        this.selectedDateTime = dto.text;
      }

      // Time slots: Choose any dates -> Pick dates
      if (
        (dto.tag.domElement.name === "appointmentdate" &&
          dto.text === "Today") ||
        (dto.tag.domElement.name === "appointmentdate" &&
          dto.text === "Tomorrow")
      ) {
        this.conversationalForm.addRobotChatResponse(
          "Fetching available time slots..."
        );
        const date = new Date();
        let currentDate =
          date.getFullYear() +
          "-" +
          (parseInt(date.getMonth() + 1) < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) +
          "-" +
          date.getDate() +
          " " +
          "00:00";
        const tommorrow = new Date(date.setDate(date.getDate() + 1));
        const tomorrowDate =
          tommorrow.getFullYear() +
          "-" +
          (parseInt(tommorrow.getMonth() + 1) < 10
            ? "0" + (tommorrow.getMonth() + 1)
            : tommorrow.getMonth() + 1) +
          "-" +
          tommorrow.getDate() +
          " " +
          "00:00";
        const nextDate = new Date(date.setDate(date.getDate() + 1));
        const nextDayDate =
          nextDate.getFullYear() +
          "-" +
          (parseInt(nextDate.getMonth() + 1) < 10
            ? "0" + (nextDate.getMonth() + 1)
            : nextDate.getMonth() + 1) +
          "-" +
          nextDate.getDate() +
          " " +
          "00:00";
        let payload = {
          startDateTime: currentDate,
          endDateTime: tomorrowDate,
          service: "covid-19",
        };
        if (dto.text === "Today") {
          // TODO: Make a network call: Get Available time slots for today and then add time selections tags
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patient/availableslots",
              payload
            );
            const availableTimeSlots = [
              {
                tag: "select",
                name: "availabletimeslots",
                "cf-questions": "The available time slots for today are:",
                children: [],
              },
            ];
            this.userSessionData.result = [];
            for (let item of response.data) {
              const time = item.startDateTime.split("T")[1];
              const parsedTime =
                time.split(":")[0] +
                ":" +
                time.split(":")[1] +
                (parseInt(time.split(":")[0]) > 11 ? "pm" : "am");
              if (!this.userSessionData.result.includes(parsedTime)) {
                this.userSessionData.result.push(parsedTime);
                availableTimeSlots[0].children.push({
                  tag: "option",
                  "cf-label": parsedTime,
                  value: "",
                });
                this.userSessionData.resultData[parsedTime] = item;
              }
            }
            // handle success
            if (response.data.length > 0)
              this.conversationalForm.addTags(availableTimeSlots, true);
            if (response.data.length === 0)
              this.conversationalForm.addRobotChatResponse(
                "Sorry, It looks like all the available slots for today have been booked. There are no available slots."
              );
            console.log("logging axios..(success)", response);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            this.conversationalForm.addRobotChatResponse(
              "Sorry, I could not fetch the available time slots for this date."
            );
          }
          while (currentDate.includes("-"))
            currentDate = currentDate.replace("-", "/");
          this.userSessionData.appointmentDateTime = currentDate.split(" ")[0];
        }
        payload = {
          startDateTime: tomorrowDate,
          endDateTime: nextDayDate,
          service: "covid-19",
        };
        if (dto.text === "Tomorrow") {
          // TODO: Make a network call: Get Available time slots for tomorrow and then add time selections tags
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patient/availableslots",
              payload
            );
            const availableTimeSlots = [
              {
                tag: "select",
                name: "availabletimeslots",
                "cf-questions": "The available time slots for tomorrow are:",
                children: [],
              },
            ];
            this.userSessionData.result = [];
            for (let item of response.data) {
              const time = item.startDateTime.split("T")[1];
              const parsedTime =
                time.split(":")[0] +
                ":" +
                time.split(":")[1] +
                (parseInt(time.split(":")[0]) > 11 ? "pm" : "am");
              if (!this.userSessionData.result.includes(parsedTime)) {
                this.userSessionData.result.push(parsedTime);
                availableTimeSlots[0].children.push({
                  tag: "option",
                  "cf-label": parsedTime,
                  value: "",
                });
                this.userSessionData.resultData[parsedTime] = item;
              }
            }
            // handle success
            if (response.data.length > 0)
              this.conversationalForm.addTags(availableTimeSlots, true);
            if (response.data.length === 0)
              this.conversationalForm.addRobotChatResponse(
                "Sorry, It looks like all the available slots for tomorrow have been booked. There are no available slots."
              );
            console.log("logging axios..(success)", response);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            this.conversationalForm.addRobotChatResponse(
              "Sorry, I could not fetch the available time slots for this date."
            );
          }
          this.userSessionData.appointmentDateTime = tomorrowDate.split(" ")[0];
        }
        if (dto.text === "Choose other days") {
          // open date selection popup
          openTray("bottom-sheet-payment");
          // hold chatbot to wait: for time selection response from popup
          const result = await new Promise((resolve) => {
            const timer = setInterval(
              () => console.log("popup open and waiting"),
              1000
            );
            window.addEventListener("popupclosed", () => {
              clearInterval(timer);
              resolve();
            });
          });
          // add tags
          this.conversationalForm.addTags(userExistenceTags, true);
          this.userSessionData.appointmentDateTime =
            window.selectDate + " " + window.selectTime;
        }
      }
      // Choose time tags
      if (dto.tag.domElement.name === "availabletimeslots") {
        this.userSessionData.appointmentDateTime += " " + dto.text.slice(0, 5);
        this.userSessionData.selectedTime = dto.text;
      }

      // Grab mobile number
      if (dto.tag.domElement.name === "mobile") {
        this.userSessionData.mobile = dto.text;
      }

      if (dto.tag.domElement.name === "bookingfor") {
        if (dto.text === "Yes, myself")
          if (!this.loggedIn) this.userSessionData.isBookingForSelf = true;
      }

      // Verify user
      if (dto.tag.domElement.name === "dateofbirth") {
        // post
        const payload = {
          sz_firstname: "",
          sz_email: "",
          sz_password: "",
          sz_lastname: "",
          sz_dob: "",
          sz_gender: "",
          sz_phoneno: this.userSessionData.mobile,
          sz_marital_status: "",
          sz_nationality: "",
          sz_native_language: "",
          sz_religion: "",
          sz_occupation: "",
          sz_employer: "",
          sz_ice_name: "",
          sz_ice_place_of_work: "",
          sz_ice_address: "",
          sz_ice_phoneno: "",
          sz_bookedfortime: "",
          sz_bookername: "",
          sz_paymentmethod: "",
          sz_complaint: "suspected-covid-19",
          sz_docempid: "",
          sz_docname: "",
          sz_unitid: "",
          sz_othername: "",
          sz_address: "",
          sz_title: "",
          phone: this.userSessionData.mobile,
        };

        const verifyLoginCodeInputTags = [
          {
            tag: "cf-robot-message",
            name: "message",
            "cf-questions": `I found an existing account with this mobile number.`,
          },
          {
            tag: "input",
            name: "verifylogincode",
            "cf-questions":
              "Please verify your number by entering the six digit code that has been sent to your phone.",
          },
        ];

        const welcomeBackMessageTags = [
          {
            tag: "cf-robot-message",
            name: "message",
            "cf-questions": "",
          },
          {
            tag: "cf-robot-message",
            name: "message",
            "cf-questions": `Please find a summary of your ${this.userSessionData.appointmentType} : You opted for ${this.userSessionData.appointmentDate} at ${this.userSessionData.appointmentTime}`,
          },
          {
            tag: "select",
            name: "proceedwithappointment",
            "cf-questions":
              "Select Proceed to book an appointment or cancel to cancel the appointment.",
            children: [
              {
                tag: "option",
                "cf-label": "Proceed",
                value: "",
              },
              {
                tag: "option",
                "cf-label": "Cancel",
                value: "",
              },
            ],
          },
        ];

        // verify account
        this.conversationalForm.addRobotChatResponse("Verifying...");
        try {
          // Make a request for a user with a given ID
          let response = await axios.post(
            this.apiUrl + "/patient/checkexistence",
            payload
          );

          const patientResponse = await axios.post(
            this.apiUrl + "/patient/get",
            {
              szpatientid: response.data.sz_userid,
            }
          );

          // handle success
          console.log("logging axios..(success)", response);
          this.userSessionData.userData = patientResponse.data;
          this.userSessionData.fullName = response.data.sz_username;
          this.userSessionData.userId = response.data.sz_userid;

          // Send code
          response = await axios.post(
            this.apiUrl + "/user/sendotpcode",
            payload
          );
          // handle success
          console.log("logging axios..(success)", response);
          this.userSessionData.code = response.data.code;
          this.conversationalForm.addTags(verifyLoginCodeInputTags, true);
        } catch (e) {
          // handle success
          console.log("logging axios..(error)", e);
        }
      }
      if (dto.tag.domElement.name === "verifylogincode") {
        const verifyLoginCodeInputTags = [
          {
            tag: "input",
            name: "verifylogincode",
            "cf-questions":
              "Please verify your number by entering the six digit code that has been sent to your phone.",
          },
        ];

        if (this.userSessionData.code != dto.text) {
          this.conversationalForm.addRobotChatResponse(
            "Sorry, the code does not match."
          );
          this.conversationalForm.addTags(verifyLoginCodeInputTags, true);
        }

        if (this.userSessionData.code == dto.text) {
          if (this.userSessionData.isBookingForSelf) {
            this.$store.commit("authStore/setLoggedIn", true);
            this.$store.commit(
              "authStore/setUserInfo",
              this.userSessionData.userData
            );
            this.$store.commit(
              "authStore/setId",
              this.userSessionData.userData.id
            );
          }
          this.conversationalForm.addRobotChatResponse(
            `Hi ${this.userSessionData.fullName}`
          );
        }
      }
      // call succes to move to next flow step
      success();
    },
  },
  mounted() {
    this.setupForm();

    var dispatcher = new cf.EventDispatcher();
    dispatcher.addEventListener(
      cf.ChatListEvents.CHATLIST_UPDATED,
      function (event) {
        // your code here
        const date = document.querySelector("input[placeholder='dd/mm/yyyy']");

        function checkValue(str, max) {
          if (str.charAt(0) !== "0" || str == "00") {
            var num = parseInt(str);
            if (isNaN(num) || num <= 0 || num > max) num = 1;
            str =
              num > parseInt(max.toString().charAt(0)) &&
              num.toString().length == 1
                ? "0" + num
                : num.toString();
          }
          return str;
        }

        date.addEventListener("input", function (e) {
          this.type = "text";
          var input = this.value;
          if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
          var values = input.split("/").map(function (v) {
            return v.replace(/\D/g, "");
          });
          if (values[0]) values[0] = checkValue(values[0], 31);
          if (values[1]) values[1] = checkValue(values[1], 12);
          var output = values.map(function (v, i) {
            return v.length == 2 && i < 2 ? v + "/" : v;
          });
          this.value = output.join("").substr(0, 14);
        });
      },
      false
    );

    this.conversationalForm = window.cf.ConversationalForm.startTheConversation(
      {
        formEl: document.getElementById("form"),
        context: document.getElementById("cf-context"),
        preventAutoFocus: true,
        userImage:
          "https://cdn.glitch.com/1d1d7c79-abe4-4c95-b135-3a111632f4d8%2FEllipse%2018.png?v=1585908713570",
        robotImage: "../assets/logo.png",
        flowStepCallback: this.flowCallback,
        submitCallback: this.submitCallback,
        eventDispatcher: dispatcher,
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.cf-input-button {
  background-color: green;
  font-size: 100px;
}

.inputWrapper {
  /* background-color: green; */
  background-color: #c4c4c431;
}

.dark-theme .conversational-form cf-input input:hover {
  color: black;
}

.dark-theme .conversational-form .cf-button:hover cf-radio {
  color: grey;
}

.dark-theme .conversational-form-inner input:hover {
  color: green;
  background-color: #c4c4c431;
}

.dark-theme .conversational-form cf-input textarea:hover {
  color: grey;
}

.dark-theme .conversational-form cf-chat-response thumb > p {
  background-color: white;
  background-image: url("../assets/logo.png") !important;
}

.dark-theme .conversational-form cf-chat-response.user thumb {
  background-color: white;
  background-image: url("https://gulpjs.com/img/gulp-white-text.svg") !important;
  background-image: url("https://cdn.glitch.com/1d1d7c79-abe4-4c95-b135-3a111632f4d8%2FEllipse%2018.png?v=1585908713570") !important;
}

dark-theme .conversational-form cf-chat-response.robot thumb {
  background-color: white;
}

#chatstart {
  margin: 0;
  padding: auto;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: lighter;
}

.hold {
  background: linear-gradient(
    140.72deg,
    #72deff 0%,
    rgba(35, 31, 219, 0.51) 100%
  );
  margin-top: -10px;
  padding-bottom: 20px;
  padding-top: 20px;
}

#chatone {
  font-family: "Lato", sans-serif;
  padding-left: 10px;
  font-weight: lighter;
  padding-top: 10px;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: large;
}

#conversational-form {
  background-color: white;
  color: white;
}

.conversational-form-inner {
  background-color: white;
}

.conversational-form-inner input {
  color: #00000050;
  background-color: #c4c4c431;
}

.conversational-form {
  /* color: hotpink; */
  background-color: white;
}

.cf-button-input {
  background-color: #c4c4c431;
}

.chat-popup {
  width: 50%;
}

#cf-context {
  width: 100% !important;
}

.user.can-edit.peak-thumb.show p {
  background-color: blue;
  color: white;
  font-weight: bold;
}

.conversational-form--enable-animation .cf-button.animate-in {
  background-color: blue;
  color: white;
  font-weight: bold;
  border: 1px solid white;
}

.cf-button:hover,
.cf-button:hover:not([checked="checked"]) {
  color: green;
}

.conversational-form--enable-animation .cf-button.animate-in:hover {
  background-color: white;
  color: blue;
  border: 1px solid blue;
}

thumb .conversational-form--enable-animation cf-chat-response.user.peak-thumb {
  background-color: tomato;
}

.cf-input-button.cf-input-button {
  background-color: white;
  background-repeat: no-repeat;
  color: white;
}

cf-input-button.cf-input-button .cf-input-icons {
  color: green;
}

cf-input-button.cf-input-button .cf-input-progress {
  background-color: green;
}

cf-input-button.cf-input-button .cf-input-attachment {
  background-color: green;
}

.conversational-form--enable-animation cf-chat-response text > p.show {
  background-color: white;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  /* line-height: normal; */
  font-family: "Lato", sans-serif;
  font-weight: light;
  width: auto;
}

// .conversational-form--enable-animation cf-chat-response.robot.peak-thumb thumb {
//   background-image: url(../images/Ellipse\ 18.png) !important;
//   background-color: white;
//   width: 30px;
//   margin-right: 0;
// }

.conversational-form--enable-animation cf-chat-response thumb {
  background-color: blue;
}

cf-chat-response.user thumb {
  background-color: blue;
}

cf-input-button.cf-input-button:hover {
  background-color: blue;
}

cf-chat-response.user thumb {
  background-color: teal;
}

/*.conversational-form--enable-animation cf-chat-response text > p.show {
  /* background-color: teal;
}*/

.conversational-form--enable-animation cf-chat-response.user.peak-thumb thumb {
  background-color: white;
}

cf-chat-response.show {
  padding-left: 0;
}

#hide-button {
  display: none;
}

#cf-context {
  width: 350px;
  height: 500px;
}
#form {
  height: 0px;
  overflow: hidden;
}
#form-appointment {
  height: 0px;
  overflow: hidden;
}
/* The popup chat - hidden by default */
.chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #4caf50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}
.myForm {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
iframe {
  height: 100%;
  width: 100%;
  border-width: 0px;
  border-style: inset;
  border-color: initial;
  border-image: initial;
  border: 0;
  border: none;
  min-height: 0px;
  min-width: 0px;
  background: transparent;
}
* {
  box-sizing: border-box;
}
/* width */
::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  /* background-color:; */
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

@media screen and (max-width: 770px) {
  #cf-context {
    width: 100% !important;
  }

  .chat-popup {
    width: 100%;
  }

  #chatstart {
    font-size: 18px;
  }
}
</style>
<style>
cf-list {
  width: auto;
  height: auto;
  margin-top: 20px;
  max-height: 210px;
  overflow-y: auto;
}
</style>
