<template>
  <div class="UnderDevelopment">
    <img src="https://img.icons8.com/wired/64/000000/code.png" />
    <p class="text-muted">Oops, this page is currently under development!</p>
    <p class="text-muted" style="font-size: 0.7rem">Come again later</p>
    <button class="mt-3 btn btn-sm btn-outline-secondary" @click="goBack">Take Me Back</button>
  </div>
</template>

<script>
export default {
  name: "under-development",
  methods: {
    goBack(){
      this.$router.go(-1)
    },
  }
};
</script>

<style scoped>
.UnderDevelopment {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
