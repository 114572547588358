<template>
  <div class="LabTile">
    <div>
      <div class="row m-0 align-items-center">
        <div class="col-2 align-middle px-2">
          <img
            src="https://img.icons8.com/dusk/64/000000/thin-test-tube.png"
            style="width: 40px"
          />
        </div>
        <div class="col-8 text-left">
          <div>
            <div class="col p-0" style="font-size: 0.9rem">
              <b>{{ lab.code }}</b>
            </div>
          </div>
          <div>
            <div class="col p-0 text-muted" style="font-size: 0.6rem">
              {{ lab.issuedDate | readableDateTime }}
            </div>
          </div>
          <div>
            <div class="col p-0 text-muted" style="font-size: 0.6rem">
              Lab type: {{ lab.category }}
            </div>
          </div>
        </div>
        <div class="col-2 p-0 text-center mt-2" style="font-size: 0.6rem">
          <span
            class="status-indicator"
            :class="`${
              lab.status == 'final' || lab.status == 'completed'
                ? 'green'
                : lab.status == 'preliminary' || lab.status == 'in-progress'
                ? 'orange'
                : lab.status == 'active'
                ? 'blue'
                : lab.status == 'draft'
                ? 'gray'
                : 'red'
            }`"
          ></span>
          <br />
          {{ lab.status }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "lab-tile",
  props: {
    lab: {
      type: Object,
      required: true,
      default: () => ({
        name: "COVID-19 Test",
        status: "Ready",
        prescribedBy: "Dr. Bruce",
        dateTime: "2020-04-09 13:30",
      }),
    },
  },
  data() {
    return {};
  },
  computed: {},
  filters: {
    readableDateTime(val) {
      return moment(val).format("Do MMM, YYYY @ HH:mm");
    },
  },
  methods: {},
};
</script>

<style scoped>
.LabTile {
  border-radius: 16px;
  cursor: pointer;
  background-color: #fff;
}
.LabTile:hover {
  background-color: #ccc;
}
.status-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.status-indicator.red {
  background-color: red;
}
.status-indicator.orange {
  background-color: orange;
}
.status-indicator.green {
  background-color: green;
}
.status-indicator.blue {
  background-color: blue;
}
.status-indicator.gray {
  background-color: gray;
}
</style>
