<template>
  <div class="mt-4 mb-5 py-3">
    <div class="Appointments container">
      <!-- The modal -->
      <b-modal
        id="appoint"
        centered
        :title="'Appointment with ' + 'Dr. ' + apptSelect.sz_docname"
      >
        <!-- <Rating/> -->
        <div class="w-100">
          <div class="text-muted mb-4">
            {{ apptSelect.sz_bookingtype }} for
            <b>{{ userInfo.szfirstname + " " + userInfo.szlastname }}</b>
          </div>
          <!-- <span>Booked time: <b>{{apptSelect.sz_bookedfortime | moment("dddd, MMMM Do YYYY") }} at {{apptSelect.sz_bookedfortime | moment("hA")}}</b></span>  -->
          <div class="row m-0 mb-4">
            <div class="px-1 pr-2">
              <i class="fas fa-calendar fa-3x text-info"></i>
            </div>
            <div class="pl-3">
              <h5 class="font-weight-bold m-0">
                {{ apptSelect.sz_bookedfortime | moment("dddd, MMMM Do YYYY") }}
              </h5>
              <div class="text-muted">Date</div>
            </div>
          </div>
          <div class="row m-0 mb-4">
            <div class="px-1 pr-2">
              <i class="fas fa-stopwatch fa-3x text-info"></i>
            </div>
            <div class="pl-3">
              <h5 class="font-weight-bold m-0">
                {{ apptSelect.sz_bookedfortime | moment("hh:mm A") }}
              </h5>
              <div class="text-muted">Time</div>
            </div>
          </div>

          <div class="row m-0 mb-4">
            <div class="px-1 pr-2">
              <i class="fas fa-coins fa-3x text-info"></i>
            </div>
            <div class="pl-3">
              <h5 class="font-weight-bold m-0">
                {{ apptSelect.sz_paymentmethod }}
              </h5>
              <div class="text-muted">Payment</div>
            </div>
          </div>

          <div class="row m-0 mb-4">
            <div class="px-1 pr-2">
              <i class="fas fa-user-nurse fa-3x text-info"></i>
            </div>
            <div class="pl-3">
              <h5 class="font-weight-bold m-0">
                {{ "Dr. " + apptSelect.sz_docname }}
              </h5>
              <div class="text-muted">Doctor</div>
            </div>
          </div>
        </div>
        <template v-slot:modal-footer="{ cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="outline-danger" @click="cancel()"
            >Cancel</b-button
          >
          <!-- Button with custom close trigger value -->

          <span v-if="!apptSelect.expiredStatus">
            <a
              v-if="
                apptSelect.sz_bookingtype != 'sars-cov-2' &&
                apptSelect.sz_bookingtype != 'covid-19' &&
                apptSelect.sz_bookingstatus == 'Confirmed'
              "
              @click="startVideo"
              class="btn btn-outline-success"
              >Start Appointment</a
            >
            <a
              v-if="apptSelect.sz_bookingstatus != 'Confirmed'"
              @click="showPaymentModal"
              class="btn btn-outline-success"
              >Pay for this Appointment</a
            >
          </span>
          <a v-else @click="routeAppoint" class="btn btn-outline-success"
            >Book a new Appointment</a
          >
        </template>
      </b-modal>
      <sweet-modal v-if="show" blocking ref="modal">
        <template>
          <div>
            <b-form
              @submit.prevent="onSubmit"
              @reset.prevent="onReset"
              v-if="show"
            >
              <b-form-group
                id="input-group-3"
                label="Select Payment Option:"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="form.paymentType"
                  :options="paymentTypes"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Please Select Your Provider:"
                label-for="input-3"
                style="margin-top: 30px"
              >
                <b-form-radio-group
                  v-model="form.provider"
                  :options="options"
                  required
                  class="mb-3"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                ></b-form-radio-group>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label-for="input-1"
                description="We'll never share your email with anyone else."
              >
                <b-form-input
                  id="input-1"
                  v-model="form.mobileNumber"
                  type="number"
                  required
                  style="0540452046margin-top: 30px"
                  placeholder="Enter mobile money number"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-4">
                <b-form-checkbox-group
                  v-model="form.rememberPaymentInfo"
                  id="checkboxes-4"
                >
                  <b-form-checkbox value="true">Remember</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>

              <b-button type="submit" variant="primary">
                <div v-if="paymentLoading">
                  <b-spinner small label="Loading..."></b-spinner>
                </div>
                <span v-else>Submit</span>
              </b-button>
            </b-form>
          </div>
        </template>
      </sweet-modal>
      <sweet-modal v-if="show" blocking ref="vodafonePaymentStepsModal">
        <template>
          <div>
            <b-form
              @submit.prevent="onSubmit"
              @reset.prevent="onReset"
              v-if="show"
            >
              <label class="mr-sm-2" for="inline-form-custom-select-pref"
                >Steps For Vodafone Payment</label
              >
              <b-form-group id="input-group-1" label-for="input-1">
                <strong style="display: block">1. Dial *110#</strong>
                <strong style="display: block">2. Select option 4</strong>
                <strong style="display: block"
                  >3. Select Generate Voucher</strong
                >
                <strong style="display: block"
                  >4. Enter Generated Voucher below</strong
                >
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                description="We'll never share your details with anyone else."
              >
                <b-form-input
                  id="input-1"
                  v-model="form.voucherForVodafone"
                  type="number"
                  required
                  style="margin-top: 30px"
                  placeholder="Enter your vodafone voucher here"
                ></b-form-input>
              </b-form-group>

              <b-button type="submit" variant="primary">
                <div v-if="paymentLoading">
                  <b-spinner small label="Loading..."></b-spinner>
                </div>
                <span v-else>Done</span>
              </b-button>
            </b-form>
          </div>
        </template>
      </sweet-modal>
      <sweet-modal v-if="show" blocking ref="mtnPaymentStepsModal">
        <template>
          <div>
            <b-form
              @submit.prevent="onMtnStepsDone"
              @reset.prevent="onReset"
              v-if="show"
            >
              <label class="mr-sm-2" for="inline-form-custom-select-pref"
                >Steps For MTN Payment:</label
              >
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                description="We'll never share your details with anyone else."
              >
                <strong style="display: block">1. Dial *170#</strong>
                <strong style="display: block">2. Select option 6</strong>
                <strong style="display: block">3. Select My Approvals</strong>
                <strong style="display: block">4. Confirm our payment</strong>
              </b-form-group>
              <b-button type="submit" variant="primary">
                <div v-if="paymentLoading">
                  <b-spinner small label="Loading..."></b-spinner>
                </div>
                <span v-else>Done</span>
              </b-button>
            </b-form>
          </div>
        </template>
      </sweet-modal>
      <!-- The modal ending -->
      <div class="mt-5 mb-5">
        <!-- header -->
        <div class="row pt-2">
          <div class="col-7 text-left">
            <h3>
              <b>Appointments</b>
            </h3>
          </div>
        </div>
        <div class="row mb-2 briefing text-left">
          <div v-if="upcomingCount > 0 && !loading" class="col">
            You have
            {{ upcomingCount }}
            upcoming appointment(s)
          </div>
          <!--<div
            v-else-if="upcomingCount <= 0 && !loading"
            class="col"
          >You have no upcoming appointment(s)</div>-->
        </div>
        <div class="row pt-4 mb-5">
          <div class="col-6 text-left">
            <button
              class="btn btn-sm btn-primary dropdown-toggle"
              style="border-radius: 2rem"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ apptFilter }}
            </button>
            <div
              class="dropdown-menu mb-5"
              aria-labelledby="dropdownMenuButton"
            >
              <span
                class="dropdown-item"
                style="cursor: pointer"
                @click="getAppoint('All')"
                >All</span
              >
              <span
                class="dropdown-item"
                style="cursor: pointer"
                @click="getAppoint('Confirmed')"
                >Confirmed</span
              >
              <span
                class="dropdown-item"
                style="cursor: pointer"
                @click="getAppoint('Unconfirmed')"
                >Unconfirmed</span
              >
              <span
                class="dropdown-item"
                style="cursor: pointer"
                @click="getAppoint('Expired')"
                >Expired</span
              >
            </div>
            <span
              ><button
                class="btn btn-sm btn-outline-info mx-2"
                style="border-radius: 2rem"
                type="button"
                @click="getVisits()"
              >
                My Visits
              </button></span
            >
          </div>
          <div class="col-6 text-right">
            <button
              class="btn btn-sm btn-outline-danger mr-md-2 mb-2 mb-md-0"
              style="border-radius: 2rem"
              @click="clearAllExpiredAppts"
            >
              Clear All Expired
            </button>
          </div>
        </div>

        <!-- body -->

        <div v-if="loading" class="text-center mt-5">
          <b-spinner
            variant="info"
            style="width: 3rem; height: 3rem"
            type="grow"
            label="Spinning"
          ></b-spinner>
        </div>
        <div v-else style="overflow-y: auto; height: 100vh">
          <!-- <div v-if="allAppts.results == [] || allAppts.results == ''">
          <div  class="row m-0 mt-2">
        <div class="w-100 text-center">
        <b>Sorry no appointment</b>
        </div>
      </div>
          </div>-->
          <div class="mt-2">
            <div class="row m-0" v-if="!viewVisit">
              <div
                class="col-md-6 mb-3"
                v-for="(appt, i) in filteredAppts"
                :key="i"
              >
                <AppointmentTile
                  :appt="appt"
                  class="shadow"
                  v-on:click.native="selectAppt(appt)"
                  v-b-modal="'appoint'"
                />
              </div>
            </div>
            <div class="row m-0" v-else>
              <div class="col-md-6 mb-3" v-for="(visit, i) in visits" :key="i">
                <VisitTile
                  :appt="visit"
                  :listvisit="getVisits"
                  class="shadow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppointmentTile from "@/components/AppointmentTile";
import VisitTile from "@/components/VisitTile";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import { mapState } from "vuex";
import http from "@/network/Base.js";
import env from "../../../env";

export default {
  name: "appointments",
  components: {
    AppointmentTile,
    VisitTile,
    SweetModal,
    SweetModalTab,
  },
  data() {
    return {
      apptFilter: "All",
      allAppts: [],
      visits: [],
      loading: false,
      paymentLoading: false,
      isUnconfirmed: false,
      upcomingCount: 0,
      modalShow: false,
      viewVist: false,
      isUpComing: false,
      isExpired: false,
      apptSelect: {},
      // apiUrl: "https://api.v05.serenity.health", //https://api.v05.serenity.health //https://api.v05.serenity.health
      apiUrl:
        process.env.NODE_ENV === "production"
          ? env.baseUrlProduction
          : env.baseUrlTest,
      Appts: [
        {
          sz_bookedtime: 1592533150,
          sz_bookingtype: "virtual-care",
          sz_docname: "Dadson",
          sz_paymentmethod: "mobile-money",
        },
        {
          sz_bookedtime: 1593019150,
          sz_bookingtype: "virtual-care",
          sz_docname: "Ashong",
          sz_paymentmethod: "mobile-money",
        },
      ],
      form: {
        paymentType: null,
        provider: "",
        mobileNumber: "",
        voucherForVodafone: null,
        rememberPaymentInfo: false,
      },
      paymentTypes: [
        { text: "Mobile Money", value: null },
        { text: "Cash", value: null, disabled: true },
        { text: "Visa", value: null, disabled: true },
        { text: "Corporate", value: null, disabled: true },
      ],
      show: true,
      selected: "MTN",
      options: [
        { item: "MTN", name: "MTN" },
        { item: "AIRTEL", name: "Tigo", disabled: true },
        { item: "VODAFONE", name: "Vodafone", disabled: true },
      ],
    };
  },
  watch: {
    apptFilter(val) {},
  },

  methods: {
    clearAllExpiredAppts() {},
    startVideo() {
      this.$router.push(`/video?url=${this.apptSelect.joinUrl}`);
    },
    routeAppoint() {
      this.$router.push("/appoint-bot");
    },
    goBack() {
      this.$router.push("/");
    },
    selectAppt(appt) {
      this.apptSelect = appt;
      this.$bvModal.show("appoint");
      console.log(appt);
    },
    onMtnStepsDone() {
      this.$refs.mtnPaymentStepsModal.close();
      this.$route.params.option = "";
      this.getAppoint("All");
    },
    async onSubmit(evt) {
      evt.preventDefault();
      if (
        !!!this.form.voucherForVodafone &&
        this.form.provider.toLowerCase() === "vodafone"
      ) {
        this.$refs.vodafonePaymentStepsModal.open();
      } else {
        this.$refs.vodafonePaymentStepsModal.close();
        this.paymentLoading = true;

        const payload = {
          // dob: this.apptSelect.patient.szdob,
          vendor: !!!this.form.provider ? "mtn" : this.form.provider,
          mobile:
            "233" +
            (this.form.mobileNumber[0] === "0"
              ? this.form.mobileNumber.slice(1)
              : this.form.mobileNumber),
          // sz_paymentnumber:
          //   "233" +
          //   (this.form.mobileNumber[0] === "0"
          //     ? this.form.mobileNumber.slice(1)
          //     : this.form.mobileNumber),
          service: this.apptSelect.service,
          // productid: "",
          // status: "pending",
          trans_type: "debit",
          voucher_number: this.form.voucherForVodafone
            ? this.form.voucherForVodafone
            : this.form.mobileNumber,
          charge_item: this.apptSelect.payment.charge_item_id,
          // slotid: this.apptSelect.slotid,
          // provider: "Nyaho",
          // patientName: this.apptSelect.sz_bookername,
          // patientId: this.apptSelect.sz_bookerid,
          // appointmentId: this.apptSelect.id,
        };

        try {
          // Make a request for a user with a given ID
          const response = await http().post(
            "/finance/payment/mobile-money",
            payload
          );
          if (this.form.provider.toLowerCase() === "mtn")
            this.$refs.mtnPaymentStepsModal.open();

          if (this.form.provider.toLowerCase() === "vodafone") {
            this.$route.params.option = "";
            this.getAppoint("All");
          }
          this.$snotify.info("Payment initiated");
        } catch (e) {
          console.log(e);
          this.$snotify.error("Payment failed! Could not Complete");
        } finally {
          if (this.form.provider.toLowerCase() === "mtn")
            this.$refs.mtnPaymentStepsModal.open();
          this.paymentLoading = false;
          this.$refs.modal.close();
        }
      }
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    getAppoint(bookingstatus) {
      this.viewVisit = false;
      this.apptFilter = bookingstatus;

      if (bookingstatus === "Confirmed") this.isUpComing = true;
      else this.isUpComing = false;

      if (bookingstatus === "Expired") this.isExpired = true;
      else this.isExpired = false;

      if (bookingstatus === "Unconfirmed") this.isUnconfirmed = true;
      else this.isUnconfirmed = false;

      if (
        bookingstatus == "All" ||
        bookingstatus === "Unconfirmed" ||
        bookingstatus == "Expired"
      ) {
        bookingstatus = "";
      } else {
        this.apptFilter = bookingstatus;
      }

      if (this.$route.params.option === "unconfirmed") {
        bookingstatus = "New";
        this.isUnconfirmed = true;
      } else if (this.$route.params.option === "upcoming") {
        bookingstatus = "Confirmed";
        this.isUpComing = true;
      }

      this.loading = true;
      http()
        .get(
          `/patients/appointments?sz_bookingstatus=${bookingstatus}&sz_bookedforid=${this.userInfo.szpatientid}`
        )
        .then(({ data }) => {
          this.allAppts = data;
          this.upcomingCount = data.results.filter(
            (appt) =>
              !appt.expiredStatus && appt.sz_bookingstatus == "Confirmed"
          ).length;
          if (
            this.$route.params.option != "unconfirmed" &&
            this.$route.params.option != "upcoming" &&
            this.$route.params.option &&
            data
          ) {
            console.log("here");
            this.selectAppt(
              this.allAppts.results.filter(
                (appt) => appt.id == this.$route.params.option
              )[0]
            );
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          // this.$snotify.error('Sorry, cannot get Service detail')
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getVisits() {
      this.viewVisit = true;
      this.loading = true;
      http()
        .get(`/visits/getphistory?szpatientid=${this.userInfo.szpatientid}`)
        .then(({ data }) => {
          this.visits = data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          // this.$snotify.error('Sorry, cannot get Service detail')
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showPaymentModal() {
      this.$bvModal.hide("appoint");
      this.$router.push("/express-pay");
      // this.$refs.modal.open();
    },
  },
  created() {
    if (this.$route.params.option === "unconfirmed")
      this.getAppoint("Unconfirmed");
    else if (this.$route.params.option === "upcoming")
      this.getAppoint("Confirmed");
    else this.getAppoint("All");
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
    }),
    filteredAppts() {
      if (this.isUpComing)
        return this.allAppts.results.filter((appt) => !appt.expiredStatus);
      else if (this.isExpired)
        return this.allAppts.results.filter((appt) => appt.expiredStatus);
      else if (this.isUnconfirmed)
        return this.allAppts.results.filter(
          (appt) => appt.sz_bookingstatus == "New" && !appt.expiredStatus
        );
      else
        return this.allAppts.results.filter(
          (appt) => !appt.expiredStatus || appt.expiredStatus
        );
    },
  },
};
</script>

<style scoped>
.Appointments {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.briefing {
  font-style: italic;
}
</style>
<style>
.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15) !important;
}
</style>
<style>
.sweet-modal.is-alert .sweet-content {
  text-align: left;
}
</style>
