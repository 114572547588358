<template>
  <div class="bot container">
    <form id="form-appointment" autocomplete="off"></form>
    <div id="cf-context" class="dark-theme" role="cf-context" cf-context></div>
  </div>
</template>

<script src="https://cdn.datedropper.com/get/gwt4hp3opzxjq763bez1rmuwi1156yoi"></script>
<script>
import axios from "axios";
import { mapState } from "vuex";
import moment from "moment";
import http from "@/network/Base.js";
import env from "../../env";

export default {
  data() {
    return {
      show: false,
      conversationalForm: null,
      userSessionData: {
        appointmentCost: "GHc 99",
        address: "Some location in the world",
        result: [],
        resultData: {},
        doctors: {},
        streetAddress: "",
        appointmentTypeKey: "virtual-care-gp",
        appointmentType: "Virtual Care",
        appointmentCost: "GHS 99",
      },
      specialistKeys: {
        "Family Physician": "family-medicine",
        "Emergency Doctor": "emergency-medicine",
        Dietician: "dietetics",
        "Paediatric Doctor": "paediatrics",
        "Obstetrician & Gynecologist": "obstetrics-and-gynaecology",
        "General Surgeon": "general-surgery",
        Urologist: "urology",
      },
      apiUrl:
        process.env.NODE_ENV === "production"
          ? env.baseUrlProduction
          : env.baseUrlTest,

      totalPoints: 0,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      practitionerType: "",
      greetingsTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "",
        },
        // {
        //   tag: "select",
        //   name: "helloworld",
        //   "cf-questions":
        //     "I'm Serenity and I can help you get started in minutes. Please choose the type of appointment.",
        //   children: [],
        // },
        {
          tag: "select",
          name: "startoptions",
          "cf-questions":
            "I'm Serenity and I can help you get started in minutes. When would you like the online consultation?",
          children: [
            {
              tag: "option",
              "cf-label": "Next available Date",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Choose my own Date",
              value: "",
            },
          ],
        },
      ],
      greetingsSpecialistTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "",
        },
        {
          tag: "select",
          name: "specialistType",
          "cf-questions":
            "I'm Serenity and I can help you get started in minutes. Please which type of appointmet do you want to book?",
          children: [
            {
              tag: "option",
              "cf-label": "General Surgeon",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Family Physician",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Paediatric Doctor",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Dietician",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Emergency Doctor",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Urologist",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Obstetrician & Gynecologist",
              value: "",
            },
          ],
        },
      ],
      otherTimeSlots: [
        {
          tag: "select",
          name: "otherTimeSlots",
          "cf-questions":
            "Select from the available slots a day for your appointment.",
          children: [
            {
              tag: "option",
              "cf-label": "Today",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Tomorrow",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Choose other days",
              value: "",
            },
          ],
        },
      ],
      otherTimeSlotsNoToday: [
        {
          tag: "select",
          name: "otherTimeSlots",
          "cf-questions":
            "Select from the available slots a day for your appointment.",
          children: [
            {
              tag: "option",
              "cf-label": "Tomorrow",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Choose other days",
              value: "",
            },
          ],
        },
      ],
      userExistenceTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "Splendid",
        },
        {
          tag: "select",
          name: "bookingfor",
          "cf-questions": "Are you booking for yourself or someone else",
          children: [
            {
              tag: "option",
              "cf-label": "Yes,  myself",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "No, someone else",
              value: "",
            },
          ],
        },
        {
          tag: "select",
          name: "userexistence",
          "cf-questions": "Is this your first time?",
          children: [
            {
              tag: "option",
              "cf-label": "Yes,  I’m a new user",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "No I’m an existing user",
              value: "",
            },
          ],
        },
      ],
      validateMyInfoTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "Ok, great. confirming your details",
        },
        {
          tag: "select",
          name: "validatemyinfo",
          "cf-questions":
            "You are Thomas Kensah, 26 years, mobile 0500318982. Is this correct?",
          children: [
            {
              tag: "option",
              "cf-label": "Yes",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "No",
              value: "",
            },
          ],
        },
      ],
      newUserDetailsCollectionTags: [
        {
          tag: "input",
          name: "phone",
          "cf-questions": "Kindly input the phone number for registration.",
        },
        {
          tag: "select",
          name: "gender",
          "cf-questions": "Please select your gender",
          children: [
            {
              tag: "option",
              "cf-label": "Male",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Female",
              value: "",
            },
          ],
        },
        {
          tag: "input",
          name: "fullname",
          "cf-questions": "Kindly give me your full name.",
        },
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "Kindly share your Date of Birth with me.",
        },
        {
          tag: "input",
          name: "dateofbirth",
          "cf-input-placeholder": "dd/mm/yyyy",
          "cf-questions": "<strong>eg. 26/09/1993</strong>",
        },
      ],
      existingUserDetailsCollectionTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "Lets get you verified",
        },
        {
          tag: "input",
          name: "mobile",
          "cf-questions":
            "Please enter the phone number you used for registration.",
        },
      ],
      welcomeBackMessageTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": ``,
        },
        {
          tag: "select",
          name: "condition",
          "cf-questions": "What brings you in?",
          children: [
            {
              tag: "option",
              "cf-label": "Abdominal pain",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Rash",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Diarrhoea",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Other",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Corona Virus Concerns",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "None",
              value: "",
            },
          ],
        },
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": ``,
        },
        {
          tag: "select",
          name: "proceedwithappointment",
          "cf-questions":
            "Select Proceed to book an appointment or cancel to cancel the appointment.",
          children: [
            {
              tag: "option",
              "cf-label": "Proceed",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Cancel",
              value: "",
            },
          ],
        },
      ],
      verifyLoginCodeInputTags: [
        {
          tag: "input",
          name: "verifylogincode",
          "cf-questions":
            "Please verify your number by entering the six digit code that has been sent to your phone.",
        },
      ],
      paymentSelectionTags: [
        {
          tag: "select",
          name: "paymentselection",
          "cf-questions": "",
          children: [
            // {
            //   tag: "option",
            //   "cf-label": "Mobile Money",
            //   value: "",
            // },
            // {
            //   tag: "option",
            //   "cf-label": "Bill my company",
            //   value: "",
            // },
            {
              tag: "option",
              "cf-label": "expressPay",
              value: "",
            },
          ],
        },
      ],
      cancelTags: [
        {
          tag: "select",
          name: "cancel",
          "cf-questions": "Would you like to end the session or go back?",
          children: [
            {
              tag: "option",
              "cf-label": "End the session",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Return to back to the start",
              value: "",
            },
          ],
        },
      ],
      mobileMoneyProviderSelectionTags: [
        {
          tag: "select",
          name: "mobilemoneyproviderselection",
          "cf-questions": "Please select a payment provider",
          children: [
            {
              tag: "option",
              "cf-label": "MTN",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Airtel Tigo",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Vodafone",
              value: "",
            },
          ],
        },
      ],
      mobileMoneyNumberInputTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "",
        },
        {
          tag: "input",
          name: "mobilemoneynumber",
          "cf-questions":
            "Please enter the {mobilemoneyproviderselection} Mobile money number",
        },
      ],
      vodafoneVoucherInputTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": `Please dial *110# and select option 4 then select Generate voucher to generate a voucher for this payment.`,
        },
        {
          tag: "input",
          name: "voucher",
          "cf-questions": "Enter your generated voucher here",
        },
      ],
      finalSessionMTNMessageTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "",
        },
        {
          tag: "select",
          name: "finalsessionmessage",
          "cf-questions":
            "Please dial *170# and select options 6 then 3 to authorise payment. Thank you.",
          children: [
            {
              tag: "option",
              "cf-label": "Book another appointment",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Check my bill for this appointment",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Done",
              value: "",
            },
            // {
            //   tag: "option",
            //   "cf-label": "End Session",
            //   value: ""
            // }
          ],
        },
      ],
      finalSessionMessageTags: [
        {
          tag: "select",
          name: "finalsessionmessage",
          "cf-questions": "",
          children: [
            {
              tag: "option",
              "cf-label": "Book another appointment",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Check my bill for this appointment",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Proceed with payment",
              value: "",
            },
            {
              tag: "option",
              "cf-label": "Done",
              value: "",
            },
          ],
        },
      ],
      forSpecialist: false,
      listTiers: [],
      userName: "",
    };
  },
  created() {
    // this.greetingsTags[1].children = this.$store.state.serviceStore.listGPtiers;
    this.listTiers = this.$store.state.serviceStore.listGP;
    // this.listService = this.$store.state.serviceStore.specialties;

    this.userName = this.userInfo.szfirstname
      ? this.userInfo.szfirstname
      : "Dear";
  },
  methods: {
    setupForm() {},
    startInfo() {},
    async flowCallback(dto, success, error) {
      // if (dto.tag.domElement.name === "helloworld") {
      //   this.userSessionData.display = dto.tag.value[0];
      //   console.log(this.userSessionData.display);
      //   // console.log(this.listTiers);
      //   this.listTiers.forEach((el) => {
      //     if (el.display == this.userSessionData.display) {
      //       this.userSessionData.serviceValue = el;
      //       // console.log(el);
      //     }
      //   });
      //   this.userSessionData.practitionerName = dto.text;
      // }
      this.userSessionData.serviceValue = this.listTiers.health_service_price_tiers[0];
      console.log(this.userSessionData.serviceValue);

      if (dto.tag.domElement.name === "startoptions") {
        if (dto.text === "Next available Date") {
          // TODO: Make a network call: Get next Available time slot
          this.conversationalForm.addRobotChatResponse(
            "Fetching next available time slot..."
          );
          let payload = {
            service: this.userSessionData.serviceValue.health_service,
          };
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patient/slots/nextavailable",
              payload
            );

            this.Timeslots = response.data;
            let timing = [];

            this.Timeslots.forEach((el) => {
              const time = this.$moment(el.startDateTime).format("LT");
              // const date = el.startDateTime.split(" ")[0];
              // console.log(time);
              const date = this.$moment(el.startDateTime).calendar();
              // TODO: Update this tag with api response
              // const parsedDate = new Date(date);
              el.timing = date;
              timing.push({
                tag: "option",
                "cf-label": date,
                value: date,
              });
            });
            const tags = [
              {
                tag: "cf-robot-message",
                name: "message",
                "cf-questions": "These are the available time slots",
              },
              {
                tag: "select",
                name: "nextavailabletimeslot",
                "cf-questions":
                  "Select the available time slot to proceed or select a different date",
                children: timing,
              },
            ];

            this.conversationalForm.addTags(tags, true);
            console.log(
              "logging axios..(success)",
              this.userSessionData.appointmentDateTime
            );
            console.log("logging axios..(success)", response);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "slotsfailed",
                "cf-questions":
                  "Sorry, I could not fetch the available time slots.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Pick other dates",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
        }
        if (dto.text === "Choose my own Date") {
          // add choose your own date tags
          const date = new Date();
          if (date.getHours() > 22)
            this.conversationalForm.addTags(this.otherTimeSlotsNoToday, true);
          if (date.getHours() <= 22)
            this.conversationalForm.addTags(this.otherTimeSlots, true);
        }
      }
      if (dto.tag.domElement.name === "nextavailabletimeslot") {
        this.Timeslots.forEach((el) => {
          if (el.timing == dto.text) {
            this.userSessionData.slotid = el.slotid;
            this.userSessionData.appointmentDateTime = el.startDateTime;
            this.userSessionData.selectedTime = el.timing;
            this.userSessionData.resultData = el;
            console.log(this.userSessionData.resultData.doctorName);
          }
        });
      }
      if (dto.tag.domElement.name === "slotsfailed") {
        if (dto.text === "Try again") {
          // TODO: Make a network call: Get next Available time slot
          this.conversationalForm.addRobotChatResponse(
            "Fetching next available time slot..."
          );
          let payload = {
            service: this.userSessionData.serviceValue.health_service,
          };
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patient/slots/nextavailable",
              payload
            );
            console.log(response.data);
            let timing = [];

            response.data.forEach((el) => {
              const time = this.$moment(el.startDateTime).format("LT");
              console.log(time);
              // const date = el.startDateTime.split(" ")[0];
              const date = this.$moment(el.startDateTime).calendar();
              // TODO: Update this tag with api response
              // const parsedDate = new Date(date);
              timing.push({
                tag: "option",
                "cf-label": date,
                value: date,
              });
            });
            console.log(timing);
            const tags = [
              {
                tag: "cf-robot-message",
                name: "message",
                "cf-questions": "These are the available time slots",
              },
              {
                tag: "select",
                name: "nextavailabletimeslot",
                "cf-questions":
                  "Select the available time slot to proceed or select a different date",
                children: timing,
              },
            ];

            console.log(
              "logging axios..(success)",
              this.userSessionData.appointmentDateTime
            );
            console.log("logging axios..(success)", response);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "slotsfailed",
                "cf-questions":
                  "Sorry, I could not fetch the available time slots.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Pick other dates",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
        }
        if (dto.text === "Pick other dates") {
          // add choose your own date tags
          const date = new Date();
          if (date.getHours() > 22)
            this.conversationalForm.addTags(this.otherTimeSlotsNoToday, true);
          if (date.getHours() <= 22)
            this.conversationalForm.addTags(this.otherTimeSlots, true);
        }
        if (dto.text === "Cancel") {
          this.$router.push("/");
        }
      }
      if (dto.tag.domElement.name === "otherTimeSlots") {
        this.userSessionData.day = dto.text;

        const date = new Date();
        let currentDate =
          date.getFullYear() +
          "-" +
          (parseInt(date.getMonth() + 1) < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) +
          "-" +
          date.getDate() +
          " " +
          "00:00";
        const tommorrow = new Date(date.setDate(date.getDate() + 1));
        const tomorrowDate =
          tommorrow.getFullYear() +
          "-" +
          (parseInt(tommorrow.getMonth() + 1) < 10
            ? "0" + (tommorrow.getMonth() + 1)
            : tommorrow.getMonth() + 1) +
          "-" +
          tommorrow.getDate() +
          " " +
          "00:00";
        const nextDate = new Date(date.setDate(date.getDate() + 1));
        const nextDayDate =
          nextDate.getFullYear() +
          "-" +
          (parseInt(nextDate.getMonth() + 1) < 10
            ? "0" + (nextDate.getMonth() + 1)
            : nextDate.getMonth() + 1) +
          "-" +
          nextDate.getDate() +
          " " +
          "00:00";
        let payload = {
          startDateTime:
            currentDate.split(" ")[0] +
            " " +
            date.getHours() +
            ":" +
            date.getMinutes(),
          endDateTime: tomorrowDate,
          // add specialist Id here,
          service: this.userSessionData.serviceValue.health_service,
          practitioner_id: this.userSessionData.practitionerId,
        };
        if (dto.text === "Today") {
          this.conversationalForm.addRobotChatResponse(
            "Fetching available time slots..."
          );
          // TODO: Make a network call: Get Available time slots for today and then add time selections tags
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patient/slots/available",
              payload
            );
            const availableTimeSlots = [
              {
                tag: "select",
                name: "availabletimeslots",
                "cf-questions": "The available time slots for today are:",
                children: [],
              },
            ];
            this.Timeslots = response.data;

            this.userSessionData.result = [];
            for (let item of this.Timeslots) {
              const time = item.startDateTime.split("T")[1];
              const parsedTime =
                time.split(":")[0] +
                ":" +
                time.split(":")[1] +
                (parseInt(time.split(":")[0]) > 11 ? "pm" : "am");
              if (!this.userSessionData.result.includes(parsedTime)) {
                this.userSessionData.result.push(parsedTime);
                availableTimeSlots[0].children.push({
                  tag: "option",
                  "cf-label": parsedTime,
                  value: "",
                });
                this.userSessionData.resultData[parsedTime] = item;
              }
            }

            // handle success
            if (this.Timeslots.length > 0)
              this.conversationalForm.addTags(availableTimeSlots, true);
            if (this.Timeslots.length === 0) {
              const tags = [
                {
                  tag: "select",
                  name: "otherslotsfailed",
                  "cf-questions": "Sorry, There are no slots.",
                  children: [
                    {
                      tag: "option",
                      "cf-label": "Try again",
                      value: "",
                    },
                    {
                      tag: "option",
                      "cf-label": "Pick other dates",
                      value: "",
                    },
                    {
                      tag: "option",
                      "cf-label": "Cancel",
                      value: "",
                    },
                  ],
                },
              ];
              this.conversationalForm.addTags(tags, true);
            }
            console.log("logging axios..(success)", response);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "otherslotsfailed",
                "cf-questions": "Sorry, I could not get a service response.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Pick other dates",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
          while (currentDate.includes("-"))
            currentDate = currentDate.replace("-", "/");
          this.userSessionData.appointmentDateTime = currentDate.split(" ")[0];
        }
        payload = {
          startDateTime: tomorrowDate,
          endDateTime: nextDayDate,
          // add specialist Id here,
          service: this.userSessionData.serviceValue.health_service,
          practitioner_id: this.userSessionData.practitionerId,
        };
        if (dto.text === "Tomorrow") {
          this.conversationalForm.addRobotChatResponse(
            "Fetching available time slots..."
          );
          // TODO: Make a network call: Get Available time slots for tomorrow and then add time selections tags
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patient/slots/available",
              payload
            );
            const availableTimeSlots = [
              {
                tag: "select",
                name: "availabletimeslots",
                "cf-questions": "The available time slots for tomorrow are:",
                children: [],
              },
            ];
            this.Timeslots = response.data;

            this.userSessionData.result = [];
            for (let item of response.data) {
              const time = item.startDateTime.split("T")[1];
              const parsedTime =
                time.split(":")[0] +
                ":" +
                time.split(":")[1] +
                (parseInt(time.split(":")[0]) > 11 ? "pm" : "am");
              if (!this.userSessionData.result.includes(parsedTime)) {
                this.userSessionData.result.push(parsedTime);
                availableTimeSlots[0].children.push({
                  tag: "option",
                  "cf-label": parsedTime,
                  value: "",
                });
                this.userSessionData.resultData[parsedTime] = item;
              }
            }

            // handle success
            if (response.data.length > 0)
              this.conversationalForm.addTags(availableTimeSlots, true);
            if (response.data.length === 0) {
              const tags = [
                {
                  tag: "select",
                  name: "otherslotsfailed",
                  "cf-questions":
                    "Sorry, It looks like all the available slots for tomorrow have been booked. There are no available slots.",
                  children: [
                    {
                      tag: "option",
                      "cf-label": "Try again",
                      value: "",
                    },
                    {
                      tag: "option",
                      "cf-label": "Pick other dates",
                      value: "",
                    },
                    {
                      tag: "option",
                      "cf-label": "Cancel",
                      value: "",
                    },
                  ],
                },
              ];
              this.conversationalForm.addTags(tags, true);
            }
            console.log("logging axios..(success)", response);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            this.conversationalForm.addRobotChatResponse("");
            const tags = [
              {
                tag: "select",
                name: "otherslotsfailed",
                "cf-questions":
                  "Sorry, I could not fetch the available time slots for this date.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Pick other dates",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
          this.userSessionData.appointmentDateTime = tomorrowDate.split(" ")[0];
        }
        if (dto.text === "Choose other days") {
          // open date selection popup
          const tags = [
            {
              tag: "input",
              name: "selectOtherDate",
              "cf-input-placeholder": "dd/mm/yyyy",
              "cf-questions": "<strong>eg. 24/07/2020</strong>",
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
      }
      if (dto.tag.domElement.name === "selectOtherDate") {
        let start = dto.text
          .replace(/[/]/gi, "-")
          .split("-")
          .reverse()
          .join("-");
        let realDate = moment(start).format("Do MMM, YYYY");
        console.log(start);
        let payload = {
          startDateTime: start + " " + "00:00",
          endDateTime: start + " " + "23:59",
          // add specialist Id here,
          service: this.userSessionData.serviceValue.health_service,
          // practitioner_id: this.userSessionData.practitionerId,
        };
        this.conversationalForm.addRobotChatResponse(
          "Fetching available time slots..."
        );
        // TODO: Make a network call: Get Available time slots for tomorrow and then add time selections tags
        try {
          // Make a request for a user with a given ID
          const response = await axios.post(
            this.apiUrl + "/patient/slots/available",
            payload
          );
          const availableTimeSlots = [
            {
              tag: "select",
              name: "availabletimeslots",
              "cf-questions": `The available time slots for ${realDate} are:`,
              children: [],
            },
          ];
          this.Timeslots = response.data;
          this.userSessionData.result = [];
          for (let item of response.data) {
            const time = item.startDateTime.split("T")[1];
            const parsedTime =
              time.split(":")[0] +
              ":" +
              time.split(":")[1] +
              (parseInt(time.split(":")[0]) > 11 ? "pm" : "am");
            if (!this.userSessionData.result.includes(parsedTime)) {
              this.userSessionData.result.push(parsedTime);
              availableTimeSlots[0].children.push({
                tag: "option",
                "cf-label": parsedTime,
                value: "",
              });
              this.userSessionData.resultData[parsedTime] = item;
            }
          }

          // handle success
          if (response.data.length > 0)
            this.conversationalForm.addTags(availableTimeSlots, true);
          if (response.data.length === 0) {
            const tags = [
              {
                tag: "select",
                name: "otherslotsfailed",
                "cf-questions":
                  "Sorry, It looks like all the available slots for tomorrow have been booked. There are no available slots.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Pick other dates",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
          console.log("logging axios..(success)", response);
        } catch (e) {
          // handle success
          console.log("logging axios..(error)", e);
          this.conversationalForm.addRobotChatResponse("");
          const tags = [
            {
              tag: "select",
              name: "otherslotsfailed",
              "cf-questions":
                "Sorry, I could not fetch the available time slots for this date.",
              children: [
                {
                  tag: "option",
                  "cf-label": "Try again",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Pick other dates",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Cancel",
                  value: "",
                },
              ],
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
        this.userSessionData.appointmentDateTime = start;
      }

      if (dto.tag.domElement.name === "otherslotsfailed") {
        if (dto.text === "Try again") {
          const tags = [
            {
              tag: "input",
              name: "selectOtherDate",
              "cf-input-placeholder": "dd/mm/yyyy",
              "cf-questions": "<strong>eg. 24/07/2020</strong>",
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
        if (dto.text === "Pick other dates") {
          const tags = [
            {
              tag: "input",
              name: "selectOtherDate",
              "cf-input-placeholder": "dd/mm/yyyy",
              "cf-questions": "<strong>eg. 24/07/2020</strong>",
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
        if (dto.text === "Cancel") {
          this.$router.push("/");
        }
      }
      //   if (dto.tag.domElement.name === "selectOtherDate") {
      //     if (!document.querySelector('input[name="datepicker"]')) {
      //       var input = document.createElement("input"); // Create a <button> element
      //       input.name = "datepicker";
      //       input.setAttribute("data-modal", "true");
      //       input.setAttribute("data-large-default", "true");
      //       input.setAttribute("data-large-mode", "true");
      //       input.setAttribute("data-theme", "s10");
      //       input.setAttribute("data-init-set", "false");
      //       input.setAttribute("placeholder", event.detail.tag._inputPlaceholder);
      //       if (event.detail.tag.defaultValue != "") {
      //         input.setAttribute(
      //           "data-default-date",
      //           event.detail.tag.defaultValue
      //         );
      //         input.value = event.detail.tag.defaultValue;
      //       }
      //       document.querySelector("cf-input").appendChild(input); // We append it to the bottom of CF
      //       $('input[name="datepicker"]').dateDropper(); // init the datedropper

      //       // Setup listener to pipe date to our CF textarea
      //       $('input[name="datepicker"]').change(function (e) {
      //         $("cf-input textarea").val($(e.target).val());
      //       });
      //       document.querySelector("cf-input textarea").style.display = "none"; // We hide the default textarea
      // } else if (document.querySelector('input[name="datepicker"]')) {
      //   // reset/remove temp input field
      //   var elem = document.querySelector('input[name="datepicker"]');
      //   elem.parentNode.removeChild(elem); // Remove the temporary input field
      //   document.querySelector("cf-input textarea").style.display = "block"; // We show the default textarea again
      // }
      //   }
      if (dto.tag.domElement.name === "availabletimeslots") {
        this.Timeslots.forEach((el) => {
          const time = el.startDateTime.split("T")[1];
          const parsedTime =
            time.split(":")[0] +
            ":" +
            time.split(":")[1] +
            (parseInt(time.split(":")[0]) > 11 ? "pm" : "am");
          if (parsedTime == dto.text) {
            console.log(parsedTime, "eh", dto.text);
            this.userSessionData.slotid = el.slotid;
            this.userSessionData.appointmentDateTime +=
              " " + dto.text.slice(0, 5);
            this.userSessionData.selectedTime = dto.text;
            this.userSessionData.resultData = el;
          } else {
            console.log(parsedTime, "noooo", dto.text);
          }
        });
        this.conversationalForm.addTags(this.userExistenceTags, true);
      }
      if (dto.tag.domElement.name === "nextavailabletimeslot") {
        if (dto.text === "Pick other dates") {
          // add choose your own date tags
          const date = new Date();
          if (date.getHours() > 22)
            this.conversationalForm.addTags(this.otherTimeSlotsNoToday, true);
          if (date.getHours() <= 22)
            this.conversationalForm.addTags(this.otherTimeSlots, true);
        } else this.conversationalForm.addTags(this.userExistenceTags, true);
      }
      if (dto.tag.domElement.name === "bookingfor") {
        if (dto.text === "Yes,  myself") {
          if (this.loggedIn) {
            this.userSessionData.fullName =
              this.userInfo.szfirstname + " " + this.userInfo.szlastname;
            this.userSessionData.userId = this.userInfo.sz_userid;
            this.userSessionData.mobile = this.userInfo.sz_phoneno;
            this.userSessionData.dateOfBirth = this.userInfo.szdob;

            if (!!this.sz_companyid) {
              this.userSessionData.hasCorporate = true;
              this.userSessionData.companyId = this.userInfo.sz_companyid;
            }
            if (!!this.sz_companyname)
              this.userSessionData.companyName = this.userInfo.sz_companyname;

            this.validateMyInfoTags[1]["cf-questions"] = `You are ${
              this.userSessionData.fullName
            }, ${
              this.$moment().diff(this.userSessionData.dateOfBirth, 'years', false)
            } years, mobile ${this.userSessionData.mobile}. Is this correct?`;
            this.conversationalForm.addTags(this.validateMyInfoTags, true);
          } else this.userSessionData.isBookingForSelf = true;
        } else if ("No, someone else") {
        }
      }
      if (dto.tag.domElement.name === "validatemyinfo") {
        if (dto.text === "Yes" && this.loggedIn) {
          const tags = [
            {
              tag: "select",
              name: "proceedwithappointment",
              "cf-questions": `Please verify your appointment date and time. You opted for ${this.userSessionData.selectedTime}`,
              children: [
                {
                  tag: "option",
                  "cf-label": "Proceed",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Cancel",
                  value: "",
                },
              ],
            },
          ];
          this.conversationalForm.addTags(tags, true);
        } else if (dto.text === "No") this.$router.push({ path: "/" });
      }
      if (dto.tag.domElement.name === "userexistence") {
        if (dto.text === "Yes,  I’m a new user") {
          this.conversationalForm.addTags(
            this.newUserDetailsCollectionTags,
            true
          );
          this.userSessionData.isUserNew = true;
        }
        if (dto.text === "No I’m an existing user") {
          this.conversationalForm.addTags(
            this.existingUserDetailsCollectionTags,
            true
          );
          this.userSessionData.isUserNew = false;
        }
      }

      // Existing User Authentication steps
      // (1)
      if (dto.tag.domElement.name === "mobile") {
        this.userSessionData.mobile = dto.text;

        // post
        const payload = {
          sz_firstname: "",
          sz_email: "",
          sz_password: "",
          sz_lastname: "",
          sz_dob: "",
          sz_gender: "",
          sz_phoneno: this.userSessionData.mobile,
          sz_marital_status: "",
          sz_nationality: "",
          sz_native_language: "",
          sz_religion: "",
          sz_occupation: "",
          sz_employer: "",
          sz_ice_name: "",
          sz_ice_place_of_work: "",
          sz_ice_address: "",
          sz_ice_phoneno: "",
          sz_bookedfortime: "",
          sz_bookername: "",
          sz_paymentmethod: "",
          sz_complaint: "suspected-covid-19",
          sz_docempid: "",
          sz_docname: "",
          sz_unitid: "",
          sz_othername: "",
          sz_address: "",
          sz_title: "",
          phone: this.userSessionData.mobile,
        };

        // verify account
        this.conversationalForm.addRobotChatResponse("Verifying...");
        try {
          // Make a request for a user with a given ID
          let response = await axios.post(
            this.apiUrl + "/patient/checkexistence",
            payload
          );

          const patientResponse = await axios.post(
            this.apiUrl + "/patient/get",
            {
              szpatientid: response.data.sz_userid,
            }
          );
          // handle success
          console.log("logging axios..(success)", response);
          this.userSessionData.userData = patientResponse.data;
          this.userSessionData.fullName =
            patientResponse.data.szfirstname +
            " " +
            patientResponse.data.szlastname;
          this.userSessionData.userId = response.data.sz_userid;
          this.userSessionData.userData.sz_userid = response.data.sz_userid;
          if (!!response.data.sz_companyid) {
            this.userSessionData.hasCorporate = true;
            this.userSessionData.companyId = response.data.sz_companyid;
          }
          if (!!response.data.sz_companyname)
            this.userSessionData.companyName = response.data.sz_companyname;

          // Send code
          response = await axios.post(
            this.apiUrl + "/user/sendotpcode",
            payload
          );
          // handle success
          console.log("logging axios..(success)", response);
          this.userSessionData.code = response.data.code;
          this.conversationalForm.addTags(this.verifyLoginCodeInputTags, true);
        } catch (e) {
          // handle success
          console.log("logging axios..(error)", e);
          this.conversationalForm.addRobotChatResponse("");
          // Try an existing account
          const tags = [
            {
              tag: "select",
              name: "tryexisting",
              "cf-questions":
                "Your account could not be verified. If you are a new user please sign up by clicking on the sign up button at the top of this page before booking for a doctor",
              children: [
                {
                  tag: "option",
                  "cf-label": "Try again",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Register a new account",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Cancel",
                  value: "",
                },
              ],
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
      }
      if (dto.tag.domElement.name === "tryexisting") {
        if (
          dto.text === "Try logging into an existing account" ||
          dto.text === "Try again"
        )
          this.conversationalForm.addTags(
            this.existingUserDetailsCollectionTags,
            true
          );
        if (dto.text === "Register a new account") {
          this.conversationalForm.addTags(
            this.newUserDetailsCollectionTags,
            true
          );
          this.userSessionData.isUserNew = true;
        }
        if (dto.text === "Cancel") closeForm();
      }
      if (dto.tag.domElement.name === "verifylogincode") {
        if (dto.text == this.userSessionData.code) {
          if (this.userSessionData.isBookingForSelf) {
            this.$store.commit("authStore/setLoggedIn", true);
            this.$store.commit(
              "authStore/setUserInfo",
              this.userSessionData.userData
            );
            this.$store.commit(
              "authStore/setId",
              this.userSessionData.userData.id
            );
          }
          this.welcomeBackMessageTags[0][
            "cf-questions"
          ] = `Welcome back ${this.userSessionData.fullName}`;
          this.welcomeBackMessageTags[2][
            "cf-questions"
          ] = `Please verify your appointment date and time. You opted for ${
            this.userSessionData.appointmentDateTime.split(" ")[0]
          } at ${this.userSessionData.appointmentDateTime.split(" ")[1]}`;
          this.conversationalForm.addTags(this.welcomeBackMessageTags, true);
        } else {
          this.conversationalForm.addRobotChatResponse(
            "Wrong input. Try again."
          );
          this.conversationalForm.addTags(this.verifyLoginCodeInputTags, true);
        }
      }
      // Eight interaction: Payment
      if (dto.tag.domElement.name === "proceedwithappointment") {
        if (dto.text === "Proceed") {
          if (this.userSessionData.hasCorporate) {
            this.paymentSelectionTags[0].children.push({
              tag: "option",
              "cf-label": "Bill my company",
              value: "",
            });
          }
          this.paymentSelectionTags[0][
            "cf-questions"
          ] = `Your total fee for the ${this.userSessionData.appointmentType} is <a class="text-success" href="#"> GHS ${this.userSessionData.serviceValue.charge}</a>. How would you like to pay`;
          this.conversationalForm.addTags(this.paymentSelectionTags, true);
        }
        if (dto.text === "Cancel")
          this.conversationalForm.addTags(this.cancelTags, true); // TODO: exit
      }
      // Nineth interaction: Payment -> select payment method
      if (dto.tag.domElement.name === "paymentselection") {
        if (dto.text === "Mobile Money" || dto.text === "Try Mobile Money") {
          this.conversationalForm.addTags(
            this.mobileMoneyProviderSelectionTags,
            true
          );
          this.userSessionData.paymentMethod = "mobile-money";
        }
        if (dto.text === "Visa")
          this.conversationalForm.addTags(noVisaTags, true); // TODO: exit
        if (dto.text === "expressPay") {
          this.userSessionData.paymentMethod = "cash";
          // book slot:
          this.conversationalForm.addRobotChatResponse(
            "Booking your appointment..."
          );
          // const slotInfo = this.userSessionData.resultData[
          //   this.userSessionData.selectedTime
          // ];
          // this.userSessionData.serviceValue.id = slotInfo.slotid;

          let payload = {
            slotid: this.userSessionData.slotid,
            patientName: this.userSessionData.fullName,
            mobile: this.userSessionData.mobile,
            email: this.userSessionData.mobile + "@Nhyahohealth.com",
            patientId: this.userSessionData.userId,
            sz_bookerid: this.userSessionData.userId,
            sz_bookername: this.userSessionData.fullName,
            sz_bookedforid: this.userSessionData.userId,
            sz_docempid: this.userSessionData.resultData.assignedDoctorId,
            sz_docname: this.userSessionData.resultData.doctorName,
            sz_bookedtime: this.userSessionData.appointmentDateTime,
            sz_location: this.userSessionData.streetAddress,
            sz_payerid: this.userSessionData.userId,
            payername: "self",
            service: this.userSessionData.serviceValue.health_service
              ? this.userSessionData.serviceValue.health_service
              : "",
            tier: this.userSessionData.serviceValue.id
              ? this.userSessionData.serviceValue.id
              : "",
            sz_bookingnote: "",
            sz_bookingstatus: "",
            sz_bookingmode: "Online",
            sz_hospid: "",
            sz_unitid: "",
            sz_paymentmethod: this.userSessionData.paymentMethod,
            sz_bookedforname: this.userSessionData.fullName,
            sz_complaint: "",
            assignedDoctorId: this.userSessionData.resultData.assignedDoctorId,
            sz_bookingtype: this.userSessionData.appointmentTypeKey,
            sz_bookedfortime: this.userSessionData.appointmentDateTime,
          };
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patients/bookappointment",
              payload
            );

            // handle success
            console.log("logging axios..(success)", response);
            this.finalSessionMessageTags[0][
              "cf-questions"
            ] = `Thanks for having me, ${this.userSessionData.fullName}. Your payment has been initiated, once your payment succeeds. You will receive an SMS and Email to confirm your appointment booking.`;
            this.conversationalForm.addTags(this.finalSessionMessageTags, true);
            console.log("logging axios..(success)", "response");
          } catch (e) {
            // handle error
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "timeselectionfailed",
                "cf-questions":
                  "Sorry, something went wrong and I could not select this slot.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Book a new appointment",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
        }
        if (dto.text === "Bill my company") {
          const tags = [
            {
              tag: "select",
              name: "insurancer",
              "cf-questions": `Please select the company you would like to pay for this service`,
              children: [
                {
                  tag: "option",
                  "cf-label": "Toasted Eggs",
                  value: "",
                },
              ],
            },
          ];
          this.conversationalForm.addTags(tags, true);
          this.userSessionData.paymentMethod = "corporate";
        }
        if (dto.text === "I don't have mobile money")
          this.conversationalForm.addRobotChatResponse(
            "Sorry, You will need a payment option to proceed. Thank you."
          );
      }
      if (dto.tag.domElement.name === "insurancer") {
        if (dto.text === "Toasted Eggs") {
          const tags = [
            {
              tag: "select",
              name: "insuranceprovider",
              "cf-questions": `Toasted Eggs will be charged for your virtual care. would you like to proceed?`,
              children: [
                {
                  tag: "option",
                  "cf-label": "Yes, proceed",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "No, cancel",
                  value: "",
                },
              ],
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
      }
      if (dto.tag.domElement.name === "insuranceprovider") {
        if (dto.text === "Yes, proceed") {
          this.conversationalForm.addRobotChatResponse(
            "Booking your appointment..."
          );
          this.conversationalForm.addRobotChatResponse(
            "Billing Toasted Eggs..."
          );
          this.finalSessionMessageTags[0][
            "cf-questions"
          ] = `Thanks for having me, ${this.userSessionData.fullName}. You will receive an SMS and Email to confirm your appointment booking.`;
          this.conversationalForm.addTags(this.finalSessionMessageTags, true);
        } else this.conversationalForm.addTags(this.paymentSelectionTags, true);
      }
      if (dto.tag.domElement.name === "mobilemoneynumber") {
        this.userSessionData.momonumber = dto.text;
      }
      // Tenth interaction: Payment -> Mobile Money -> select provider
      if (dto.tag.domElement.name === "mobilemoneyproviderselection") {
        if (dto.text === "MTN") this.userSessionData.provider = "mtn";
        if (dto.text === "Airtel Tigo")
          this.userSessionData.provider = "airtel";
        if (dto.text === "Vodafone") this.userSessionData.provider = "vodafone";
        this.mobileMoneyNumberInputTags[0]["cf-questions"] =
          "A charge for " +
          this.userSessionData.appointmentType +
          " will be charged to your account";
        this.conversationalForm.addTags(this.mobileMoneyNumberInputTags, true);
      }
      if (
        dto.tag.domElement.name === "mobilemoneynumber" &&
        this.userSessionData.provider != "vodafone"
      ) {
        // book slot:
        this.conversationalForm.addRobotChatResponse(
          "Booking your appointment..."
        );
        // const slotInfo = this.userSessionData.resultData[
        //   this.userSessionData.selectedTime
        // ];
        // this.userSessionData.serviceValue.id = slotInfo.slotid;

        let payload = {
          slotid: this.userSessionData.slotid,
          patientName: this.userSessionData.fullName,
          mobile: this.userSessionData.mobile,
          email: this.userSessionData.mobile + "@Nhyahohealth.com",
          patientId: this.userSessionData.userId,
          sz_bookerid: this.userSessionData.userId,
          sz_bookername: this.userSessionData.fullName,
          sz_bookedforid: this.userSessionData.userId,
          sz_docempid: this.userSessionData.resultData.assignedDoctorId,
          sz_docname: this.userSessionData.resultData.doctorName,
          sz_bookedtime: this.userSessionData.appointmentDateTime,
          sz_location: this.userSessionData.streetAddress,
          sz_payerid: this.userSessionData.userId,
          payername: "self",
          service: this.userSessionData.serviceValue.health_service
            ? this.userSessionData.serviceValue.health_service
            : "",
          tier: this.userSessionData.serviceValue.id
            ? this.userSessionData.serviceValue.id
            : "",
          sz_bookingnote: "",
          sz_bookingstatus: "",
          sz_bookingmode: "Online",
          sz_hospid: "",
          sz_unitid: "",
          sz_paymentmethod: this.userSessionData.paymentMethod,
          sz_bookedforname: this.userSessionData.fullName,
          sz_complaint: "",
          assignedDoctorId: this.userSessionData.resultData.assignedDoctorId,
          sz_bookingtype: this.userSessionData.appointmentTypeKey,
          sz_bookedfortime: this.userSessionData.appointmentDateTime,
        };
        try {
          // Make a request for a user with a given ID
          const response = await axios.post(
            this.apiUrl + "/patients/bookappointment",
            payload
          );

          // handle success
          this.userSessionData.patientId = response.data.sz_bookedforid;
          this.userSessionData.appointmentId = response.data.id;
          this.userSessionData.chargeItem = response.data.charge_item_id;

          this.userSessionData.voucher = dto.text;
          //POST: api
          payload = {
            vendor: this.userSessionData.provider,
            mobile:
              "233" +
              (this.userSessionData.momonumber[0] === "0"
                ? this.userSessionData.momonumber.slice(1)
                : this.userSessionData.momonumber),
            // sz_paymentnumber:
            //   "233" +
            //   (this.userSessionData.momonumber[0] === "0"
            //     ? this.userSessionData.momonumber.slice(1)
            //     : this.userSessionData.momonumber),
            service: this.userSessionData.serviceValue.health_service,
            // productid: "",
            // status: "pending",
            trans_type: "debit",
            voucher_number: this.userSessionData.voucher,
            charge_item: this.userSessionData.chargeItem,
            // slotid: this.userSessionData.serviceValue.id,
            // provider: "Nyaho",
            // patientName: this.userSessionData.fullName,
            // patientId: this.userSessionData.patientId,
            // appointmentId: this.userSessionData.appointmentId,
          };

          // call payment api
          this.conversationalForm.addRobotChatResponse("Verifying payment...");
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/finance/payment/mobile-money",
              payload
            );

            // handle success
            console.log("logging axios..(success)", response);
            this.finalSessionMessageTags[0][
              "cf-questions"
            ] = `Thanks for having me, ${this.userSessionData.fullName}. Your payment has been initiated, once your payment succeeds. You will receive an SMS and Email to confirm your appointment booking.`;
            this.finalSessionMTNMessageTags[0][
              "cf-questions"
            ] = `Thanks for having me, ${this.userSessionData.fullName}. Your payment has been initiated, once your payment succeeds. You will receive an SMS and Email to confirm your appointment booking.`;

            if (this.userSessionData.provider === "mtn")
              this.conversationalForm.addTags(
                this.finalSessionMTNMessageTags,
                true
              );
            if (this.userSessionData.provider != "mtn")
              this.conversationalForm.addTags(
                this.finalSessionMessageTags,
                true
              );
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            this.conversationalForm.addTags(this.paymentFailedTags, true);
          }
        } catch (e) {
          // handle error
          console.log("logging axios..(error)", e);
          const tags = [
            {
              tag: "select",
              name: "timeselectionfailed",
              "cf-questions":
                "Sorry, something went wrong and I could not select this slot.",
              children: [
                {
                  tag: "option",
                  "cf-label": "Try again",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Book a new appointment",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Cancel",
                  value: "",
                },
              ],
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
      } else if (
        dto.tag.domElement.name === "mobilemoneynumber" &&
        this.userSessionData.provider === "vodafone"
      ) {
        this.conversationalForm.addTags(this.vodafoneVoucherInputTags, true);
      }
      // Tenth interaction: Payment -> Mobile Money -> select provider
      if (dto.tag.domElement.name === "voucher") {
        // book slot:
        this.conversationalForm.addRobotChatResponse(
          "Booking your appointment..."
        );
        // const slotInfo = this.userSessionData.resultData[
        //   this.userSessionData.selectedTime
        // ];
        // this.userSessionData.serviceValue.id = slotInfo.slotid;

        let payload = {
          slotid: this.userSessionData.slotid,
          patientName: this.userSessionData.fullName,
          mobile: this.userSessionData.mobile,
          email: this.userSessionData.mobile + "@Nhyahohealth.com",
          patientId: this.userSessionData.userId,
          sz_bookerid: this.userSessionData.userId,
          sz_bookername: this.userSessionData.fullName,
          sz_bookedforid: this.userSessionData.userId,
          sz_docempid: this.userSessionData.resultData.assignedDoctorId,
          sz_docname: this.userSessionData.resultData.doctorName,
          sz_bookedtime: this.userSessionData.appointmentDateTime,
          sz_location: this.userSessionData.streetAddress,
          sz_payerid: this.userSessionData.userId,
          payername: "self",
          sz_bookingnote: "",
          sz_bookingstatus: "",
          service: this.userSessionData.serviceValue.health_service
            ? this.userSessionData.serviceValue.health_service
            : "",
          tier: this.userSessionData.serviceValue.id
            ? this.userSessionData.serviceValue.id
            : "",
          sz_bookingmode: "Online",
          sz_hospid: "",
          sz_unitid: "",
          sz_paymentmethod: this.userSessionData.paymentMethod,
          sz_bookedforname: this.userSessionData.fullName,
          sz_complaint: "",
          assignedDoctorId: this.userSessionData.resultData.assignedDoctorId,
          sz_bookingtype: this.userSessionData.appointmentTypeKey,
          sz_bookedfortime: this.userSessionData.appointmentDateTime,
        };
        try {
          // Make a request for a user with a given ID
          const response = await axios.post(
            this.apiUrl + "/patients/bookappointment",
            payload
          );
          // handle success
          this.userSessionData.patientId = response.data.sz_bookedforid;
          this.userSessionData.appointmentId = response.data.id;
          this.userSessionData.chargeItem = response.data.charge_item_id;

          this.userSessionData.voucher = dto.text;
          //POST: api
          payload = {
            vendor: this.userSessionData.provider,
            mobile:
              "233" +
              (this.userSessionData.momonumber[0] === "0"
                ? this.userSessionData.momonumber.slice(1)
                : this.userSessionData.momonumber),
            // sz_paymentnumber:
            //   "233" +
            //   (this.userSessionData.momonumber[0] === "0"
            //     ? this.userSessionData.momonumber.slice(1)
            //     : this.userSessionData.momonumber),
            service: this.userSessionData.serviceValue.health_service,
            // productid: "",
            // status: "pending",
            trans_type: "debit",
            voucher_number: this.userSessionData.voucher,
            charge_item: this.userSessionData.chargeItem,
            // slotid: this.userSessionData.serviceValue.id,
            // provider: "Nyaho",
            // patientName: this.userSessionData.fullName,
            // patientId: this.userSessionData.patientId,
            // appointmentId: this.userSessionData.appointmentId,
          };

          // call payment api
          this.conversationalForm.addRobotChatResponse("Verifying payment...");
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/finance/payment/mobile-money",
              payload
            );

            // handle success
            console.log("logging axios..(success)", response);
            this.finalSessionMessageTags[0][
              "cf-questions"
            ] = `Thanks for having me, ${this.userSessionData.fullName}. Your payment has been initiated, once your payment succeeds. You will receive an SMS and Email to confirm your appointment booking.`;
            this.conversationalForm.addTags(this.finalSessionMessageTags, true);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            this.conversationalForm.addTags(this.paymentFailedTags, true);
          }
        } catch (e) {
          // handle error
          console.log("logging axios..(error)", e);
          const tags = [
            {
              tag: "select",
              name: "timeselectionfailed",
              "cf-questions":
                "Sorry, something went wrong and I could not select this slot.",
              children: [
                {
                  tag: "option",
                  "cf-label": "Try again",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Book a new appointment",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Cancel",
                  value: "",
                },
              ],
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
      }
      if (dto.tag.domElement.name === "timeselectionfailed") {
        if (dto.text === "Try again") {
          this.conversationalForm.addRobotChatResponse(
            "Booking your appointment..."
          );
          // const slotInfo = this.userSessionData.resultData[
          //   this.userSessionData.selectedTime
          // ];
          // this.userSessionData.serviceValue.id = slotInfo.slotid;

          let payload = {
            slotid: this.userSessionData.slotid,
            patientName: this.userSessionData.fullName,
            mobile: this.userSessionData.mobile,
            email: this.userSessionData.mobile + "@Nhyahohealth.com",
            patientId: this.userSessionData.userId,
            sz_bookerid: this.userSessionData.userId,
            sz_bookername: this.userSessionData.fullName,
            sz_bookedforid: this.userSessionData.userId,
            sz_docempid: this.userSessionData.resultData.assignedDoctorId,
            sz_docname: this.userSessionData.resultData.doctorName,
            sz_bookedtime: this.userSessionData.appointmentDateTime,
            sz_location: this.userSessionData.streetAddress,
            sz_payerid: this.userSessionData.userId,
            payername: "self",
            sz_bookingnote: "",
            sz_bookingstatus: "",
            service: this.userSessionData.serviceValue.health_service
              ? this.userSessionData.serviceValue.health_service
              : "",
            tier: this.userSessionData.serviceValue.id
              ? this.userSessionData.serviceValue.id
              : "",
            sz_bookingmode: "Online",
            sz_hospid: "",
            sz_unitid: "",
            sz_paymentmethod: this.userSessionData.paymentMethod,
            sz_bookedforname: this.userSessionData.fullName,
            sz_complaint: "",
            assignedDoctorId: this.userSessionData.resultData.assignedDoctorId,
            sz_bookingtype: this.userSessionData.appointmentTypeKey,
            sz_bookedfortime: this.userSessionData.appointmentDateTime,
          };
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patients/bookappointment",
              payload
            );
            // handle success
            this.userSessionData.patientId = response.data.sz_bookedforid;
            this.userSessionData.appointmentId = response.data.id;
            this.userSessionData.chargeItem = response.data.charge_item_id;

            this.userSessionData.voucher = dto.text;
            //POST: api
            payload = {
              vendor: this.userSessionData.provider,
              mobile:
                "233" +
                (this.userSessionData.momonumber[0] === "0"
                  ? this.userSessionData.momonumber.slice(1)
                  : this.userSessionData.momonumber),
              // sz_paymentnumber:
              //   "233" +
              //   (this.userSessionData.momonumber[0] === "0"
              //     ? this.userSessionData.momonumber.slice(1)
              //     : this.userSessionData.momonumber),
              service: this.userSessionData.serviceValue.health_service,
              // productid: "",
              // status: "pending",
              trans_type: "debit",
              voucher_number: this.userSessionData.voucher,
              charge_item: this.userSessionData.chargeItem,
              // slotid: this.userSessionData.serviceValue.id,
              // provider: "Nyaho",
              // patientName: this.userSessionData.fullName,
              // patientId: this.userSessionData.patientId,
              // appointmentId: this.userSessionData.appointmentId,
            };

            // call payment api
            this.conversationalForm.addRobotChatResponse(
              "Verifying payment..."
            );
            try {
              // Make a request for a user with a given ID
              const response = await axios.post(
                this.apiUrl + "/finance/payment/mobile-money",
                payload
              );

              // handle success
              console.log("logging axios..(success)", response);
              this.finalSessionMessageTags[0][
                "cf-questions"
              ] = `Thanks for having me, ${this.userSessionData.fullName}. Your payment has been initiated, once your payment succeeds. You will receive an SMS and Email to confirm your appointment booking.`;
              this.conversationalForm.addTags(
                this.finalSessionMessageTags,
                true
              );
            } catch (e) {
              // handle success
              console.log("logging axios..(error)", e);
              this.conversationalForm.addTags(this.paymentFailedTags, true);
            }
          } catch (e) {
            // handle error
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "timeselectionfailed",
                "cf-questions":
                  "Sorry, something went wrong and I could not select this slot.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Book a new appointment",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
        }
        if (dto.text === "Book a new appointment") {
          const tags = [
            {
              tag: "select",
              name: "startoptions",
              "cf-questions":
                "When would you like to book the next appointment?",
              children: [
                {
                  tag: "option",
                  "cf-label": "Next available Date",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Choose my own Date",
                  value: "",
                },
              ],
            },
          ];

          this.conversationalForm.addTags(tags, true);
        }
      }
      // End of flow: Final
      if (dto.tag.domElement.name === "paymentfailed") {
        if (dto.text === "Try again") {
          // book slot:
          // book slot:
          this.conversationalForm.addRobotChatResponse(
            "Booking your appointment..."
          );
          // const slotInfo = this.userSessionData.resultData[
          //   this.userSessionData.selectedTime
          // ];
          // this.userSessionData.serviceValue.id = slotInfo.slotid;
          let payload = {
            slotid: this.userSessionData.slotid,
            patientName: this.userSessionData.fullName,
            mobile: this.userSessionData.mobile,
            email: this.userSessionData.mobile + "@Nhyahohealth.com",
            patientId: this.userSessionData.userId,
            sz_bookerid: this.userSessionData.userId,
            sz_bookername: this.userSessionData.fullName,
            sz_bookedforid: this.userSessionData.userId,
            sz_docempid: this.userSessionData.resultData.assignedDoctorId,
            sz_payerid:
              this.userSessionData.paymentMethod != "corporate"
                ? this.userSessionData.userId
                : this.userSessionData.companyId,
            sz_companyid: this.userSessionData.companyId,
            sz_docname: this.userSessionData.resultData.doctorName,
            sz_bookedtime: this.userSessionData.appointmentDateTime,
            sz_location: this.userSessionData.streetAddress,
            payername:
              this.userSessionData.paymentMethod != "corporate"
                ? "self"
                : this.userSessionData.companyName,
            sz_bookingnote: "",
            sz_bookingstatus: "",
            sz_bookingmode: "Online",
            sz_hospid: "",
            sz_unitid: "",
            service: this.userSessionData.serviceValue.health_service
              ? this.userSessionData.serviceValue.health_service
              : "",
            tier: this.userSessionData.serviceValue.id
              ? this.userSessionData.serviceValue.id
              : "",
            sz_paymentmethod: this.userSessionData.paymentMethod,
            sz_bookedforname: this.userSessionData.fullName,
            sz_complaint: "",
            assignedDoctorId: this.userSessionData.resultData.assignedDoctorId,
            sz_bookingtype: this.userSessionData.appointmentTypeKey,
            sz_bookedfortime: this.userSessionData.appointmentDateTime,
          };
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/patients/bookappointment",
              payload
            );
            // handle success
            this.userSessionData.patientId = response.data.sz_bookedforid;
            this.userSessionData.appointmentId = response.data.id;
            console.log("logging axios..(success)", response);
          } catch (e) {
            // handle error
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "timeselectionfailed",
                "cf-questions":
                  "Sorry, something went wrong and I could not select this slot.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Book a new appointment",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }

          const response = await axios.post(
            this.apiUrl + "/patients/bookappointment",
            payload
          );
          // handle success
          this.userSessionData.patientId = response.data.sz_bookedforid;
          this.userSessionData.appointmentId = response.data.id;
          this.userSessionData.chargeItem = response.data.charge_item_id;

          this.userSessionData.voucher = dto.text;
          //POST: api
          payload = {
            vendor: this.userSessionData.provider,
            mobile:
              "233" +
              (this.userSessionData.momonumber[0] === "0"
                ? this.userSessionData.momonumber.slice(1)
                : this.userSessionData.momonumber),
            // sz_paymentnumber:
            //   "233" +
            //   (this.userSessionData.momonumber[0] === "0"
            //     ? this.userSessionData.momonumber.slice(1)
            //     : this.userSessionData.momonumber),
            service: this.userSessionData.serviceValue.health_service,
            // productid: "",
            // status: "pending",
            trans_type: "debit",
            voucher_number: this.userSessionData.voucher,
            charge_item: this.userSessionData.chargeItem,
            // slotid: this.userSessionData.serviceValue.id,
            // provider: "Nyaho",
            // patientName: this.userSessionData.fullName,
            // patientId: this.userSessionData.patientId,
            // appointmentId: this.userSessionData.appointmentId,
          };

          // call payment api
          this.conversationalForm.addRobotChatResponse("Retrying..");
          this.conversationalForm.addRobotChatResponse("Verifying payment...");
          try {
            // Make a request for a user with a given ID
            const response = await axios.post(
              this.apiUrl + "/finance/payment/mobile-money",
              payload
            );

            // handle success
            console.log("logging axios..(success)", response);
            this.finalSessionMessageTags[0][
              "cf-questions"
            ] = `Thanks for having me, ${this.userSessionData.fullName}. Your payment has been initiated, once your payment succeeds. You will receive an SMS and Email to confirm your appointment booking.`;
            if (this.userSessionData.provider === "mtn")
              this.conversationalForm.addTags(
                this.finalSessionMTNMessageTags,
                true
              );
            if (this.userSessionData.provider != "mtn")
              this.conversationalForm.addTags(
                this.finalSessionMessageTags,
                true
              );
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            this.conversationalForm.addTags(this.paymentFailedTags, true);
          }
        }
        if (dto.text === "Return to start") openForm("form-appointment");
      }
      if (dto.tag.domElement.name === "finalsessionmessage") {
        if (dto.text === "Done") {this.$router.push({ path: "/appointments" });}
        if (dto.text === "Check my bill for this appointment"){
          this.$router.push({ path: "/bills" });}
        if (dto.text === "Proceed with payment"){
          this.$router.push({ path: "/express-pay" });}
        if (dto.text === "Book another appointment") {
          const tags = [
            {
              tag: "select",
              name: "startoptions",
              "cf-questions":
                "When would you like to book the next appointment?",
              children: [
                {
                  tag: "option",
                  "cf-label": "Next available Date",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Choose my own Date",
                  value: "",
                },
              ],
            },
          ];

          this.conversationalForm.addTags(tags, true);
        }
      }
      success();
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
      loggedIn: (state) => state.authStore.loggedIn,
    }),
    greeting: () => {
      const date = new Date();
      return date.getHours() >= 17
        ? "Hello 😊, Good evening"
        : date.getHours() >= 12
        ? "Hello 😊, Good afternoon"
        : "Hello 😊, Good morning";
    },
  },
  mounted() {
    // this.startInfo();
    this.setupForm();
    var dispatcher = new cf.EventDispatcher();
    dispatcher.addEventListener(
      cf.ChatListEvents.CHATLIST_UPDATED,
      function (event) {
        // your code here
        const date = document.querySelector("input[placeholder='dd/mm/yyyy']");

        function checkValue(str, max) {
          if (str.charAt(0) !== "0" || str == "00") {
            var num = parseInt(str);
            if (isNaN(num) || num <= 0 || num > max) num = 1;
            str =
              num > parseInt(max.toString().charAt(0)) &&
              num.toString().length == 1
                ? "0" + num
                : num.toString();
          }
          return str;
        }

        date.addEventListener("input", function (e) {
          this.type = "text";
          var input = this.value;
          if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
          var values = input.split("/").map(function (v) {
            return v.replace(/\D/g, "");
          });
          if (values[0]) values[0] = checkValue(values[0], 31);
          if (values[1]) values[1] = checkValue(values[1], 12);
          var output = values.map(function (v, i) {
            return v.length == 2 && i < 2 ? v + "/" : v;
          });
          this.value = output.join("").substr(0, 14);
        });
      },
      false
    );
    this.conversationalForm = window.cf.ConversationalForm.startTheConversation(
      {
        formEl: document.getElementById("form-appointment"),
        context: document.getElementById("cf-context"),
        preventAutoFocus: true,
        userImage:
          "https://cdn.glitch.com/1d1d7c79-abe4-4c95-b135-3a111632f4d8%2FEllipse%2018.png?v=1585908713570",
        robotImage: "../assets/logo.png",
        flowStepCallback: this.flowCallback,
        submitCallback: function () {},
        eventDispatcher: dispatcher,
      }
    );
    if (this.forSpecialist) {
      this.greetingsSpecialistTags[0]["cf-questions"] = this.greeting;
      this.conversationalForm.addTags(this.greetingsSpecialistTags, true);
    } else {
      this.greetingsTags[0]["cf-questions"] = this.greeting;
      this.conversationalForm.addTags(this.greetingsTags, true);
    }
    this.conversationalForm.start();
  },
};
</script>

<style lang="scss" scoped>
.bot {
  height: 100vh;
}

.cf-input-button {
  background-color: green;
  font-size: 100px;
}

.inputWrapper {
  /* background-color: green; */
  background-color: #c4c4c431;
}

.dark-theme .conversational-form cf-input input:hover {
  color: black;
}

.dark-theme .conversational-form .cf-button:hover cf-radio {
  color: grey;
}

.dark-theme .conversational-form-inner input:hover {
  color: green;
  background-color: #c4c4c431;
}

.dark-theme .conversational-form cf-input textarea:hover {
  color: grey;
}

.dark-theme .conversational-form cf-chat-response thumb > p {
  background-color: white;
  background-image: url("../assets/logo.png") !important;
}

.dark-theme .conversational-form cf-chat-response.user thumb {
  background-color: white;
  background-image: url("https://gulpjs.com/img/gulp-white-text.svg") !important;
  background-image: url("https://cdn.glitch.com/1d1d7c79-abe4-4c95-b135-3a111632f4d8%2FEllipse%2018.png?v=1585908713570") !important;
}

dark-theme .conversational-form cf-chat-response.robot thumb {
  background-color: white;
}

#chatstart {
  margin: 0;
  padding: auto;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: lighter;
}

.hold {
  background: linear-gradient(
    140.72deg,
    #72deff 0%,
    rgba(35, 31, 219, 0.51) 100%
  );
  margin-top: -10px;
  padding-bottom: 20px;
  padding-top: 20px;
}

#chatone {
  font-family: "Lato", sans-serif;
  padding-left: 10px;
  font-weight: lighter;
  padding-top: 10px;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: large;
}

#conversational-form {
  background-color: white;
  color: white;
}

.conversational-form-inner {
  background-color: white;
}

.conversational-form-inner input {
  color: #00000050;
  background-color: #c4c4c431;
}

.conversational-form {
  /* color: hotpink; */
  background-color: white;
}

.cf-button-input {
  background-color: #c4c4c431;
}

.chat-popup {
  width: 50%;
}

#cf-context {
  width: 100% !important;
}

.user.can-edit.peak-thumb.show p {
  background-color: blue;
  color: white;
  font-weight: bold;
}

.conversational-form--enable-animation .cf-button.animate-in {
  background-color: blue;
  color: white;
  font-weight: bold;
  border: 1px solid white;
}

.cf-button:hover,
.cf-button:hover:not([checked="checked"]) {
  color: green;
}

.conversational-form--enable-animation .cf-button.animate-in:hover {
  background-color: white;
  color: blue;
  border: 1px solid blue;
}

thumb .conversational-form--enable-animation cf-chat-response.user.peak-thumb {
  background-color: tomato;
}

.cf-input-button.cf-input-button {
  background-color: white;
  background-repeat: no-repeat;
  color: white;
}

cf-input-button.cf-input-button .cf-input-icons {
  color: green;
}

cf-input-button.cf-input-button .cf-input-progress {
  background-color: green;
}

cf-input-button.cf-input-button .cf-input-attachment {
  background-color: green;
}

.conversational-form--enable-animation cf-chat-response text > p.show {
  background-color: white;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  /* line-height: normal; */
  font-family: "Lato", sans-serif;
  font-weight: light;
  width: auto;
}

// .conversational-form--enable-animation cf-chat-response.robot.peak-thumb thumb {
//   background-image: url(../images/Ellipse\ 18.png) !important;
//   background-color: white;
//   width: 30px;
//   margin-right: 0;
// }

.conversational-form--enable-animation cf-chat-response thumb {
  background-color: blue;
}

cf-chat-response.user thumb {
  background-color: blue;
}

cf-input-button.cf-input-button:hover {
  background-color: blue;
}

cf-chat-response.user thumb {
  background-color: teal;
}

.conversational-form--enable-animation cf-chat-response text > p.show {
  /* background-color: teal; */
}

.conversational-form--enable-animation cf-chat-response.user.peak-thumb thumb {
  background-color: white;
}

cf-chat-response.show {
  padding-left: 0;
}

#hide-button {
  display: none;
}

#cf-context {
  width: 350px;
  height: 500px;
}
#form {
  height: 0px;
  overflow: hidden;
}
#form-appointment {
  height: 0px;
  overflow: hidden;
}
/* The popup chat - hidden by default */
.chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #4caf50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}
.myForm {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
iframe {
  height: 100%;
  width: 100%;
  border-width: 0px;
  border-style: inset;
  border-color: initial;
  border-image: initial;
  border: 0;
  border: none;
  min-height: 0px;
  min-width: 0px;
  background: transparent;
}
* {
  box-sizing: border-box;
}
/* width */
::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  /* background-color:; */
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

@media screen and (max-width: 770px) {
  #cf-context {
    width: 100% !important;
  }

  .chat-popup {
    width: 100%;
  }

  #chatstart {
    font-size: 18px;
  }
}
</style>
<style>
.loading {
  width: 60px;
  min-height: 60px;
  margin: 0px auto;
  background-color: #544848;
  border-radius: 100%;
  -webkit-animation: sa-scaleout 1s infinite ease-in-out;
  animation: sa-scaleout 1s infinite ease-in-out;
}

@keyframes sa-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sa-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
cf-list {
  width: auto;
  height: auto;
  margin-top: 20px;
  max-height: 210px;
  overflow-y: auto;
}
</style>
