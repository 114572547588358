<template>
  <div class="AppointmentTile mr-2 p-2">
    <div>
      <div class="row text-left">
        <!-- when -->
        <div
          class="col-3 text-center"
          style="border-right: 2px solid black; text-transform: uppercase"
        >
          <div class="row align-bottom" style="font-size: 0.7rem">
            <div class="col">{{ apptDay | moment("ddd") }}</div>
          </div>
          <div class="row" style="font-weight: 700; font-size: 1.5rem">
            <div class="col">{{ apptDate | moment("D") }}</div>
          </div>
          <div class="row" style="font-size: 0.7rem">
            <div class="col">{{ apptMonth | moment("MMM") }}</div>
          </div>
        </div>

        <!-- detail -->
        <div class="col ml-1">
          <div class="row text-muted" style="font-size: 0.7rem">
            <div class="col">{{ apptType }} with</div>
          </div>
          <div style="font-weight: 700; font-size: 1.5rem">
            <div>{{ practitionerName }}</div>
          </div>
          <div class="row text-muted" style="font-size: 0.7rem">
            <div class="col">{{ apptTime | moment("HH:mm") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "appointment-tile",
  props: {
    appt: {
      type: Object,
      // required: true
    },
  },
  data() {
    return {};
  },
  computed: {
    apptDay() {
      return this.appt.sz_bookedfortime;
    },
    apptDate() {
      return this.appt.sz_bookedfortime;
    },
    apptMonth() {
      return this.appt.sz_bookedfortime;
    },
    apptTime() {
      return this.appt.sz_bookedfortime;
    },
    apptType() {
      return this.appt.sz_bookingtype;
    },
    practitionerName() {
      return this.appt.sz_docname;
    },
  },
  filters: {
    readableDateTime(val) {
      return moment(val).format("Do MMM, YYYY @ HH:mm");
    },
  },
};
</script>

<style scoped>
.AppointmentTile {
  border-radius: 16px;
  cursor: pointer;
  background-color: #fff;
}
.AppointmentTile:hover {
  background-color: #ccc;
}
</style>
