<template>
  <div class="bot container">
    <form id="form-appointment" autocomplete="off"></form>
    <div id="cf-context" class="dark-theme" role="cf-context" cf-context></div>
  </div>
</template>

<script>
import axios from "axios";
import coroBotVue from "./coroBot.vue";
import env from "../../env";

export default {
  data() {
    return {
      conversationalForm: null,
      userSessionData: {
        appointmentCost: "GHc 99",
        address: "Some location in the world",
        result: [],
        resultData: {},
        doctors: {},
        streetAddress: "",
        appointmentTypeKey: "virtual-care-gp",
        appointmentType: "Virtual Care",
        covidAppointments: {},
      },
      specialistKeys: {
        "Family Physician": "family-medicine",
        "Emergency Doctor": "emergency-medicine",
        Dietician: "dietetics",
        "Paediatric Doctor": "paediatrics",
        "Obstetrician & Gynecologist": "obstetrics-and-gynaecology",
        "General Surgeon": "general-surgery",
        Urologist: "urology",
      },
      // apiUrl: "https://api.v05.serenity.health",
      apiUrl:
        process.env.NODE_ENV === "production"
          ? env.baseUrlProduction
          : env.baseUrlTest,
      totalPoints: 0,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      practitionerType: "",
      greetingsTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "",
        },
        {
          tag: "input",
          name: "startoptions",
          "cf-questions":
            "I'm Serenity and I can help you know where you are in the Covid-19 Testing queue. Please enter the mobile number you registered with",
        },
      ],
      finalSessionMessageTags: [
        {
          tag: "cf-robot-message",
          name: "message",
          "cf-questions": "Hi username",
        },
        {
          tag: "select",
          name: "finalsessionmessage",
          "cf-questions":
            "You are 15th in the queue. There are 14 people ahead of you.",
          children: [
            {
              tag: "option",
              "cf-label": "Okay",
              value: "Okay",
            },
          ],
        },
      ],
      forSpecialist: false,
    };
  },
  methods: {
    setupForm() {},
    async flowCallback(dto, success, error) {
      if (dto.tag.domElement.name === "startoptions") {
        // TODO: Make a network call: Get next Available time slot
        this.conversationalForm.addRobotChatResponse(
          "Checking your number in the queue..."
        );
        this.userSessionData.mobile = dto.text;
        const payload = {
          sz_phoneno: this.userSessionData.mobile,
          phone: this.userSessionData.mobile,
        };

        try {
          // Make a request for a user with a given ID
          let response = await axios.post(
            this.apiUrl + "/patient/checkexistence",
            payload
          );

          const patientResponse = await axios.post(
            this.apiUrl + "/patient/get",
            {
              szpatientid: response.data.sz_userid,
            }
          );

          // handle success
          this.userSessionData.fullName = response.data.sz_username;
          this.userSessionData.userId = response.data.sz_userid;

          // Make a request for a user with a given ID
          response = await axios.get(
            this.apiUrl +
              `/patients/appointments?id=&sz_bookingstatus=&sz_docempid=&service=covid-19&clinicId=&expiredStatus=&sz_bookedforid=`
          );

          const covidAppointments = response.data.results.filter(
            (element) => element.sz_bookedforid === this.userSessionData.userId
          );

          const testDays = covidAppointments.map((el) => {
            this.userSessionData.covidAppointments[
              this.$moment(el.sz_bookedfortime).calendar()
            ] = {
              isExpired: el.expiredStatus,
              confirmed: el.sz_bookingstatus === "Confirmed" ? true : false,
              queue: response.data.results.filter(
                (element) =>
                  element.sz_bookingtype === "covid-19" &&
                  el.sz_bookedfortime === element.sz_bookedfortime
              ),
              currentPositionInQueue:
                response.data.results
                  .filter(
                    (element) =>
                      element.sz_bookingtype === "covid-19" &&
                      el.sz_bookedfortime === element.sz_bookedfortime
                  )
                  .findIndex(
                    (element) =>
                      element.sz_bookedforid === this.userSessionData.userId
                  ) + 1,
            };
            return {
              tag: "option",
              "cf-label": this.$moment(el.sz_bookedfortime).calendar(),
              value: this.$moment(el.sz_bookedfortime).calendar(),
            };
          });

          const tags = [
            {
              tag: "select",
              name: "appointmentselection",
              "cf-questions": `${patientResponse.data.szfirstname}, you have appointments for the following days. Please select a day to check`,
              children: testDays,
            },
          ];

          if (covidAppointments.length < 1) {
            tags[0]["name"] = "finalsessionmessage";
            tags[0][
              "cf-questions"
            ] = `Sorry ${patientResponse.data.szfirstname}, you have no covid appointments. Please book a new covid test appointment`;
            tags[0]["children"].push({
              tag: "option",
              "cf-label": "Okay",
              value: "Okay",
            });
            tags[0]["children"].push({
              tag: "option",
              "cf-label": "Book new COVID test",
              value: "Book new COVID test",
            });
          }

          this.conversationalForm.addTags(tags);

          // const numberInQueue =
          //   response.data.count -
          //   response.data.results.findIndex(
          //     (element) =>
          //       element.sz_bookedforid === this.userSessionData.userId
          //   );

          // this.finalSessionMessageTags[0][
          //   "cf-questions"
          // ] = `Hi ${this.userSessionData.fullName}`;

          // if (numberInQueue > 3)
          //   this.finalSessionMessageTags[1][
          //     "cf-questions"
          //   ] = `Your payments have been confirmed and you have been passed to testing. You are currently ${numberInQueue}th in the queue. There are ${numberInQueue -
          //     1} people ahead of you. Please wait while you move along in the queue. You will be tested soon.`;

          // if (numberInQueue === 3)
          //   this.finalSessionMessageTags[1][
          //     "cf-questions"
          //   ] = `Your payments have been confirmed and you have been passed to testing. You are currently ${numberInQueue}rd in the queue. There are ${numberInQueue -
          //     1} people ahead of you. Please wait while you move along in the queue. You will be tested soon.`;

          // if (numberInQueue === 2) {
          //   console.log("I'm here in 2");
          //   this.finalSessionMessageTags[1][
          //     "cf-questions"
          //   ] = `Your payments have been confirmed and you have been passed to testing. You are currently ${numberInQueue}nd in the queue. There is ${numberInQueue -
          //     1} person ahead of you. Please wait while you move along in the queue. You will be tested soon.`;
          // }

          // if (numberInQueue === 1)
          //   this.finalSessionMessageTags[1][
          //     "cf-questions"
          //   ] = `Your payments have been confirmed and you have been passed to testing. You are currently ${numberInQueue}st in the queue. There are 0 people ahead of you. Please wait while you move along in the queue. You will be tested soon.`;

          // if (numberInQueue === 0)
          //   this.finalSessionMessageTags[1][
          //     "cf-questions"
          //   ] = `You are not in the queue.`;

          //this.conversationalForm.addTags(this.finalSessionMessageTags, true);
        } catch (e) {
          // handle success
          console.log("logging axios..(error)", e);
          const tags = [
            {
              tag: "select",
              name: "slotsfailed",
              "cf-questions": "Sorry, I could not contact the server.",
              children: [
                {
                  tag: "option",
                  "cf-label": "Try again",
                  value: "",
                },
                {
                  tag: "option",
                  "cf-label": "Cancel",
                  value: "",
                },
              ],
            },
          ];
          this.conversationalForm.addTags(tags, true);
        }
      }
      if (dto.tag.domElement.name === "appointmentselection") {
        if (this.userSessionData.covidAppointments[dto.text].isExpired) {
          this.finalSessionMessageTags[0][
            "cf-questions"
          ] = `Sorry ${this.userSessionData.fullName}`;
          this.finalSessionMessageTags[1][
            "cf-questions"
          ] = `This appointment has expired. Click on book new covid test to book another.`;
          this.finalSessionMessageTags[1]["children"].push({
            tag: "option",
            "cf-label": "Book new COVID test",
            value: "Book new COVID test",
          });
        } else {
          if (this.userSessionData.covidAppointments[dto.text].confirmed) {
            this.finalSessionMessageTags[0][
              "cf-questions"
            ] = `Hi ${this.userSessionData.fullName}`;

            const numberInQueue = this.userSessionData.covidAppointments[
              dto.text
            ].currentPositionInQueue;

            if (numberInQueue > 3)
              this.finalSessionMessageTags[1][
                "cf-questions"
              ] = `Your payments have been confirmed and you have been passed to testing. You are currently ${numberInQueue}th in the queue. There are ${
                numberInQueue - 1
              } people ahead of you. Please wait while you move along in the queue. You will be tested soon.`;

            if (numberInQueue === 3)
              this.finalSessionMessageTags[1][
                "cf-questions"
              ] = `Your payments have been confirmed and you have been passed to testing. You are currently ${numberInQueue}rd in the queue. There are ${
                numberInQueue - 1
              } people ahead of you. Please wait while you move along in the queue. You will be tested soon.`;

            if (numberInQueue === 2) {
              console.log("I'm here in 2");
              this.finalSessionMessageTags[1][
                "cf-questions"
              ] = `Your payments have been confirmed and you have been passed to testing. You are currently ${numberInQueue}nd in the queue. There is ${
                numberInQueue - 1
              } person ahead of you. Please wait while you move along in the queue. You will be tested soon.`;
            }

            if (numberInQueue === 1)
              this.finalSessionMessageTags[1][
                "cf-questions"
              ] = `Your payments have been confirmed and you have been passed to testing. You are currently ${numberInQueue}st in the queue. There are 0 people ahead of you. Please wait while you move along in the queue. You will be tested soon.`;

            if (numberInQueue === 0)
              this.finalSessionMessageTags[1][
                "cf-questions"
              ] = `You are not in the queue.`;

            //this.conversationalForm.addTags(this.finalSessionMessageTags, true);
          } else {
            this.finalSessionMessageTags[0][
              "cf-questions"
            ] = `Hi ${this.userSessionData.fullName}`;
            this.finalSessionMessageTags[1][
              "cf-questions"
            ] = `This appointment needs to be paid for. You currently need to find the cashiers at Nyaho Medical Centre to settle your bill.`;
          }
        }

        console.log(this.userSessionData.covidAppointments[dto.text]);
        this.conversationalForm.addTags(this.finalSessionMessageTags, true);
      }
      if (dto.tag.domElement.name === "slotsfailed") {
        if (dto.text === "Try again") {
          // TODO: Make a network call: Get next Available time slot
          this.conversationalForm.addRobotChatResponse(
            "Checking your number in the queue..."
          );
          this.userSessionData.mobile = dto.text;
          const payload = {
            sz_phoneno: this.userSessionData.mobile,
            phone: this.userSessionData.mobile,
          };

          try {
            // Make a request for a user with a given ID
            let response = await axios.post(
              this.apiUrl + "/patient/checkexistence",
              payload
            );

            // handle success
            this.userSessionData.fullName = response.data.sz_username;
            this.userSessionData.userId = response.data.sz_userid;

            // Make a request for a user with a given ID
            response = await axios.get(
              this.apiUrl +
                `/patients/appointments?sz_bookingtype=covid19&sz_bookingstatus=Confirmed`
            );
            const numberInQueue =
              response.data.count -
              response.data.results.findIndex(
                (element) =>
                  element.sz_bookedforid === this.userSessionData.userId
              );

            this.finalSessionMessageTags[0][
              "cf-questions"
            ] = `Hi ${this.userSessionData.fullName}`;
            this.finalSessionMessageTags[1][
              "cf-questions"
            ] = `You are ${numberInQueue}th in the queue. There are ${
              numberInQueue - 1
            } people ahead of you.`;

            if (numberInQueue === 2)
              this.finalSessionMessageTags[1][
                "cf-questions"
              ] = `You are ${numberInQueue}nd in the queue. There 1 ${
                numberInQueue - 1
              } person ahead of you.`;

            if (numberInQueue === 1)
              this.finalSessionMessageTags[1][
                "cf-questions"
              ] = `You are ${numberInQueue}st in the queue. There are 0 people ahead of you.`;

            if (numberInQueue === 0)
              this.finalSessionMessageTags[1][
                "cf-questions"
              ] = `You are not in the queue.`;
            this.conversationalForm.addTags(this.finalSessionMessageTags, true);
          } catch (e) {
            // handle success
            console.log("logging axios..(error)", e);
            const tags = [
              {
                tag: "select",
                name: "slotsfailed",
                "cf-questions": "Sorry, I could not contact the server.",
                children: [
                  {
                    tag: "option",
                    "cf-label": "Try again",
                    value: "",
                  },
                  {
                    tag: "option",
                    "cf-label": "Cancel",
                    value: "",
                  },
                ],
              },
            ];
            this.conversationalForm.addTags(tags, true);
          }
        }
        if (dto.text === "Cancel") this.$router.push({ path: "/" });
      }
      if (dto.tag.domElement.name === "finalsessionmessage") {
        if (dto.text === "Okay") this.$router.push({ path: "/" });
        if (dto.text === "Book new COVID test")
          this.$router.push({ path: "/corona-tool" });
      }
      success();
    },
  },
  computed: {
    greeting: () => {
      const date = new Date();
      return date.getHours() >= 17
        ? "Hello 😊, Good evening"
        : date.getHours() >= 12
        ? "Hello 😊, Good afternoon"
        : "Hello 😊, Good morning";
    },
  },
  mounted() {
    this.setupForm();
    this.conversationalForm = window.cf.ConversationalForm.startTheConversation(
      {
        formEl: document.getElementById("form-appointment"),
        context: document.getElementById("cf-context"),
        preventAutoFocus: true,
        userImage:
          "https://cdn.glitch.com/1d1d7c79-abe4-4c95-b135-3a111632f4d8%2FEllipse%2018.png?v=1585908713570",
        robotImage: "../assets/logo.png",
        flowStepCallback: this.flowCallback,
        submitCallback: function () {},
      }
    );
    if (this.forSpecialist) {
      this.greetingsSpecialistTags[0]["cf-questions"] = this.greeting;
      this.conversationalForm.addTags(this.greetingsSpecialistTags, true);
    } else {
      this.greetingsTags[0]["cf-questions"] = this.greeting;
      this.conversationalForm.addTags(this.greetingsTags, true);
    }
    this.conversationalForm.start();
  },
};
</script>

<style lang="scss" scoped>
.bot {
  height: 100vh;
}

.cf-input-button {
  background-color: green;
  font-size: 100px;
}

.inputWrapper {
  /* background-color: green; */
  background-color: #c4c4c431;
}

.dark-theme .conversational-form cf-input input:hover {
  color: black;
}

.dark-theme .conversational-form .cf-button:hover cf-radio {
  color: grey;
}

.dark-theme .conversational-form-inner input:hover {
  color: green;
  background-color: #c4c4c431;
}

.dark-theme .conversational-form cf-input textarea:hover {
  color: grey;
}

.dark-theme .conversational-form cf-chat-response thumb > p {
  background-color: white;
  background-image: url("../assets/logo.png") !important;
}

.dark-theme .conversational-form cf-chat-response.user thumb {
  background-color: white;
  background-image: url("https://gulpjs.com/img/gulp-white-text.svg") !important;
  background-image: url("https://cdn.glitch.com/1d1d7c79-abe4-4c95-b135-3a111632f4d8%2FEllipse%2018.png?v=1585908713570") !important;
}

dark-theme .conversational-form cf-chat-response.robot thumb {
  background-color: white;
}

#chatstart {
  margin: 0;
  padding: auto;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: lighter;
}

.hold {
  background: linear-gradient(
    140.72deg,
    #72deff 0%,
    rgba(35, 31, 219, 0.51) 100%
  );
  margin-top: -10px;
  padding-bottom: 20px;
  padding-top: 20px;
}

#chatone {
  font-family: "Lato", sans-serif;
  padding-left: 10px;
  font-weight: lighter;
  padding-top: 10px;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: large;
}

#conversational-form {
  background-color: white;
  color: white;
}

.conversational-form-inner {
  background-color: white;
}

.conversational-form-inner input {
  color: #00000050;
  background-color: #c4c4c431;
}

.conversational-form {
  /* color: hotpink; */
  background-color: white;
}

.cf-button-input {
  background-color: #c4c4c431;
}

.chat-popup {
  width: 50%;
}

#cf-context {
  width: 100% !important;
}

.user.can-edit.peak-thumb.show p {
  background-color: blue;
  color: white;
  font-weight: bold;
}

.conversational-form--enable-animation .cf-button.animate-in {
  background-color: blue;
  color: white;
  font-weight: bold;
  border: 1px solid white;
}

.cf-button:hover,
.cf-button:hover:not([checked="checked"]) {
  color: green;
}

.conversational-form--enable-animation .cf-button.animate-in:hover {
  background-color: white;
  color: blue;
  border: 1px solid blue;
}

thumb .conversational-form--enable-animation cf-chat-response.user.peak-thumb {
  background-color: tomato;
}

.cf-input-button.cf-input-button {
  background-color: white;
  background-repeat: no-repeat;
  color: white;
}

cf-input-button.cf-input-button .cf-input-icons {
  color: green;
}

cf-input-button.cf-input-button .cf-input-progress {
  background-color: green;
}

cf-input-button.cf-input-button .cf-input-attachment {
  background-color: green;
}

.conversational-form--enable-animation cf-chat-response text > p.show {
  background-color: white;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  /* line-height: normal; */
  font-family: "Lato", sans-serif;
  font-weight: light;
  width: auto;
}

// .conversational-form--enable-animation cf-chat-response.robot.peak-thumb thumb {
//   background-image: url(../images/Ellipse\ 18.png) !important;
//   background-color: white;
//   width: 30px;
//   margin-right: 0;
// }

.conversational-form--enable-animation cf-chat-response thumb {
  background-color: blue;
}

cf-chat-response.user thumb {
  background-color: blue;
}

cf-input-button.cf-input-button:hover {
  background-color: blue;
}

cf-chat-response.user thumb {
  background-color: teal;
}

.conversational-form--enable-animation cf-chat-response text > p.show {
  /* background-color: teal; */
}

.conversational-form--enable-animation cf-chat-response.user.peak-thumb thumb {
  background-color: white;
}

cf-chat-response.show {
  padding-left: 0;
}

#hide-button {
  display: none;
}

#cf-context {
  width: 350px;
  height: 500px;
}
#form {
  height: 0px;
  overflow: hidden;
}
#form-appointment {
  height: 0px;
  overflow: hidden;
}
/* The popup chat - hidden by default */
.chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #4caf50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}
.myForm {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
iframe {
  height: 100%;
  width: 100%;
  border-width: 0px;
  border-style: inset;
  border-color: initial;
  border-image: initial;
  border: 0;
  border: none;
  min-height: 0px;
  min-width: 0px;
  background: transparent;
}
* {
  box-sizing: border-box;
}
/* width */
::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  /* background-color:; */
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

@media screen and (max-width: 770px) {
  #cf-context {
    width: 100% !important;
  }

  .chat-popup {
    width: 100%;
  }

  #chatstart {
    font-size: 18px;
  }
}
</style>
<style>
.loading {
  width: 60px;
  min-height: 60px;
  margin: 0px auto;
  background-color: #544848;
  border-radius: 100%;
  -webkit-animation: sa-scaleout 1s infinite ease-in-out;
  animation: sa-scaleout 1s infinite ease-in-out;
}

@keyframes sa-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sa-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
cf-list {
  width: auto;
  height: auto;
  margin-top: 20px;
  max-height: 210px;
  overflow-y: auto;
}
</style>
