<template>
  <div class="mt-4 mb-5 py-3">
    <div class="container mt-5">
      <!-- <b-modal id="switch" size="sm" centered :hide-footer="true" :hide-header="true">
       
        <div class="w-100">
          <div class="text center">
            <h5 class="text-center py-2">Switch Patient</h5>
            <div
              class="row m-0 py-3 justify-content-center align-items-center text-dark"
              style="cursor: pointer;border-top: 1px solid silver; border-bottom: 1px solid silver;"
            >
              <div class="px-1 pr-2">
                <i class="fas text-info fa-plus"></i>
              </div>
              <div class="pl-1">
                <h5 class="text-info m-0">Add a family member</h5>
              </div>
            </div>
            <div class="mt-3 text-dark">
              <h5 class="text-center text-info" @click="close" style="cursor: pointer;">Cancel</h5>
            </div>
          </div>
        </div>
      </b-modal>-->
      <!-- {{userInfo}} -->
      <div>
        <div class="row m-0 mb-4 align-items-center">
          <div class="p-1 pr-2">
            <div
              id="imagePreview"
              style="
                width: 70px;
                height: 70px;
                border-radius: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
              "
              :style="{ 'background-image': `url(${image})` }"
            >
              <vue-initials-img
                v-if="!image"
                :name="userInfo.szfirstname + ' ' + userInfo.szlastname"
                style="width: 70px; height: 70px; border-radius: 50%"
              />
            </div>
          </div>
          <div class="pl-3 text-left">
            <h5 class="font-weight-bold m-0">
              {{ userInfo.szfirstname + " " + userInfo.szlastname }}
            </h5>
            <div class="text-muted">{{ userInfo.szdob | changeYear }}</div>
          </div>
        </div>
        <router-link to="/insurance">
          <div
            class="row m-0 py-3 align-items-center text-dark"
            style="
              border-top: 1px solid silver;
              border-bottom: 1px solid silver;
            "
          >
            <div class="px-1 pr-2">
              <i class="fas fa-hand-holding-medical"></i>
            </div>
            <div class="pl-3">
              <h5 class="m-0">Insurance</h5>
            </div>
          </div>
        </router-link>

        <router-link to="/bills">
          <div
            class="row m-0 py-3 align-items-center text-dark"
            style="border-bottom: 1px solid silver"
          >
            <div class="px-1 pr-2">
              <i class="fas fa-file-invoice-dollar"></i>
            </div>
            <div class="pl-4">
              <h5 class="m-0">Bills</h5>
            </div>
          </div>
        </router-link>

        <router-link to="/payment">
          <div
            class="row m-0 py-3 align-items-center text-dark"
            style="border-bottom: 1px solid silver"
          >
            <div class="px-1 pr-2">
              <i class="fas fa-money-check-alt"></i>
            </div>
            <div class="pl-3">
              <h5 class="m-0">Payment Options</h5>
            </div>
          </div>
        </router-link>

        <router-link to="/account">
          <div
            class="row m-0 py-3 align-items-center text-dark"
            style="border-bottom: 1px solid silver"
          >
            <div class="px-1 pr-2">
              <i class="fas fa-user-circle"></i>
            </div>
            <div class="pl-3">
              <h5 class="m-0">Account</h5>
            </div>
          </div>
        </router-link>

        <router-link to="/messages">
          <div
            class="row m-0 py-3 align-items-center text-dark"
            style="border-bottom: 1px solid silver"
          >
            <div class="px-1 pr-2">
              <i class="fas fa-comments"></i>
            </div>
            <div class="pl-3">
              <h5 class="m-0">Messages</h5>
            </div>
          </div>
        </router-link>

        <!-- <div
          v-b-modal="'switch'"
          class="row m-0 py-3 align-items-center text-dark"
          style="border-bottom: 1px solid silver; cursor: pointer"
        >
          <div class="px-1 pr-2">
            <i class="fas fa-users"></i>
          </div>
          <div class="pl-2">
            <h5 class="m-0">Switch Patient</h5>
          </div>
        </div>-->

        <a href="https://serenity.health">
          <div
            class="row m-0 py-3 align-items-center text-dark"
            style="border-bottom: 1px solid silver"
          >
            <div class="px-1 pr-2">
              <i class="fas fa-comment-medical"></i>
            </div>
            <div class="pl-2">
              <h5 class="m-0">Support</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="text-center mt-5">
        <b-button
          variant="light"
          class="text-center shadow font-weight-bold text-danger"
          @click="logout()"
          >Log out</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  methods: {
    logout() {
      this.$store.dispatch("authStore/unSetUserCode");
      // this.$store.dispatch('unSetToken')
      this.$router.push("/login");
    },
    close() {
      this.$bvModal.hide("switch");
    },
  },
  filters: {
    changeYear(year) {
      let old = moment(year, "YYYY-MM-DD").fromNow().replace("ago", "old");
      console.log(old);
      return old;
      // ("34 years ago");
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
      loggedIn: (state) => state.authStore.loggedIn,
      image: (state) => state.authStore.image,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
