<template>
  <div class="Login row m-0">
    <div class="signup__form col-lg pt-5 mt-5 container px-md-5">
      <!-- image -->

      <!-- greeting -->
      <div class="text-left">
        <!-- <span style="font-size: 1rem">{{ getGreeting }}</span> -->
        <!-- <span style="font-size: 1.2rem">Good {{ getTimeOfDay }}</span> -->
        <!-- <br /> -->
        <b
          style="
            font-size: 2rem;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            display: block;
          "
          >Hello!</b
        >
        <!-- <br /> -->
        <span>Please sign up with us</span>
      </div>
      <div class="close">
        <router-link to="/">
          <i class="fa fa-times shadow"></i>
        </router-link>
      </div>
      <form @submit.prevent="signup">
        <div class="row text-left mt-3">
          <div class="col-6">
            <small class="text-muted">First name</small>
            <input
              type="text"
              name="first-inp"
              id="first-inp"
              class="form-control"
              placeholder="First name"
              v-model.trim="form.sz_firstname"
              required
            />
          </div>
          <div class="col-6">
            <small class="text-muted">Other name</small>
            <input
              type="text"
              name="other-inp"
              id="other-inp"
              class="form-control"
              placeholder="Other name"
              v-model.trim="form.sz_othername"
            />
          </div>
        </div>
        <div class="text-left mt-3">
          <small class="text-muted">Last name</small>
          <input
            type="text"
            name="last-inp"
            id="last-inp"
            class="form-control"
            placeholder="Last name"
            v-model.trim="form.sz_lastname"
            required
          />
        </div>
        <div class="row mt-3">
          <div class="col-6 text-left">
            <small class="text-muted">Date of Birth</small>
            <input
              type="date"
              name="date-inp"
              id="date-inp"
              class="form-control"
              placeholder="Date of birth"
              v-model="dob"
              required
            />
          </div>
          <div class="col-6 text-left">
            <small class="text-muted">Gender</small>
            <!-- <input
              type="text"
              class="form-control"
              placeholder="Gender"
              v-model.trim="form.sz_gender"
              required
            />-->
            <select class="form-control" id="gender" v-model="form.sz_gender">
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
        </div>
        <div class="row text-left mt-3">
          <div class="col-6">
            <small class="text-muted">Mobile</small>
            <input
              type="tel"
              name="mobile-inp"
              id="mobile-inp"
              class="form-control"
              placeholder="Mobile"
              v-model.trim="form.sz_phoneno"
              required
            />
          </div>
          <div class="col-6">
            <small class="text-muted">Email</small>
            <input
              type="email"
              name="pass-inp"
              id="pass-inp"
              class="form-control"
              placeholder="Email"
              v-model.trim="form.sz_email"
            />
          </div>
          <div class="col-12 mt-3">
            <small class="text-muted">Address</small>
            <input
              type="text"
              name="mobile-inp"
              id="mobile-inp"
              class="form-control"
              placeholder="Address"
              v-model.trim="form.sz_address"
              required
            />
          </div>
          <div class="col-12 mt-2">
            <div class="row justify-content-between px-3">
              <small style="font-size: 0, 8rem">
                Already have an account?
                <router-link to="/login" class="text-info" replace
                  >Login</router-link
                >
              </small>
              <small style="font-size: 0, 8rem">
                <a href class="text-info">Forgot your password?</a>
              </small>
            </div>
          </div>
          <div class="col-12 mt-5 text-right">
            <button type="submit" class="loginBtn" :disabled="loading">
              <i
                v-if="loading"
                :class="[
                  'fa',
                  'fa-spin',
                  'fa-spinner',
                  loading ? 'loading-width' : '',
                ]"
              ></i>
              <template v-else>
                <i class="fa fa-arrow-right"></i>
              </template>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg d-none d-md-block m-0 p-0 text-right">
      <div class="h-100">
        <img src="@/assets/bglogin.svg" alt height="100%" />
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/network/Base.js";
import moment from "moment";
export default {
  name: "login",
  components: {},
  data() {
    return {
      dob: "",
      loading: false,
      form: {
        sz_title: "",
        sz_firstname: "",
        sz_lastname: "",
        sz_othername: "",
        sz_gender: "Male",
        sz_dob: "",
        sz_phoneno: "",
        sz_email: "",
        szemployeeid: "",
        sz_created: "",
        sz_address: "",
        szemployeeid: "",
        sz_location: "",
        sz_marital_status: "",
        sz_nationality: "",
        sz_native_language: "",
        sz_religion: "",
        sz_occupation: "",
        sz_employer: "",
        sz_ice_name: "",
        sz_ice_place_of_work: "",
        sz_ice_address: "",
        sz_ice_phoneno: "",
      },
    };
  },
  computed: {
    getTimeOfDay() {
      const hr = new Date().getHours();
      if (hr < 12) return "Morning";
      else if (hr < 17) return "Afternoon";
      else return "Evening";
    },
  },
  methods: {
    // validateForm() {
    //   if (this.form.mobile && this.form.password) return true;
    //   return false;
    // },
    signup() {
      this.loading = true;
      console.log(this.dob);
      this.form.sz_dob = this.dob;
      http()
        .post("patients/add", this.form)
        .then(({ data }) => {
          this.$snotify.success("Successfully signed up");
          this.$router.push({ path: "/login" });
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error)
          this.$snotify.error("Sorry, couldn't signup");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.Login {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 50px;
}

.close {
  position: absolute;
  top: -20px;
  left: 40px;
  color: red;
  cursor: pointer;
}
/* .form-control {
  border: none;
  border-bottom: 2px solid grey;
  width: 100%;
  height: 3rem;
  padding: 1rem 4px;
  margin-bottom: 8px;
  background: transparent;
  font-size: 1rem;
} */
.loginBtn {
  background: linear-gradient(
    270deg,
    rgba(9, 220, 68, 0.48) 0%,
    rgba(20, 178, 247, 0.48) 100%
  );
  border-radius: 50%;
  border: 0px;
  width: 45px;
  height: 45px;
  color: white;
}
</style>
