<template>
  <div class="AppointmentTile p-2">
    <div>
      <div class="row text-left align-items-center">
        <!-- when -->
        <div
          class="col-3 text-center"
          style="border-right: 2px solid black; text-transform: uppercase"
        >
          <div class="row align-bottom" style="font-size: 0.7rem">
            <div class="col">{{ apptDate | moment("ddd") }}</div>
          </div>
          <div class="row" style="font-weight: 700; font-size: 1.5rem;">
            <div class="col">{{ apptDate | moment("D") }}</div>
          </div>
          <div class="row" style="font-size: 0.7rem">
            <div class="col">{{ apptDate | moment("MMM") }}</div>
          </div>
        </div>

        <!-- detail -->
        <div class="col ml-1">
          <div class="row text-muted" style="font-size: 0.7rem">
            <div class="col">Patient: {{patientName}}</div>
          </div>
          <div style="font-weight: 700; font-size: 1rem;">
            <div>{{serviceName}}</div>
          </div>
          <div class="row text-muted" style="font-size: 0.7rem">
            <div class="col">{{ apptDate | moment("HH:mm") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "appointment-tile",
  props: {
    bill: {
      type: Object,
      // required: true
    },
  },
  data() {
    return {};
  },
  computed: {
    patientName() {
      return this.bill.patientname;
    },
    serviceName() {
      return this.bill.service_or_product_name;
    },
    apptDate() {
      return this.bill.created_on;
    },
    // practitionerName() {
    //   return "Dr. " + this.bill.sz_docname;
    // },
  },
  filters: {
    readableDateTime(val) {
      return moment(val).format("Do MMM, YYYY @ HH:mm");
    },
  },
};
</script>

<style scoped>
.AppointmentTile {
  border-radius: 16px;
  cursor: pointer;
  background-color: #fff;
}
.AppointmentTile:hover {
  background-color: rgba(221, 220, 220, 0.578);
}
</style>
