<template>
  <div class="PrescriptionTile">
    <div class="container-fluid p-1">
      <div class="row m-0 align-items-center">
        <div class="col">
          <div class="row align-items-center">
            <div class="col-2 align-middle px-2">
              <img
                src="https://img.icons8.com/dusk/64/000000/pill.png"
                style="width: 100%; max-width: 100%"
              />
            </div>
            <div class="col-5 text-left">
              <div class="row">
                <div class="col">
                  <b style="font-size: 14px">{{ drug.medication }}</b>
                  <!-- <div style="font-size: 0.7rem">
                    {{ drug.medication_dosage_instruction[0].strength }}
                  </div> -->
                </div>
              </div>
              <div>
                <div class="text-muted" style="font-size: 0.7rem">
                  By {{ drug.medication_requester_name }}
                </div>
              </div>
            </div>
            <div class="col-5 text-left">
              <div class="row">
                <div class="col">
                  <b style="font-size: 14px"
                    >{{ drug.medication_dosage_instruction[0].frequency }}
                    {{ drug.medication_dosage_instruction[0].frequency_unit }}
                  </b>
                </div>
              </div>
              <div>
                <div class="text-muted" style="font-size: 0.7rem">
                  for {{ drug.medication_dosage_instruction[0].period
                  }}{{ drug.medication_dosage_instruction[0].period_unit }}
                  continuously
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "prescription-tile",
  props: {
    drug: {
      type: Object,
      required: true,
      default: () => ({
        medication: "",
        strength: "",
        medication_requester_name: "",
        medication_dosage_instruction: [],
        period: "",
        duration: "",
        modified_at: "",
        patientName: "",
      }),
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  filters: {
    readableDateTime(val) {
      return moment(val).format("Do MMM, YYYY @ HH:mm");
    },
  },
  created() {},
};
</script>

<style scoped>
.PrescriptionTile {
  border-radius: 16px;
  cursor: pointer;
  background-color: #fff;
}
.PrescriptionTile:hover {
  background-color: rgba(219, 219, 219, 0.51);
}
</style>
