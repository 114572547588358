var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"covid-ready pt-5",class:[_vm.result.result == 'positive' ? 'fail-test' : 'not-tested', _vm.result.result == 'negative' ? 'success-test' : 'not-tested']},[_c('div',{staticClass:"container mt-4 py-5"},[_c('div',{staticClass:"container",attrs:{"id":"printMe"}},[_c('div',{staticClass:"card status-card mt-4 py-5 shadow-sm"},[_c('span',{staticClass:"my-3"},[_c('qrcode-vue',{staticClass:"m-0",attrs:{"value":_vm.file,"size":"250","level":"H","foreground":"#535353","renderAs":"svg"}})],1),_c('div',{staticClass:"mt-2"},[_c('h3',[_c('b',[_vm._v(_vm._s(_vm.userInfo.szfirstname + ' ' + _vm.userInfo.szlastname))])]),_c('div',[_c('b-badge',{staticClass:"shadow-sm",class:{ 
                'text-light bg-danger' : (_vm.result.result == 'positive'), 
                'text-light bg-success' : (_vm.result.result == 'negative'), 
             'text-secondary bg-light' : (!_vm.result)},attrs:{"pill":""}},[_c('h6',{staticClass:"m-0"},[_c('b',[_vm._v(_vm._s(_vm.result.result || 'Not Tested'))])])])],1),_c('div',{staticClass:"mt-3 text-left ml-1 container"},[_c('div',{staticClass:"row m-0 justify-content-center",staticStyle:{"font-weigth":"900"}},[_c('div',{staticClass:"col-6"},[_c('div',[_c('small',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("LAST COVID TEST")]),_c('div',{staticClass:"row m-0 align-items-center"},[_c('div',{staticClass:"bg-secondary status-indicator",class:{ 
                'bg-danger' : (_vm.result.result == 'positive'), 
                'bg-success' : (_vm.result.result == 'negative'), 
             'bg-secondary' : (!_vm.result)}}),_c('h6',{staticClass:"ml-2 mb-0"},[_vm._v(_vm._s(_vm.result ? _vm.time : 'Not done'))])])]),_c('div',[_c('small',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("ANTIBODY TEST")]),_c('div',{staticClass:"row m-0 align-items-center"},[_c('div',{staticClass:"bg-secondary status-indicator",class:{ 
                'bg-danger' : (_vm.result.result == 'positive'), 
                'bg-success' : (_vm.result.result == 'negative'), 
             'bg-secondary' : (!_vm.result)}}),_c('h6',{staticClass:"ml-2 mb-0"},[_vm._v(_vm._s('Not done'))])])]),_c('div',[_c('small',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("PROVIDER")]),_c('div',{staticClass:"row m-0 align-items-center"},[_c('div',{staticClass:"bg-secondary status-indicator",class:{ 
                'bg-danger' : (_vm.result.result == 'positive'), 
                'bg-success' : (_vm.result.result == 'negative'), 
             'bg-secondary' : (!_vm.result)}}),_c('h6',{staticClass:"ml-2 mb-0"},[_vm._v(_vm._s(_vm.result ? 'Nyaho Medical Centre' : 'None'))])])])]),_c('div',{staticClass:"col-6 pl-0"},[_c('div',[_c('small',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("SYMPTOM TRACKER")]),_c('div',{staticClass:"row m-0 align-items-center"},[_c('div',{staticClass:"bg-secondary status-indicator",class:{ 
                'bg-danger' : (_vm.result.result == 'positive'), 
                'bg-success' : (_vm.result.result == 'negative'), 
             'bg-secondary' : (!_vm.result)}}),_c('h6',{staticClass:"ml-2 mb-0"},[_vm._v("None")])])]),_c('div',[_c('small',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("LATEST TEMP READING")]),_c('div',{staticClass:"row m-0 align-items-center"},[_c('div',{staticClass:"bg-secondary status-indicator",class:{ 
                'bg-danger' : (_vm.result.result == 'positive'), 
                'bg-success' : (_vm.result.result == 'negative'), 
             'bg-secondary' : (!_vm.result)}}),_c('h6',{staticClass:"ml-2 mb-0"},[_vm._v("None")])])])])])])])])]),_c('div',{staticClass:"pills--container pt-2"},[_c('div',{staticClass:"row m-0 align-items-center justify-content-between py-3"},[_c('div',[(_vm.result)?_c('button',{staticClass:"btn btn-outline-secondary mx-1 footer-pills",on:{"click":function($event){return _vm.startDownload()}}},[_vm._v("Print")]):_c('button',{staticClass:"btn btn-outline-secondary mx-1 footer-pills",on:{"click":function($event){return _vm.print()}}},[_vm._v("Print")]),_c('button',{staticClass:"btn btn-outline-secondary mx-1 footer-pills",on:{"click":function($event){return _vm.print()}}},[_vm._v("Share")])]),_c('div',[_c('button',{staticClass:"btn btn-outline-secondary mx-1 footer-pills",on:{"click":_vm.goCoro}},[_vm._v(_vm._s(_vm.result ? 'Take another test' : 'Get Tested'))]),_c('button',{staticClass:"btn btn-outline-secondary mx-1 footer-pills",on:{"click":_vm.back}},[_vm._v("Done")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }