<template>
  <div class="Vitals">
    <div class="container mt-5 mb-5">
      <!-- header -->
      <div class="row pt-5 pb-5">
        <div class="col-7">
          <h3>
            <b>Your Vitals</b>
          </h3>
          <div style="font-size: 0.7rem;">from your recent visits</div>
        </div>
        <div class="col-4 text-right">
          <button
            class="btn btn-sm btn-outline-primary"
            style="border-radius: 50%"
            @click="getVitals()"
            title="Refresh"
          >
            <i class="fa fa-sync"></i>
          </button>
        </div>
      </div>

      <!-- current vitals -->
      <div class="row">
        <div class="col-12">
          <div class="text-center" v-if="!allVitals && !loading">No vitals recorded yet 😐</div>
          <div v-else-if="loading" class="w-100 text-center mt-5">
            <b-spinner
              variant="info"
              style="width: 3rem; height: 3rem;"
              type="grow"
              label="Spinning"
            ></b-spinner>
          </div>
          <div class="card" style="border-radius: 16px;" v-else>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row justify-content-center" style="font-size: 1.5rem">
                    <b>{{ allVitals.temp }}</b>
                  </div>
                  <div
                    class="row justify-content-center text-muted"
                    style="font-size: 0.7rem"
                  >&deg;C</div>
                  <div class="row justify-content-center">TEMP</div>
                </div>
                <div class="col text-center">
                  <div class="row justify-content-center" style="font-size: 1.5rem">
                    <b>{{ allVitals.BP }}</b>
                  </div>
                  <div class="row justify-content-center text-muted" style="font-size: 0.7rem">mmHg</div>
                  <div class="row justify-content-center">BP</div>
                </div>
                <div class="col text-center">
                  <div class="col text-center">
                    <div class="row justify-content-center" style="font-size: 1.5rem">
                      <b>{{ allVitals.pulse }}</b>
                    </div>
                    <div class="row justify-content-center text-muted" style="font-size: 0.7rem">bpm</div>
                    <div class="row justify-content-center">H. Rate</div>
                  </div>
                </div>
              </div>
              <div class="row pt-4">
                <div class="col text-center">
                  <div class="col text-center">
                    <div class="row justify-content-center" style="font-size: 1.5rem">
                      <b>{{ allVitals.SpO2 }}</b>
                    </div>
                    <div class="row justify-content-center text-muted" style="font-size: 0.7rem">%</div>
                    <div class="row justify-content-center">Oxygen</div>
                  </div>
                </div>
                <div class="col text-center">
                  <div class="col text-center">
                    <div class="row justify-content-center" style="font-size: 1.5rem">
                      <b>{{ allVitals.Hgt }}</b>
                    </div>
                    <div class="row justify-content-center text-muted" style="font-size: 0.7rem">cm</div>
                    <div class="row justify-content-center">Height</div>
                  </div>
                </div>
                <div class="col text-center">
                  <div class="col text-center">
                    <div class="row justify-content-center" style="font-size: 1.5rem">
                      <b>{{ allVitals.Wgt }}</b>
                    </div>
                    <div class="row justify-content-center text-muted" style="font-size: 0.7rem">kg</div>
                    <div class="row justify-content-center">Weight</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppointmentTile from "@/components/AppointmentTile";
import http from "@/network/Base.js";
import { mapState } from "vuex";

export default {
  name: "vitals",
  components: {
    AppointmentTile,
  },
  data() {
    return {
      allVitals: {},
      loading: false,
    };
  },
  watch: {
    apptFilter(val) {},
  },
  methods: {
    clearAllExpiredAppts() {},
    goBack() {
      this.$router.push("/");
    },
    getVitals() {
      this.loading = true;
      http()
        .get(`/patient/getpatientinfo?szpatientid=${this.userInfo.szpatientid}`)
        .then(({ data }) => {
          this.loading = false;
          this.allVitals = JSON.parse(data.sz_vitals);
          console.log(data);
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error)
          // this.$snotify.error('Sorry, cannot get Service detail')
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getVitals();
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
    }),
  },
};
</script>

<style scoped>
.Vitals {
  width: 100%;
  overflow: hidden;
}
</style>
